import { DeductionCategory } from '@house-id/houseid-types/dist/deductions';
import { FinanceSaleSummaryDeductionReceipt } from '@house-id/houseid-types/dist/finances/finances';

export enum DeductionCategoryValueType {
  SINGLE_VALUE = 'SINGLE_VALUE',
  VALUE_WITH_RECEIPTS = 'VALUE_WITH_RECEIPTS',
}

export type DeductionCategoryValues = {
  type: DeductionCategoryValueType
  amount: string;
  hasReceipts: boolean;
  receiptIds: string[]
  categoryReceipts: Array<FinanceSaleSummaryDeductionReceipt>;
  description: {
    preamble: string;
    full: string;
  };
};

export type ManagePropertyEstimatedDeductionsValues = {
  [DeductionCategory.PURCHASE_EXPENSE]: DeductionCategoryValues
  [DeductionCategory.SALES_EXPENSE]: DeductionCategoryValues
  [DeductionCategory.RENOVATION]: DeductionCategoryValues
};
