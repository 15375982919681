import { resolveImageUrl } from './env';

export const openFileInNewTab = (url: string, isExternal = false) =>
  window.open(resolveImageUrl(`${url}${isExternal ? '' : '&inline=true'}`), '_blank')?.focus();

export const openUrlNewTab = (url: string) =>
  window.open(url, '_blank', 'noopener,noreferrer')?.focus();

export const openUrlSameTab = (url: string) => {
  window.open(url, '_self');
};

export const openUrl = (url: string, openInNewTab = true) => {
  if (openInNewTab) {
    openFileInNewTab(url);
  } else {
    openUrlSameTab(url);
  }
};
