import {
  useRef,
  useEffect,
} from 'react';
import * as R from 'ramda';
import {
  collection,
  query,
  where,
  QuerySnapshot,
  DocumentData,
  onSnapshot,
} from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';

import {
  useGetTokenDataQuery,
  useReloadTokenDataMutation,
} from '../modules/Auth/api/api.user';
import {
  firestore,
  getActiveAuth,
} from '../external-services/firebase';
import { reportError } from '../external-services/sentry';
import { propertyApi } from '../modules/Property/api/api.property';
import { UserSyncDataActionType } from '../modules/Property/types/types.property';
import { propertyProgressApi } from '../modules/Property/modules/PropertyProgress/api/api.propertyProgress';
import useGetCurrentPropertyId from '../modules/Property/hooks/useGetCurrentPropertyId';
import { HIDApiTags } from '../api/HIDApiTags';
import { isPartner } from '../modules/Auth/utils/token';

enum DocumentChangeType {
  ADDED = 'added',
  REMOVED = 'removed',
  MODIFIED = 'modified',
}

type SyncAction = {
  type: UserSyncDataActionType,
  createdAt: Date,
};

const handleSyncActionError = (error: Error) => reportError(error);

const handleResult = (
  snapshot: QuerySnapshot<DocumentData, DocumentData>,
  onNewDocuments: (syncActions: Array<SyncAction>) => void,
) => {
  const newSyncActions = snapshot.docChanges()
    .filter((change) => change.type === DocumentChangeType.ADDED)
    .map((change) => {
      const document = change.doc.data();

      return { type: document.type, createdAt: document.createdAt };
    });
  onNewDocuments(newSyncActions);
};

const mergeSyncActions = (oldSyncActions: Array<SyncAction>, newSyncActions: Array<SyncAction>): Array<SyncAction> =>
  [...oldSyncActions, ...newSyncActions];

type UseFirebaseSyncActionsInitializer = () => undefined;
const ACTION_INTERVAL = 1000;

const useFirebaseSyncActionsInitializer: UseFirebaseSyncActionsInitializer = () => {
  const [currentUser] = useAuthState(getActiveAuth());
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });
  const dispatch = useDispatch();

  const { data: propertyId } = useGetCurrentPropertyId(undefined, { skip: !tokenData || isPartner(tokenData) });

  const syncActionsRef = useRef<Array<SyncAction>>([]);

  const handleNewSyncActions = (newSyncActions: Array<SyncAction>) => {
    syncActionsRef.current = mergeSyncActions(syncActionsRef.current, newSyncActions);
  };

  const [reloadTokenData] = useReloadTokenDataMutation();

  const syncActionTypeHandlers: Record<string, () => void> = {
    [UserSyncDataActionType.REFRESH_TOKEN]: () => reloadTokenData(),
    [UserSyncDataActionType.RESET_PROPERTY_DATA]: () => dispatch(propertyApi.util.resetApiState()),
    [UserSyncDataActionType.RELOAD_PROPERTY_PROGRESS]: () =>
      dispatch(propertyProgressApi.util.invalidateTags([HIDApiTags.PROPERTY_PROGRESS])),
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const syncActionToShow = R.head(syncActionsRef.current);

      if (syncActionToShow) {
        syncActionsRef.current = syncActionsRef.current.filter((n) => n.type !== syncActionToShow.type);

        const handler = syncActionTypeHandlers[syncActionToShow.type];
        if (handler) {
          handler();
        }
      }
    }, ACTION_INTERVAL);

    return () => clearInterval(interval);
  }, [propertyId]);

  const initialize = () => {
    const currentUserUid = getActiveAuth().currentUser?.uid;
    if (currentUserUid) {
      const actionsRef = collection(firestore, 'users', currentUserUid, 'actions');
      const q = query(actionsRef, where('createdAt', '>', new Date()));
      onSnapshot(q, {
        next: (snapshot) => handleResult(snapshot, handleNewSyncActions),
        error: handleSyncActionError,
      });
    }
    return () => { };
  };

  useEffect(() => {
    let unsubscribe;
    if (currentUser?.uid) {
      unsubscribe = initialize();
    } else {
      return unsubscribe;
    }
  }, [currentUser?.uid]);
};

export default useFirebaseSyncActionsInitializer;
