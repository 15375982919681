import {
  NavigateOptions,
  To,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import qs from 'query-string';

export const useGetAnyRouteHistory = () => {
  const location = useLocation();
  return () => location.key !== 'default'
    // eslint-disable-next-line
    && (window.history?.state?.idx === undefined || window.history.state.idx > 0);
};

export const useNavigateBackOr = () => {
  const navigate = useNavigate();
  const getHasAnyHistory = useGetAnyRouteHistory();

  const navigateToOrDefault = (defaultRoute?: To, options?: NavigateOptions) => {
    if (getHasAnyHistory()) {
      navigate(-1);
    } else if (defaultRoute) {
      navigate(defaultRoute, options);
    }
  };

  return navigateToOrDefault;
};

export const generateRoutePath = (
  originalPath: string,
  routeProps?: Record<string, string>,
  queryProps?: Record<string, any>,
  encode: boolean = true,
): string => {
  const mainRoutePart = generatePath(originalPath, routeProps);
  const queryPart = queryProps !== undefined
    ? qs.stringify(queryProps, {
      arrayFormat: 'comma', skipEmptyString: true, skipNull: true, encode,
    })
    : '';

  return `${mainRoutePart}${queryPart ? `?${queryPart}` : ''}`;
};

export const useRouteParams = <R = Record<string, string>>() => {
  const params = useParams();
  return (params || {}) as R;
};

export const useRouteQueryParams = <Q>() => {
  const { search } = useLocation();
  return qs.parse(search) as Q;
};

export const useLocationState = <T>() => {
  const location = useLocation();
  return (location.state || {}) as T;
};

export const useNavigationParams = <R, Q>() => {
  const routeParams = useRouteParams<R>();
  const queryParams = useRouteQueryParams<Q>();

  return {
    routeParams,
    queryParams,
  };
};

export const useNavigationParamsAndState = <R, Q, S>() => {
  const {
    routeParams,
    queryParams,
  } = useNavigationParams<R, Q>();
  const state = useLocationState<S>();

  return {
    routeParams,
    queryParams,
    state,
  };
};
