import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router';

import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import HIDButton from '../../../../../components/buttons/HIDButton';
import { BarChart } from '../../../../../components/icons/Icons';
import { hidSpacing } from '../../../../../utils/number';
import { getNoWrapStyle } from '../../../../../utils/style';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import { useGetPropertyFinanceInformationQuery } from '../../../modules/Finances/api/api.propertyFinance';
import HIDSection from '../../../../../components/HIDSection';
import { BorderRadius } from '../../../../../constants/borderRadius';
import DeclarationCalculationsIncomeStatement from
  '../../../modules/Finances/modules/Declaration/pages/DeclarationCalculations/components/DeclarationCalculationsIncomeStatement';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import { getDeclarationCalculationPath } from '../../../modules/Finances/modules/Declaration/navigation.declaration';

const MarketValueEmpty = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home']);

  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleNavigateValuation = () => navigate(getPathWithPropertyIdOrInit(getDeclarationCalculationPath, { propertyId }));

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(3, 2, 2, 2),
        borderRadius: '5px',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: theme.spacing(8),
          height: theme.spacing(8),
          borderRadius: theme.spacing(4),
          backgroundColor: theme.palette.common.white,
          position: 'relative',
        }}
      >
        <BarChart
          iconColor={theme.palette.primary.main}
          size={hidSpacing(4)}
        />
      </Stack>
      <Typography
        sx={{
          color: theme.palette.common.white,
          textAlign: 'center',
          ...getNoWrapStyle(3),
        }}
        variant="body1"
      >
        {t('home:home_fill_in_the_numbers_for_your_home_and_deductions')}
      </Typography>
      <HIDButton
        noWrap
        color="blank"
        sx={{
          alignSelf: 'center',
          maxWidth: '100%',
        }}
        onClick={handleNavigateValuation}
      >
        {t('home:home_manage_finances')}
      </HIDButton>
    </Stack>
  );
};

const MarketValueWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'declarations']);
  const navigate = useNavigate();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: finances, isLoading } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);

  const handleNavigateValuation = () => navigate(getPathWithPropertyIdOrInit(getDeclarationCalculationPath, { propertyId }));

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.MARKET_VALUE);

  const isNilOrEmpty = (o: object | undefined | null) => R.isNil(o) || R.isEmpty(o);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2.5),
        height: widgetHeight,
      }}
    >
      <Stack gap={1.5} sx={{ height: '100%' }}>
        <Typography variant="h5">
          {t('home:home_sales_documents')}
        </Typography>
        {
          !isLoading && (isNilOrEmpty(finances?.valuations) || isNilOrEmpty(finances?.purchasePrice))
            ? (
              <MarketValueEmpty />
            )
            : (
              <>
                <HIDSection sx={{
                  padding: 0,
                  gap: 1,
                  borderStyle: 'unset',
                  width: '100%',
                  borderRadius: `${BorderRadius.md}px`,
                }}
                >
                  <DeclarationCalculationsIncomeStatement hideActions hideLabel />
                </HIDSection>
                <Stack gap={2} marginTop="auto">
                  <HIDButton
                    noWrap
                    sx={{
                      alignSelf: 'center',
                      maxWidth: '100%',
                    }}
                    onClick={handleNavigateValuation}
                  >
                    {t('home:home_detailed_sales_documents')}
                  </HIDButton>
                </Stack>
              </>
            )
        }
      </Stack>
    </Card>
  );
};

export default MarketValueWidget;
