import React, {
  ChangeEvent,
  FC,
  useState,
} from 'react';
import {
  useDebounce,
  useUpdateEffect,
} from 'usehooks-ts';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import {
  DynamicInputCommonFieldProps,
  DynamicInputTextField,
} from '../../../types/types.dynamicContent';
import HIDTextField, { HIDTextFieldProps } from '../../../../../../../../../components/HIDTextField';
import DynamicContentSource from './DynamicContentSource';

type DynamicContentTextInputProps = DynamicInputCommonFieldProps<string> & DynamicInputTextField & {
  multiline?: boolean;
  numberOfLines?: number;
};

const DynamicContentTextInput: FC<DynamicContentTextInputProps> = ({
  id,
  title,
  // description,
  mandatory,
  placeholder,
  readonly,
  errorMessage,
  value: valueProp,
  unitInfo,
  source,
  formSettings,
  multiline = false,
  sx,
  onValueChange,
  onBlur,
}) => {
  const theme = useTheme();
  // const info = getDynamicContentComponentInfo(title, description);

  const [value, setValue] = useState<string>(valueProp);
  const debouncedValue = useDebounce<string>(value, 250);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useUpdateEffect(() => {
    if (onValueChange) {
      onValueChange(debouncedValue);
    }
  }, [debouncedValue]);

  const helperText = (
    <Stack>
      {errorMessage && (
        <Typography variant="inherit">
          {errorMessage}
        </Typography>
      )}
      <DynamicContentSource source={source} />
    </Stack>
  );

  const backgroundColor = formSettings?.backgroundColor;
  const color = formSettings?.color;

  return (
    <HIDTextField
      backgroundColor={backgroundColor}
      color={color as HIDTextFieldProps['color']}
      disabled={readonly}
      endAdornment={
        unitInfo?.abbreviation
          ? (
            <Typography
              color={theme.palette.grey[500]}
              sx={{ marginLeft: 0.5 }}
              variant="body2"
            >
              {unitInfo?.abbreviation}
            </Typography>)
          : undefined
      }
      error={Boolean((errorMessage))}
      helperText={helperText}
      id={id}
      label={title}
      multiline={multiline}
      placeholder={placeholder}
      required={mandatory}
      sx={sx}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
    />
  );
};

export default DynamicContentTextInput;
