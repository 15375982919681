import React, {
  FocusEventHandler,
  forwardRef,
} from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import * as R from 'ramda';

import {
  FCC,
  FCCProps,
  HelperTextFormProps,
} from '../types/common';
import { HIDZIndex } from '../constants/layout';
import HIDTypography from './HIDTypography';

export type HIDSelectItem = {
  id: string;
  name: string;
  isCategory?: boolean;
};

// TODO: Extract common logic to HIDFormSelectBase
export type HIDFormSelectProps = HelperTextFormProps & {
  required?: boolean;
  multilevel?: boolean;
  label: string;
  value: string[];
  items: Array<HIDSelectItem>;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: TextFieldProps['error'];
  placeholder?: string;
  multiple?: boolean;
  color?: TextFieldProps['color'];
  backgroundColor?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (event: SelectChangeEvent<Array<string>>) => void;
};

type HIDFormSelectPropsWithRef = FCCProps<HIDFormSelectProps, HTMLInputElement>;

const HIDFormMultiSelect: FCC<HIDFormSelectProps> = forwardRef<HTMLInputElement, HIDFormSelectPropsWithRef>((
  {
    required,
    multilevel = false,
    label,
    value,
    items,
    fullWidth = true,
    disabled,
    error,
    helperText,
    helperTextWrap = false,
    showHelperText = false,
    placeholder,
    backgroundColor: backgroundColorProp,
    sx,
    onBlur,
    onChange,
  },
  ref,
) => {
  const theme = useTheme();

  const getValuesToDisplay = (selected: Array<string>) => R.pipe(
    R.filter((item: HIDSelectItem) => selected.includes(item.id)),
    R.map((item) => item.name),
    R.join(', '),
  )(items);

  const backgroundColor = backgroundColorProp || theme.palette.primary.lightest;

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      required={required}
      sx={sx}
      variant="standard"
    >
      <InputLabel sx={{ zIndex: HIDZIndex.LABEL, pointerEvents: 'none' }}>
        {label}
      </InputLabel>
      <Select
        multiple
        inputRef={ref}
        placeholder={placeholder}
        renderValue={(selected) => getValuesToDisplay(selected)}
        sx={{
          ...(!value?.length && { backgroundColor }),
          '& .MuiInput-input:focus': {
            backgroundColor: theme.palette.common.transparent,
          },
        }}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      >
        {
          items.map((item) => item.isCategory
            ? (
              <ListSubheader key={item.id} sx={{ color: theme.palette.common.black }}>
                <HIDTypography sx={{ paddingY: 1 }} variant="subtitle1">
                  {item.name}
                </HIDTypography>
              </ListSubheader>
            )
            : (
              <MenuItem key={item.id} sx={{ marginLeft: multilevel ? 2 : 0 }} value={item.id}>
                <Checkbox checked={value.indexOf(item.id) > -1} />
                {item.name}
              </MenuItem>
            ))
        }
      </Select>
      {
        showHelperText && (
          <FormHelperText sx={{ textWrap: helperTextWrap ? 'wrap' : 'nowrap' }}>
            {helperText || ' '}
          </FormHelperText>
        )
      }
    </FormControl>
  );
});

HIDFormMultiSelect.displayName = 'HIDFormSelect';

export default HIDFormMultiSelect;
