import useCurrentProperty from './useCurrentProperty';

const SF_SOURCE = 'svenskfast.se';

const useIsSfSellerProperty = () => {
  const { data: property } = useCurrentProperty();
  return property?.saleInformation?.source?.sourceId === SF_SOURCE;
};

export default useIsSfSellerProperty;
