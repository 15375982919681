import React, { lazy } from 'react';
import {
  Outlet,
  RouteObject,
} from 'react-router-dom';

import RouteNames from './RouteNames';
import InitApp from '../modules/Auth/pages/InitApp';
import HIDErrorPage from '../components/HIDErrorPage';
import HIDRootLayout from '../components/layouts/HIDRootLayout';
import ProtectedRoute from './ProtectedRoute';
import AdminProtectedRoute from '../modules/Admin/navigation/AdminProtectedRoute';
import PartnersRouteConfig from '../modules/Partners/navigation/PartnersRouteConfig';
import LoginSecondFactor from '../modules/Auth/pages/LoginSecondFactor';
import ForgotPassword from '../modules/Auth/pages/forgotPassword/ForgotPassword';
import About from '../modules/Auth/pages/About';
import OnboardingRouteConfig from '../modules/Auth/navigation/OnboardingRouteConfig';
import HomeRouteConfig from '../modules/Property/navigation/HomeRouteConfig';
import AdminRouteConfig from '../modules/Admin/navigation/AdminRouteConfig';
import ForumRouteConfig from '../modules/Forum/navigation/ForumRouteConfig';
import SubscriptionPlansRouteConfig from '../modules/SubscriptionPlans/navigation/SubscriptionPlansRouteConfig';
import UserStorage from '../modules/Property/pages/UserStorage/UserStorage';
import Move from '../modules/Move/pages/Move';
import Home from '../modules/Property/pages/Home/Home';
import ConsumablesRouteConfig from '../modules/Consumables/navigation/ConsumablesRouteConfig';
import LandingPagesRouteConfig from './LandingPagesRouteConfig';
import DeclarationHelpPrivacyPolicy from '../modules/Auth/pages/DeclarationHelpPrivacyPolicy';

const Onboarding = lazy(() => import('../modules/Auth/pages/onboarding/Onboarding'));
const AdminHome = lazy(() => import('../modules/Admin/pages/AdminHome/AdminHome'));
const SignUp = lazy(() => import('../modules/Auth/pages/signUp/SignUp'));
const Login = lazy(() => import('../modules/Auth/pages/Login'));

const ROUTES_CONFIG: Array<RouteObject> = [
  {
    element: <Outlet />,
    ErrorBoundary: HIDErrorPage,
    children: [
      {
        path: RouteNames.LOGIN,
        element: (
          <ProtectedRoute
            authRequired={false}
            path={RouteNames.LOGIN}
            propertyRequired={false}
          >
            <Login />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.LOGIN_SECOND_FACTOR,
        element: (
          <ProtectedRoute
            authRequired={false}
            path={RouteNames.LOGIN_SECOND_FACTOR}
            propertyRequired={false}
          >
            <LoginSecondFactor />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.SIGN_UP,
        element: (
          <ProtectedRoute
            authRequired={false}
            path={RouteNames.SIGN_UP}
            propertyRequired={false}
          >
            <SignUp />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.FORGOT_PASSWORD,
        element: (
          <ProtectedRoute
            authRequired={false}
            path={RouteNames.FORGOT_PASSWORD}
            propertyRequired={false}
          >
            <ForgotPassword />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.ABOUT,
        element: <About />,
      },
      {
        path: RouteNames.DECLARATION_HELP_TERMS_AND_PRIVACY_POLICY,
        element: <DeclarationHelpPrivacyPolicy />,
      },
      {
        path: RouteNames.INIT,
        element: (
          <ProtectedRoute path={RouteNames.INIT} propertyRequired={false}>
            <InitApp />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.ONBOARDING,
        element: (
          <ProtectedRoute authRequired path={RouteNames.ONBOARDING} propertyRequired={false}>
            <Onboarding />
          </ProtectedRoute>
        ),
        children: OnboardingRouteConfig,
      },
      {
        path: RouteNames.PARTNERS,
        element: <Outlet />,
        children: PartnersRouteConfig,
      },
      {
        path: RouteNames.ADMIN_HOME,
        element: (
          <AdminProtectedRoute>
            <AdminHome />
          </AdminProtectedRoute>
        ),
        children: AdminRouteConfig,
      },
      {
        path: RouteNames.ROOT,
        element: <HIDRootLayout />,
        children: [
          {
            path: RouteNames.HOME,
            element: (
              <ProtectedRoute
                authRequired
                propertyRequired
                path={RouteNames.HOME}
              >
                <Home />
              </ProtectedRoute>
            ),
            children: HomeRouteConfig,
          },
          {
            path: RouteNames.FORUM,
            element: <Outlet />,
            children: ForumRouteConfig,
          },
          {
            path: RouteNames.SUBSCRIPTION_PLANS,
            element: <Outlet />,
            children: SubscriptionPlansRouteConfig,
          },
          {
            path: RouteNames.CONSUMABLES,
            element: <Outlet />,
            children: ConsumablesRouteConfig,
          },
          {
            path: RouteNames.USER_STORAGE,
            element: <UserStorage />,
          },
          {
            path: RouteNames.MOVE,
            element: (
              <ProtectedRoute
                path={RouteNames.MOVE}
                propertyRequired={false}
              >
                <Move />
              </ProtectedRoute>
            ),
          },
          {
            path: RouteNames.LANDINGS,
            element: <Outlet />,
            children: LandingPagesRouteConfig,
          },
          {
            path: RouteNames.ROOT,
            element: (
              <ProtectedRoute path={RouteNames.ROOT} propertyRequired={false}>
                <InitApp skipOrigin />
              </ProtectedRoute>
            ),
          },
        ].filter(Boolean),
      },
      {
        path: RouteNames.ANY_PAGE,
        element: (
          <ProtectedRoute path={RouteNames.ANY_PAGE} propertyRequired={false}>
            <InitApp skipOrigin />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default ROUTES_CONFIG;
