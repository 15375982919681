import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDLink from '../../../components/HIDLink';
import {
  FCC,
  FCCProps,
} from '../../../types/common';

export type ResendCodeLinkRef = {
  reset: () => void;
};

type ResendCodeLinkProps = {
  resendAfterSecondsCount?: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
};

type ResendCodeLinkPropsWithRef = FCCProps<ResendCodeLinkProps, ResendCodeLinkRef>;

const ResendCodeLink: FCC<ResendCodeLinkProps> = forwardRef<ResendCodeLinkRef, ResendCodeLinkPropsWithRef>((
  {
    resendAfterSecondsCount = 10,
    isVisible = true,
    isDisabled = false,
    onClick,
  },
  ref,
) => {
  const { t } = useTranslation(['settings']);
  const theme = useTheme();

  const countDownTimer = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    if (countDown === resendAfterSecondsCount) {
      clearInterval(countDownTimer.current);
      countDownTimer.current = setInterval(
        () => setCountDown((seconds) => Math.max(seconds - 1, 0)),
        1000,
      );
    } else if (countDown === 0) {
      clearInterval(countDownTimer.current);
    }
  }, [countDown, resendAfterSecondsCount]);

  useEffect(() => () => clearInterval(countDownTimer.current), []);

  useImperativeHandle(ref, () => ({
    reset: () => setCountDown(resendAfterSecondsCount),
  }));

  if (!isVisible) {
    return null;
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Typography>
        {t('settings:settings_if_you_did_not_get_the_code_please_click')}
      </Typography>
      {
        isDisabled || countDown !== 0
          ? (
            <Typography sx={{ color: theme.palette.grey[500] }}>
              {t('settings:settings_resend')}
            </Typography>
          )
          : (
            <HIDLink
              label={t('settings:settings_resend')}
              onClick={onClick}
            />
          )
      }
      <Typography variant="subtitle1">
        {countDown > 0 ? countDown : ''}
      </Typography>
    </Stack>
  );
});

ResendCodeLink.displayName = 'ResendCodeLink';

export default ResendCodeLink;
