import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import { useDropzone } from 'react-dropzone';
import {
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CloudUploadOutlined } from '@mui/icons-material';
import * as R from 'ramda';
import { SubscriptionFeature } from '@house-id/houseid-types/dist/subscriptionPlans';

import HIDButton from '../buttons/HIDButton';
import {
  AllSupportedFileMimeTypes,
  FileMimeType,
  ImageMimeTypes,
} from '../../constants/mimeTypes';
import {
  FCC,
  FCCProps,
} from '../../types/common';
import { hidSpacing } from '../../utils/number';
import { getAcceptMimeTypeMap } from './utils';
import useTryUseFeatureDialog from '../../modules/SubscriptionPlans/hooks/useTryUseFeatureDialog';

export type HIDFilePickerRef = {
  open: () => void;
};

type HIDFilePickerProps = {
  mimeTypes?: Array<FileMimeType>;
  isMultiple?: boolean;
  isLoading?: boolean;
  onFilesSelected?: (files: Array<File>) => void;
};

type HIDFilePickerPropsWithRef = FCCProps<HIDFilePickerProps, HIDFilePickerRef>;

const HIDFilePicker: FCC<HIDFilePickerProps> = forwardRef<HIDFilePickerRef, HIDFilePickerPropsWithRef>((
  {
    mimeTypes = AllSupportedFileMimeTypes,
    isMultiple = true,
    isLoading = false,
    onFilesSelected,
    sx,
  },
  ref,
) => {
  const { t } = useTranslation(['common', 'photos']);
  const theme = useTheme();

  const onlyImages = R.equals(mimeTypes, ImageMimeTypes);

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.STORAGE_SPACE });

  const onDrop = useCallback(
    (files: Array<File>) => {
      proceedToFeatureOrOpenSubscriptionDialog({
        onAction: () => {
          if (onFilesSelected) {
            onFilesSelected(files);
          }
        },
      });
    },
    [onFilesSelected, proceedToFeatureOrOpenSubscriptionDialog],
  );

  const acceptMap = getAcceptMimeTypeMap(mimeTypes);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    accept: acceptMap,
    multiple: isMultiple,
    onDrop,
  });

  useImperativeHandle(ref, () => ({
    open: () => open(),
  }));

  const title = onlyImages
    ? isMultiple ? t('photos:upload_photos') : t('photos:upload_photo')
    : t('common:add_new_attachments');
  const description = onlyImages
    ? isMultiple ? t('photos:drag_and_drop_images') : t('photos:drag_and_drop_image')
    : t('common:drag_and_drop_files');
  const buttonText = onlyImages
    ? isMultiple ? t('photos:select_images') : t('photos:select_image')
    : t('photos:select_attachments');

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1.5}
      {...getRootProps()}
      sx={{
        width: '100%',
        maxWidth: '100%',
        borderRadius: 1,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        background: isDragActive ? theme.palette.primary.lightest : theme.palette.common.white,
        textAlign: 'center',
        padding: 3,
        ...sx,
      }}
    >
      {
        isLoading
          ? (
            <CircularProgress
              size={hidSpacing(8)}
            />
          )
          : (
            <>
              <input {...getInputProps()} />
              <div
                style={{
                  padding: theme.spacing(1.5),
                  borderRadius: '50%',
                  backgroundColor: theme.palette.primary.lighter,
                }}
              >
                <CloudUploadOutlined
                  color="primary"
                  style={{ display: 'block' }}
                  sx={{
                    width: theme.spacing(4),
                    height: theme.spacing(4),
                  }}
                />
              </div>
              <Typography variant="h6">
                {title}
              </Typography>
              <Typography>
                {description}
              </Typography>
              <HIDButton onClick={() => R.always<void>}>
                {buttonText}
              </HIDButton>
            </>
          )
      }
    </Stack>
  );
});

HIDFilePicker.displayName = 'HIDFilePicker';

export default HIDFilePicker;
