import React, { useState } from 'react';
import {
  Card,
  Collapse,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';

import { FCC } from '../types/common';
import { hidSpacing } from '../utils/number';
import HIDLink from './HIDLink';

type HIDFaqItemProps = {
  question: string;
  answer: string;
};

const HIDFaqItem: FCC<HIDFaqItemProps> = ({
  question: label,
  answer: description,
  sx,
}) => {
  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();

  return (
    <Card
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        backgroundColor: theme.palette.primary.lightest,
        borderColor: theme.palette.primary.lightest,
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        ...sx,
      }}
    >
      <Stack
        padding={2}
        spacing={2}
      >
        <Collapse
          collapsedSize={hidSpacing(3)}
          in={expanded}
        >
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography flex={1} variant="subtitle1">
              {label}
            </Typography>
            <HIDLink
              Icon={expanded ? ExpandLess : ExpandMore}
              color="grey"
              label=""
              underline="none"
              onClick={() => setExpanded(!expanded)}
            />
          </Stack>
          <Typography>
            <ReactMarkdown components={{ p: 'span' }}>
              {description}
            </ReactMarkdown>
          </Typography>
        </Collapse>
      </Stack>
    </Card>
  );
};
export default HIDFaqItem;
