import { skipToken } from '@reduxjs/toolkit/query';

import { useGetActiveAuthUser } from '../external-services/firebase';
import { useGetUserSettingsQuery } from '../modules/Auth/api/api.settings';
import DialogNames from './useDialog/DialogNames';

type UseDialogWasShown = (dialogName: DialogNames) => boolean;

const useDialogWasShown: UseDialogWasShown = (dialogName) => {
  const { user: currentUser } = useGetActiveAuthUser();
  const userUId = currentUser?.uid;

  const {
    data,
  } = useGetUserSettingsQuery(userUId ? { userUId } : skipToken);

  return Boolean(dialogName && data?.excludedDialogsToShow?.includes(dialogName));
};

export default useDialogWasShown;
