import React, { FC } from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';

import {
  DynamicInputCommonFieldProps,
  DynamicInputTextField,
} from '../../../types/types.dynamicContent';
import HIDFormDatePicker, { HIDFormDatePickerProps } from '../../../../../../../../../components/datePicker/HIDFormDatePicker';
import DynamicContentSource from './DynamicContentSource';

type DynamicContentDatePickerProps = DynamicInputCommonFieldProps<string | undefined> & DynamicInputTextField;

const DynamicContentDatePicker: FC<DynamicContentDatePickerProps> = ({
  title,
  mandatory,
  readonly,
  errorMessage,
  value,
  source,
  formSettings,
  onValueChange,
  onBlur,
}) => {
  const handleValueChange = (date?: Date | undefined) => {
    if (onValueChange) {
      onValueChange(date?.toISOString());
    }
  };

  const color = formSettings?.color as HIDFormDatePickerProps['color'];

  const helperText = (
    <Stack>
      {errorMessage && (
        <Typography variant="inherit">
          {errorMessage}
        </Typography>
      )}
      <DynamicContentSource source={source} />
    </Stack>
  );

  return (
    <HIDFormDatePicker
      color={color}
      disabled={readonly}
      error={Boolean(errorMessage)}
      helperText={helperText}
      label={title}
      required={mandatory}
      value={value ? new Date(value) : undefined}
      onBlur={onBlur}
      onChange={handleValueChange}
    />
  );
};
export default DynamicContentDatePicker;
