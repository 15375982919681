import qs from 'query-string';
import { Article } from '@house-id/houseid-types/dist/article';

import { propertyApi } from '../../../../../api/api.property';
import { HIDApiTags } from '../../../../../../../api/HIDApiTags';

export const articleApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<Array<Article>, { pageSize?: number, classifications?: Array<string> }>({
      query: ({ pageSize, classifications }) => `/articles?${qs.stringify({ pageSize, classifications })}`,
      providesTags: [HIDApiTags.ARTICLE],
    }),
  }),
});

export const {
  useGetArticlesQuery,
} = articleApi;
