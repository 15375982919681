/* eslint-disable @typescript-eslint/no-unsafe-argument */

import * as R from 'ramda';

export const removeEmptyParams = R.reject<Array<string> | string | number | boolean | undefined | null>(
  R.anyPass([R.isEmpty, R.isNil]),
);

export const isNotNil = R.compose(R.not, R.isNil);

export const getObjDiff = (obj1: Record<string, any>, obj2: Record<string, any>) => {
  if (obj1 === obj2) return {}; // Identical values, no difference

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return obj2; // Primitive or entirely replaced
  }

  const diff = {} as Record<string, any>;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const allKeys = keys1.length > keys2.length ? keys1 : keys2;

  for (let i = 0; i < allKeys.length; i++) {
    const key = allKeys[i];
    if (!(key in obj1)) {
      // Key only exists in obj2
      diff[key] = isNotNil(obj2[key]) ? obj2[key] : null;
    } else if (!(key in obj2)) {
      // Key only exists in obj1 (optional, mark as removed)
      diff[key] = null;
    } else if (obj1[key] !== obj2[key]) {
      // Check nested structures or overwrite if arrays/objects differ
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (Array.isArray(val1) || Array.isArray(val2)) {
        // Replace arrays directly if they differ
        if (JSON.stringify(val1) !== JSON.stringify(val2)) {
          diff[key] = isNotNil(val2) ? val2 : null;
        }
      } else if (typeof val1 === 'object' && typeof val2 === 'object') {
        // Recurse for nested objects
        const subDiff = getObjDiff(val1, val2);
        if (Object.keys(subDiff).length > 0) {
          diff[key] = isNotNil(subDiff) ? subDiff : null;
        }
      } else {
        // Replace primitive values
        diff[key] = isNotNil(val2) ? val2 : null;
      }
    }
  }

  return diff;
};

export const getIsTypeOf = <TType>(propName: keyof TType) => (obj: any): obj is TType =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  obj && typeof obj === 'object' && propName in obj;

export const isTypeOf = <TType>(obj: any, propName: keyof TType): obj is TType => getIsTypeOf<TType>(propName)(obj);

export const nullify = (obj: object) => R.map(
  (propValue) => propValue === undefined ? null : propValue,
  obj,
);

export const isObject = (value: any) =>
  value !== null && typeof value === 'object';
