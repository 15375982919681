import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Close,
  Tune,
} from '@mui/icons-material';
import {
  Card,
  Grid,
  Stack,
  Skeleton,
  SwipeableDrawer,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDBreadcrumbs, { HIDBreadcrumbLink } from '../../../../../components/HIDBreadcrumbs';
import HIDButton, { HIDButtonProps } from '../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  HEADER_HEIGHT,
  HIDZIndex,
  PAGE_OUTER_SPACING,
} from '../../../../../constants/layout';
import { RootState } from '../../../../../store/store';
import { toggleSideDrawerOpen } from '../../../../../store/layoutReducer';
import { FCC } from '../../../../../types/common';
import { hidSpacing } from '../../../../../utils/number';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import HIDInfo from '../../../../../components/HIDInfo';
import {
  useGetSideBarWidth,
  useGetHeaderBannerHeight,
} from '../../../utils/utils.home';
import {
  getNoWrapStyle,
  getTypographyHeight,
} from '../../../../../utils/style';
import useGetSubscriptionPlanTrialPeriodInfo from '../../../../SubscriptionPlans/hooks/useGetSubscriptionTrialPeriodInfo';
import { useGetActiveAuthUser } from '../../../../../external-services/firebase';

const DrawerElementDivider = styled('div')(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(1, 0),
  borderStyle: 'solid',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderColor: theme.palette.grey[300],
}));

type TitleInfo = {
  isMarkdown?: boolean;
  description: string;
};

type HomeLayoutProps = {
  title?: string;
  titleInfo?: TitleInfo;
  isLoading?: boolean;
  TitleRightComponent?: React.ReactElement;
  ContentComponent?: React.ReactElement;
  Header?: React.FC<{ ManageButton?: React.ReactElement }>;
  Toolbar?: React.ReactElement;
  BodyLoaderSkeleton?: FCC<{ isLoading: boolean }>;
  ContentComponentPreloader?: FCC<{ isLoading: boolean }>;
  showBreadcrumbsLinks?: boolean;
  breadcrumbsLinks?: Array<HIDBreadcrumbLink>;
  SideColumn?: React.ReactElement;
  sideDrawerElements?: Array<React.ReactElement>;
  appliedFiltersCount?: number;
  manageButtonColor?: HIDButtonProps['color'];
  onBack?: () => void;
};

const HomeLayout: FCC<HomeLayoutProps> = ({
  title,
  titleInfo,
  TitleRightComponent,
  ContentComponent,
  ContentComponentPreloader,
  Header,
  Toolbar,
  isLoading = false,
  BodyLoaderSkeleton,
  showBreadcrumbsLinks = true,
  breadcrumbsLinks,
  SideColumn,
  sideDrawerElements,
  appliedFiltersCount,
  children,
  manageButtonColor = 'secondary',
  onBack,
}) => {
  const { t } = useTranslation(['common']);

  const dispatch = useDispatch();
  const theme = useTheme();

  const { isPartnerAuth } = useGetActiveAuthUser();
  const { isMenuOpened, isOpenSideDrawer } = useSelector((state: RootState) => state.layout);
  const { isDownMd, isDownLg } = useBreakPointsSizes();
  const hasSideDrawerElements = Boolean(sideDrawerElements?.length);
  const sideBarWidth = useGetSideBarWidth(isMenuOpened, Boolean(SideColumn));
  const headerBannerHeight = useGetHeaderBannerHeight();

  const {
    data: { isEnabled } = {},
  } = useGetSubscriptionPlanTrialPeriodInfo(undefined, { skip: isPartnerAuth });

  const hasHeader = Header !== undefined;

  const ManageButton = (
    <HIDButton
      Icon={Tune}
      color={manageButtonColor}
      size="small"
      sx={{ flexShrink: 0, alignSelf: 'center' }}
      onClick={() => dispatch(toggleSideDrawerOpen(!isOpenSideDrawer))}
    >
      {appliedFiltersCount ? t('common:manage_filters_applied', { count: appliedFiltersCount }) : t('common:manage')}
    </HIDButton>
  );

  const isUsingColumnHeaderLayout = Boolean(TitleRightComponent && isDownMd && title && hasSideDrawerElements);

  const isSidebarHidden = (isDownMd || (isDownLg && isMenuOpened));

  return (
    <>
      <Grid
        container
        sx={{ padding: PAGE_OUTER_SPACING }}
      >
        {showBreadcrumbsLinks && (
          <HIDBreadcrumbs
            links={breadcrumbsLinks}
            sx={{
              marginBottom: theme.spacing(2.5),
              marginLeft: {
                md: 0,
                xxs: theme.spacing(-1),
              },
            }}
            title={title}
            onBack={onBack}
          />
        )}
        <Grid
          container
          direction="row"
          position="relative"
          spacing={2.5}
        >
          <Grid
            item
            sx={{
              paddingRight: isSidebarHidden ? 'unset' : `${sideBarWidth + hidSpacing(2.5)}px`,
            }}
            xxs={12}
          >
            <Card
              sx={{
                overflow: 'visible',
                ...(isDownMd && { boxShadow: 'none' }),
              }}
            >
              {hasHeader
                ? isLoading
                  ? (
                    <Skeleton
                      height={getTypographyHeight(theme.typography.body1)}
                      sx={{
                        display: 'inline-block',
                        margin: {
                          xxs: theme.spacing(0.5, 0.5, 2.5, 0.5),
                          md: theme.spacing(4, 4, 0.5, 4),
                        },
                      }}
                      variant="rounded"
                      width="50%"
                    />
                  )
                  : <Header ManageButton={isDownMd ? ManageButton : undefined} />
                : null}
              <Stack
                sx={{
                  padding: {
                    xl: theme.spacing(4),
                    md: theme.spacing(3),
                    sm: 0,
                  },
                }}
              >
                {
                  !hasHeader && (
                    <Stack
                      alignItems={isUsingColumnHeaderLayout ? 'unset' : 'center'}
                      direction={isUsingColumnHeaderLayout ? 'column' : 'row'}
                      justifyContent="space-between"
                      sx={{ marginBottom: { sm: theme.spacing(4), xxs: theme.spacing(2) } }}
                    >
                      {
                        isLoading && !title
                          ? <Skeleton height={getTypographyHeight(theme.typography.body1)} variant="rounded" width="50%" />
                          : title && titleInfo
                            ? (
                              <HIDInfo
                                description={titleInfo.description}
                                isMarkdown={titleInfo.isMarkdown}
                                label={title}
                                labelSx={getNoWrapStyle()}
                                labelVariant={isDownLg ? 'h4' : 'h3'}
                              />
                            )
                            : (
                              <Typography
                                component="h1"
                                sx={getNoWrapStyle()}
                                variant={isDownLg ? 'h4' : 'h3'}
                              >
                                {title}&nbsp;
                              </Typography>
                            )
                      }
                      {(Boolean(TitleRightComponent) || (hasSideDrawerElements && isDownMd)) && (
                        <Stack
                          direction="row"
                          justifyContent={isUsingColumnHeaderLayout ? 'space-between' : 'unset'}
                          spacing={2}
                          sx={isUsingColumnHeaderLayout ? { marginTop: theme.spacing(1.5) } : undefined}
                        >
                          {TitleRightComponent}
                          {hasSideDrawerElements && isDownMd && ManageButton}
                        </Stack>
                      )}
                    </Stack>
                  )
                }
                {Toolbar}
                {
                  BodyLoaderSkeleton
                    ? <BodyLoaderSkeleton isLoading={isLoading}>{children}</BodyLoaderSkeleton>
                    : children
                }
              </Stack>
            </Card>
            {
              ContentComponentPreloader
                ? <ContentComponentPreloader isLoading={isLoading}>{ContentComponent}</ContentComponentPreloader>
                : ContentComponent
            }
          </Grid>
          <div
            style={{
              zIndex: HIDZIndex.SIDE_BAR,
              position: 'absolute',
              right: 0,
              width: sideBarWidth,
              height: '100%',
              display: isSidebarHidden ? 'none' : 'unset',
            }}
          >
            <div
              style={{
                position: 'sticky',
                top: (isEnabled ? headerBannerHeight : 0) + HEADER_HEIGHT + hidSpacing(2),
                width: sideBarWidth,
              }}
            >
              <Stack
                direction="column"
                spacing={2.5}
                sx={{ marginTop: 2.5 }}
              >
                {SideColumn}
              </Stack>
            </div>
          </div>
        </Grid>
      </Grid>
      {hasSideDrawerElements && isDownMd && (
        <SwipeableDrawer
          anchor="right"
          open={isOpenSideDrawer}
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: theme.palette.shadow[200],
              },
            },
          }}
          onClose={() => dispatch(toggleSideDrawerOpen(false))}
          onOpen={() => dispatch(toggleSideDrawerOpen(true))}
        >
          <Stack sx={{ width: 300, maxWidth: 300 }}>
            <>
              <HIDIconButton
                Icon={Close}
                sx={{
                  alignSelf: 'flex-end',
                  margin: theme.spacing(1, 1, 0, 0),
                }}
                onClick={() => dispatch(toggleSideDrawerOpen(false))}
              />
              {
                sideDrawerElements?.flatMap((DrawerElement, index) => (
                  <React.Fragment key={`element-${index}`}>
                    <div style={{ padding: theme.spacing(0, 2, 2, 2) }}>
                      {DrawerElement}
                    </div>
                    {index < (sideDrawerElements.length - 1) && (
                      <DrawerElementDivider />
                    )}
                  </React.Fragment>
                ))
              }
            </>
          </Stack>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default HomeLayout;
