import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

import { FCC } from '../../types/common';
import HIDTypography from '../HIDTypography';
import { HIDThemeColor } from '../../theme';
import useBreakPointsSizes from '../../hooks/useBreakpointsSizes';

const HIDStepConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 1,
  },
}));

const HIDStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
  color?: HIDThemeColor;
}>(({ theme, ownerState, color = 'primary' }) => ({
  zIndex: 1,

  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: ownerState.active || ownerState.completed
    ? theme.palette[color].dark
    : theme.palette.common.black,
  backgroundColor: ownerState.active || ownerState.completed
    ? theme.palette[color].main
    : theme.palette.common.white,
}));

function HIDStepIcon({
  active,
  completed,
  className,
  icon,
  color,
}: StepIconProps & { color: HIDThemeColor }) {
  const textColor = active || completed ? 'white' : 'inherit';
  const { isDownSm } = useBreakPointsSizes();

  const size = isDownSm ? 36 : 50;
  const textVariant = isDownSm ? 'h6' : 'h5';

  return (
    <HIDStepIconRoot
      className={className}
      color={color}
      ownerState={{ completed, active }}
      sx={{
        width: size,
        height: size,
      }}
    >
      <HIDTypography color={textColor} variant={textVariant}>
        {icon}
      </HIDTypography>
    </HIDStepIconRoot>
  );
}

export type HorizontalStepperItem = {
  id: string;
  title: string;
  isActive?: boolean;
};

type HorizontalStepperProps = {
  activeStep: number;
  steps: Array<HorizontalStepperItem>;
  color?: HIDThemeColor;
};

const HorizontalStepper: FCC<HorizontalStepperProps> = ({
  steps,
  activeStep,
  color = 'primary',
}) => {
  const { isDownSm } = useBreakPointsSizes();
  const textVariant = isDownSm ? 'subtitle2' : 'subtitle1';

  return (
    <Stack spacing={4} sx={{ width: '100%' }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={
          <HIDStepConnector sx={{
            [`&.${stepConnectorClasses.alternativeLabel}`]: {
              top: isDownSm ? 16 : 22,
            },
          }}
          />
        }
      >
        {steps.map(({ id, title }) => (
          <Step key={id}>
            <StepLabel StepIconComponent={(props) => <HIDStepIcon {...props} color={color} />}>
              <HIDTypography variant={textVariant}>
                {title}
              </HIDTypography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
export default HorizontalStepper;
