import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Divider,
  Stack,
  useTheme,
} from '@mui/material';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import useGetCurrentProperty from '../../../../../../../hooks/useCurrentProperty';
import { DeclarationTableItem } from '../../../components/DeclarationTableRow';
import { useGetDeductionsOverviewQuery } from '../api/api.deductions';
import DeclarationHelpSummarySection from './DeclarationHelpSummarySection';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';

const DeclarationImportantDatesSection: FC = () => {
  const theme = useTheme();

  const { t } = useTranslation(['declarations', 'buildings', 'common']);
  const { data: property } = useGetCurrentProperty();
  const { isDownSm } = useBreakPointsSizes();

  const {
    data: deductionsOverview,
  } = useGetDeductionsOverviewQuery(property ? { propertyId: property.id } : skipToken);

  const importantDates: Array<DeclarationTableItem> = (deductionsOverview?.importantDates ?? [])
    .map(({ date, text }) => ({
      id: date,
      label: formatDate(new Date(date), DateTimeFormats.DATE_ONLY) || '',
      value: text,
    }));

  const dateColumnWidth = isDownSm ? 100 : 180;

  return importantDates.length > 0
    ? (
      <DeclarationHelpSummarySection
        title={t('declarations:declaration_help_summary_import_dates_section_title')}
        titleContainerSx={{ backgroundColor: theme.palette.skattio.lighter }}
      >
        <Stack style={{ padding: theme.spacing(2.5), gap: theme.spacing(1.5) }}>
          <Stack
            flex={1}
            flexDirection="row"
            gap={2.5}
          >
            <HIDTypography style={{ width: dateColumnWidth }} variant="subtitle1">
              {t('common:date')}
            </HIDTypography>
            <HIDTypography flex={1} variant="subtitle1">
              {t('common:event')}
            </HIDTypography>
          </Stack>
          {importantDates.map((item) => (
            <React.Fragment key={item.id}>
              <Divider />
              <Stack
                flex={1}
                flexDirection="row"
                gap={theme.spacing(2.5)}
              >
                <HIDTypography style={{ width: dateColumnWidth }} variant="body1">
                  {item.label}
                </HIDTypography>
                <HIDTypography flex={1} variant="body1">
                  {item.value}
                </HIDTypography>
              </Stack>
            </React.Fragment>
          ))}
        </Stack>
      </DeclarationHelpSummarySection>
    )
    : null;
};

export default DeclarationImportantDatesSection;
