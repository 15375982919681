import React, { forwardRef } from 'react';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
} from '@mui/material';

import HIDTextField, { HIDTextFieldProps } from './HIDTextField';
import {
  FCC,
  FCCProps,
  HelperTextFormProps,
} from '../types/common';

export type HIDSelectItem = {
  id: string;
  name: string;
};

type HIDFormFreeSelectProps = HelperTextFormProps & {
  freeSolo?: boolean;
  label?: string;
  value?: string;
  error?: boolean;
  items: Array<string>;
  fullWidth?: boolean;
  isLoading?: boolean;
  color?: HIDTextFieldProps['color'];
  backgroundColor?: HIDTextFieldProps['backgroundColor'];
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onChange: (event: React.SyntheticEvent<Element, Event>, value?: string) => void;
};

type HIDFormFreeSelectPropsWithRef = FCCProps<HIDFormFreeSelectProps, HTMLInputElement>;

const HIDFormFreeSelect: FCC<HIDFormFreeSelectProps> = forwardRef<HTMLInputElement, HIDFormFreeSelectPropsWithRef>((
  {
    freeSolo = true,
    label,
    value,
    items,
    fullWidth = true,
    helperText,
    helperTextWrap = false,
    showHelperText = true,
    error,
    sx,
    isLoading = false,
    color,
    backgroundColor,
    onBlur,
    onChange,
  },
  ref,
) => (
  <FormControl
    fullWidth={fullWidth}
    sx={sx}
    variant="standard"
  >
    <Autocomplete
      disableClearable
      forcePopupIcon
      freeSolo={freeSolo}
      options={items}
      renderInput={(params) => (
        <HIDTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          backgroundColor={backgroundColor}
          color={color}
          disabled={isLoading}
          error={error}
          helperText={helperText}
          helperTextWrap={helperTextWrap}
          label={label}
          ref={ref}
          showHelperText={showHelperText}
          value={value}
        />
      )}
      value={value}
      onBlur={onBlur}
      onInputChange={(event, value) => onChange(event, value || undefined)}
    />
  </FormControl>
));

HIDFormFreeSelect.displayName = 'HIDFormFreeSelect';

export default HIDFormFreeSelect;
