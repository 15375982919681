import {
  FC,
  ReactNode,
} from 'react';
import { FormikProps } from 'formik';
import { SxProps } from '@mui/system';
import {
  StackProps,
  TextFieldProps,
} from '@mui/material';
import { DynamicInputFormFieldType } from '@house-id/houseid-types/dist/dynamicContent/dynamicInputFormFieldType';

import {
  NavigationLink,
  Address,
  LabelValueObject,
  LabelValueWithIconObject,
  GeoPoint,
  ExternalService,
} from '../../../../../../../types/common';
import { HIDDocument } from '../../Document/types.document';

export enum ValidationDataType {
  POSITIVE_INTEGER = 'positive_integer',
  INTEGER = 'integer',
  STRING = 'string',
  DATE = 'date',
  DECIMAL = 'decimal',
  PHONE = 'phonenumber',
  EMAIL = 'email',
  LIST = 'multiselect',
  CURRENCY = 'currency',
}

export type DynamicContentBlock<TData = any> = {
  id: string;
  areaId: string;
  blockTypeId: string;
  start?: string;
  end?: string;
  data: TData;
  children?: Array<DynamicContentBlock>;
};

export type DynamicContentArea = {
  id: string;
  blocks: Array<DynamicContentBlock>;
};

export type DynamicContent = {
  areas: Array<DynamicContentArea>;
};

export type SliderCarouselData = {
  maxNumberOfChildren: number;
  layout: any;
};

export type ChildrenComponent = {
  type: string;
  Component: FC<any>;
  validChildrenComponents: Array<ChildrenComponent>;
  childrenTypes: Array<string>;
};

export type SlideItemData = {
  title: string;
  buttonTitle: string;
  imageUrl: string;
  backgroundImageUrl: string;
  layout: any;
  navigationData: any;
  link: any;
};

export type SlideData = {
  maxNumberOfChildren: number;
  layout: any;
};

export type DynamicComponentCommonProps<TData = any> = {
  id: string;
  data: TData;
  blockTypeId: string;
  areaId: string;
  childrenBlocks: Array<DynamicMappedContentBlock>;
};

export type DynamicMappedContentBlock<TData = any> = DynamicComponentCommonProps<TData> & {
  Component: FC<any>;
};

export type DynamicContainerInputStyle = {
  sx?: SxProps;
  formSettings?: DynamicInputFormColorSettings
};

export type DynamicInputFormColorSettings = {
  color?: string;
  backgroundColor?: string;
  primaryButtonColor?: string;
  secondaryButtonColor?: string;
};

export type DynamicInputCommonFieldProps<TValue> = DynamicContainerInputStyle & {
  value: TValue;
  onValueChange?: (value: TValue) => void;
  onBlur?: TextFieldProps['onBlur'];
  errorMessage?: string;
};

export type DynamicElementSettings = DynamicContainerInputStyle & {
  formik: FormikProps<Record<string, unknown>>,
  isLastElement?: boolean;
  formSettings?: DynamicInputFormColorSettings;
  index?: number;
  onReload?: () => void;
};

export type DynamicContainerFieldProps = {
  formSettings?: DynamicInputFormColorSettings;
  renderItem: (field: DynamicInputField, index: number) => ReactNode;
};

export type DynamicContentFieldSource = {
  sourceId: string;
  name: string;
  logoUrl: string;
};

export type DynamicInputCommonField = {
  id: string;
  type?: DynamicInputFormFieldType;
  title: string;
  description?: string;
  validationType?: ValidationDataType;
  placeholder?: string;
  readonly?: boolean;
  mandatory?: boolean;
  source?: DynamicContentFieldSource;
  components?: Array<DynamicInputField>;
  featured?: boolean;
};

export type DynamicContentListItem = {
  label: string;
  link?: NavigationLink;
};

export type DynamicContentAddButtonProps = DynamicNavigationContainer & DynamicContainerInputStyle;
export type DynamicContentArrowButtonProps = DynamicNavigationContainer & DynamicContainerInputStyle;

export type DynamicInputSingleValueField<TValue = any> = DynamicInputCommonField & {
  value: TValue;
};

export type DynamicNavigationContainer = DynamicInputCommonField & {
  link?: NavigationLink;
  editLink?: NavigationLink;
};

export type DynamicInputTextField = DynamicInputSingleValueField<string> & {
  unitInfo?: {
    abbreviation?: string,
    code?: string,
  };
};

export type DynamicTextField = DynamicInputSingleValueField<string> & {
  textStyle?: string;
};

export type DynamicFieldsMultipleOptions<TOption = LabelValueObject> = {
  options: Array<TOption>
};

export type DynamicSection = DynamicInputCommonField & { direction: StackProps['direction'] };
export type DynamicFactsContainer = DynamicNavigationContainer & {
  addButton?: { title: string; link: NavigationLink }
};

export type DynamicFactsGroup = DynamicNavigationContainer & {
  button?: { title: string; link: NavigationLink }
};
export type DynamicContentTextField = DynamicInputCommonField & DynamicInputSingleValueField<string>;
export type DynamicFact = DynamicInputTextField;

export enum DynamicContentImageInputHeightType {
  tiny = 'tiny',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export type HIDDocumentWithType = Omit<HIDDocument, 'classificationType'> & {
  classificationType?: {
    name: string;
    type: string;
  }
};

export type DynamicContentImageInput = DynamicInputSingleValueField<{ url: string, heightType: DynamicContentImageInputHeightType }>;
export type DynamicContentListInput = DynamicInputCommonField & DynamicInputSingleValueField<Array<DynamicContentListItem>>;
export type DynamicContentDocumentInput = DynamicInputCommonField & DynamicInputSingleValueField<HIDDocumentWithType>;
export type DynamicExternalServiceInput = DynamicInputCommonField & DynamicInputSingleValueField<ExternalService>;
export type DynamicGeoPointInput = DynamicInputCommonField & DynamicInputSingleValueField<GeoPoint>;
export type DynamicAddressTextInput = DynamicInputCommonField & DynamicInputSingleValueField<Address>;
export type DynamicSliderField = DynamicInputCommonField & DynamicFieldsMultipleOptions<LabelValueWithIconObject>;
export type DynamicButtonGroup = DynamicInputCommonField & DynamicFieldsMultipleOptions<LabelValueObject>;
export type DynamicRadioButtonListField = DynamicInputSingleValueField<string> & DynamicFieldsMultipleOptions<LabelValueWithIconObject>;
export type DynamicDropDownField = DynamicInputTextField & DynamicFieldsMultipleOptions & {
  showSearch?: boolean;
};
export type DynamicMultipleSelectField = DynamicInputSingleValueField<Array<string>> & DynamicFieldsMultipleOptions;

export type DynamicInputField =
  DynamicFactsContainer
  | DynamicFactsGroup
  | DynamicFact
  | DynamicDropDownField
  | DynamicSliderField
  | DynamicMultipleSelectField
  | DynamicSection
  | DynamicGeoPointInput
  | DynamicAddressTextInput
  | DynamicRadioButtonListField
  | DynamicContentImageInput
  | DynamicTextField;

export enum DynamicContentFormId {
  DECLARATION_HELP_DECLINED_QUESTIONS = 'declaration_help_declined_questions',
  SF_SELLER_QUESTIONS = 'svenskfast_seller_questions',
}
