import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import {
  useNavigateBackOr,
  useRouteQueryParams,
} from '../../../../../../../utils/routes';
import { getHomePath } from '../../../../../navigation/navigation.property';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { getFinancesPath } from '../../../navigation.finances';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDLink from '../../../../../../../components/HIDLink';
import useDialog from '../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import FinancesQuickNavigation from '../../../components/FinancesQuickNavigation';
import { FinancePageNames } from '../../../types.finances';
import ValuationCalculationSection from '../components/ValuationCalculationSection';
import BrokerCard from '../components/BrokerCard';
import ProfitOnSaleCard from '../components/ProfitOnSaleCard';
import ValuationOverviewCard from '../components/ValuationOverviewCard';
import { TutorialDialogTypes } from '../../../../../components/dialogs/tutorialDialog/useGetTutorialDialogSections';
import useShowDialogs from '../../../../../../../hooks/useShowDialogs';
import {
  ShowDialogItem,
  ShowDialogItemTargetType,
} from '../../../../../../../types/dialogs';

const ValuationOverview: FC = () => {
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const [openTutorialDialog] = useDialog(DialogNames.TUTORIAL_DIALOG);
  const [openIndicationValuationDialog] = useDialog(DialogNames.VALUATION_INDICATION_WIZARD_DIALOG);

  useShowDialogs(ValuationOverview.name, [
    {
      uniqueId: DialogNames.TUTORIAL_DIALOG + TutorialDialogTypes.VALUATION,
      name: DialogNames.TUTORIAL_DIALOG,
      target: ShowDialogItemTargetType.USER,
      props: { type: TutorialDialogTypes.VALUATION },
      maxCountToShow: 1,
    } as ShowDialogItem,
  ]);

  const [indicationValuationDialogTriggered, setIndicationValuationDialogTriggered] = useState(false);
  const { showIndicationValuationDialog } = useRouteQueryParams<{ showIndicationValuationDialog: boolean }>();

  useEffect(() => {
    if (showIndicationValuationDialog && !indicationValuationDialogTriggered) {
      setIndicationValuationDialogTriggered(true);
      openIndicationValuationDialog();
    }
  }, [showIndicationValuationDialog]);

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <FinancesQuickNavigation currentPage={FinancePageNames.VALUATION} />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
      ]}
      sideDrawerElements={[
        <FinancesQuickNavigation
          currentPage={FinancePageNames.VALUATION}
          key={FinancesQuickNavigation.name}
        />,
      ]}
      title={t('finances:valuation')}
      onBack={handleGoBack}
    >
      <Stack gap={3}>
        <Stack direction="row">
          <HIDTypography>
            {t('finances:read_more_about_valuation_features')}
          </HIDTypography>
          {' '}
          <HIDLink
            label={t('common:here')}
            sx={{ flexShrink: 0, ml: 0.5 }}
            onClick={() => openTutorialDialog({ type: TutorialDialogTypes.VALUATION })}
          />
          .
        </Stack>
        <ValuationOverviewCard />
        <BrokerCard />
        <ValuationCalculationSection />
        <ProfitOnSaleCard />
      </Stack>
    </HomeLayout>
  );
};

export default ValuationOverview;
