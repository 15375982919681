export const getRandomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

export const shuffle = <T>(list: Array<T>) => {
  let idx = -1;
  let position;
  const result: Array<T> = [];

  while (++idx < list.length) {
    position = Math.floor((idx + 1) * Math.random());
    result[idx] = result[position];
    result[position] = list[idx];
  }

  return result;
};
