import React, { lazy } from 'react';
import { RouteObject } from 'react-router';

import RouteNames from '../../../routes/RouteNames';
import ProtectedRoute from '../../../routes/ProtectedRoute';
import SubscriptionPlanPaymentsHistory from '../pages/SubscriptionPlanPaymentsHistory';
import SubscriptionPlanSubscriptionError from '../pages/SubscriptionPlanSubscriptionError';

const SubscriptionPlans = lazy(() => import('../pages/SubscriptionPlans/SubscriptionPlans'));
const SubscriptionPlanBuySubscription = lazy(() => import('../pages/SubscriptionPlanBuySubscription'));
const SubscriptionPlanSubscriptionOrder = lazy(() => import('../pages/SubscriptionPlanSubscriptionOrder'));

const SubscriptionPlansRouteConfig: Array<RouteObject> = [
  {
    path: RouteNames.SUBSCRIPTION_PLANS,
    element: (
      <ProtectedRoute
        authRequired={false}
        path={RouteNames.SUBSCRIPTION_PLANS}
        propertyRequired={false}
      >
        <SubscriptionPlans />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUBSCRIPTION_PLAN,
    element: (
      <ProtectedRoute
        authRequired={false}
        path={RouteNames.SUBSCRIPTION_PLANS}
        propertyRequired={false}
      >
        <SubscriptionPlans />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUBSCRIPTION_PLAN_BUY_SUBSCRIPTION,
    element: (
      <ProtectedRoute
        path={RouteNames.SUBSCRIPTION_PLAN_BUY_SUBSCRIPTION}
        propertyRequired={false}
      >
        <SubscriptionPlanBuySubscription />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER,
    element: (
      <ProtectedRoute
        path={RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER}
        propertyRequired={false}
      >
        <SubscriptionPlanSubscriptionOrder />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER_ERROR,
    element: (
      <ProtectedRoute
        path={RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ORDER_ERROR}
        propertyRequired={false}
      >
        <SubscriptionPlanSubscriptionError />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ERROR,
    element: (
      <ProtectedRoute
        path={RouteNames.SUBSCRIPTION_PLAN_SUBSCRIPTION_ERROR}
        propertyRequired={false}
      >
        <SubscriptionPlanSubscriptionError />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.SUBSCRIPTION_PLANS_SUBSCRIPTION_PAYMENTS_HISTORY,
    element: (
      <ProtectedRoute
        path={RouteNames.SUBSCRIPTION_PLANS_SUBSCRIPTION_PAYMENTS_HISTORY}
        propertyRequired={false}
      >
        <SubscriptionPlanPaymentsHistory />
      </ProtectedRoute>
    ),
  },

];

export default SubscriptionPlansRouteConfig;
