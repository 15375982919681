import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeductionCategory,
  DeductionTypeReceipt,
} from '@house-id/houseid-types/dist/deductions';
import { Stack } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { ReceiptInitialData } from '@house-id/houseid-types/dist/content/receipt';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../../../utils/date';
import HIDButton from '../../../../../../../../../../../components/buttons/HIDButton';
import DeductableReceiptsPickerCard from './DeductableReceiptsPickerCard';
import useDialog from '../../../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../../../hooks/useDialog/DialogNames';
import { getCreateReceiptPath } from '../../../../../../../../Content/modules/Receipt/navigation.receipt';
import { getPathWithPropertyIdOrInit } from '../../../../../../../../../../Auth/navigation/navigation.auth';
import useGetCurrentPropertyId from '../../../../../../../../../hooks/useGetCurrentPropertyId';

type DeductableReceiptsPickerProps = {
  deductionId?: string;
  deductionTypeId?: string;
  deductionCategoryId?: DeductionCategory;
  selectedItems: string[];
  onSelect: (items: string[]) => void;
  onBeforeAddNew?: () => void;
  receiptConnections: DeductionTypeReceipt[];
  receiptInitialValues?: Partial<ReceiptInitialData>;
};

const DeductableReceiptsPicker: FC<DeductableReceiptsPickerProps> = ({
  deductionId: deductionIdProp,
  deductionTypeId: deductionTypeIdProp,
  deductionCategoryId,
  receiptConnections,
  selectedItems: selectedItemsProps,
  receiptInitialValues,
  onSelect,
  onBeforeAddNew,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['deductions']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const selectedItems = selectedItemsProps || [];

  const connectedReceipts = receiptConnections
    .filter(({ deductionTypeId, deductionIds }) =>
      (deductionIds.length === 0 || (deductionIdProp && deductionIds.includes(deductionIdProp))) && deductionTypeId === deductionTypeIdProp)
    .map(({ receipt, amount }) => ({
      id: receipt.id,
      title: receipt.name,
      blob: receipt.mainImage || undefined,
      formattedDate: formatDate(new Date(receipt.date), DateTimeFormats.DATE_ONLY),
      deductableAmount: amount.amount,
      amount: receipt.amount.amount,
    }));

  const selectedReceipts = connectedReceipts.filter(({ id }) => selectedItems.includes(id));

  const handleAddItems = (items: Array<string>) => {
    onSelect(items);
  };

  const [openManageDeductionReceiptsDialog] = useDialog(DialogNames.MANAGE_DECLARATION_HELP_DEDUCTION_RECEIPTS_DIALOG);
  const handleOpenSelectReceipts = () => {
    if (deductionCategoryId) {
      openManageDeductionReceiptsDialog({
        deductionReceipts: connectedReceipts,
        deductionCategoryId,
        selectedItems,
        onSelect,
      });
    }
  };

  const handleAddNewReceipt = () => {
    onBeforeAddNew?.();
    navigate(
      getPathWithPropertyIdOrInit(getCreateReceiptPath, { propertyId }),
      { state: { initialData: { ...receiptInitialValues, deductionTypeId: deductionTypeIdProp, deductionId: deductionIdProp } } },
    );
  };

  return (
    <Stack gap={1.5}>
      <Stack gap={1}>
        {selectedReceipts.map((receipt) => (
          <DeductableReceiptsPickerCard
            key={receipt.id}
            receipt={receipt}
            onRemove={() => handleAddItems(selectedItems.filter((id) => id !== receipt.id))}
          />
        ))}
      </Stack>
      <Stack flexDirection="row" gap={1.5} justifyContent="flex-end">
        {connectedReceipts.length > 0 && (
          <HIDButton
            color="skattio-secondary"
            size="medium"
            title={t('deductions:retrieve_receipts')}
            onClick={handleOpenSelectReceipts}
          />
        )}
        <HIDButton
          Icon={Add}
          color="skattio-primary"
          title={t('deductions:add_receipt')}
          onClick={handleAddNewReceipt}
        />
      </Stack>
    </Stack>
  );
};

export default DeductableReceiptsPicker;
