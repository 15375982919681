import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import SalesDocuments from '../../../../../../../../../assets/images/sales_documents.png';
import { FCC } from '../../../../../../../../../types/common';
import HIDImage from '../../../../../../../../../components/image/HIDImage';
import useBreakPointsSizes from '../../../../../../../../../hooks/useBreakpointsSizes';

type SkattioDeclarationBannerProps = {
  isSmall?: boolean;
};

const SkattioDeclarationBanner: FCC<SkattioDeclarationBannerProps> = ({
  sx,
  isSmall,
  style,
}) => {
  const theme = useTheme();
  const { isDownMd } = useBreakPointsSizes();

  const isSmallSize = isDownMd || isSmall;

  return (
    <Stack
      style={{
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: '100%',
        background: theme.palette.skattio.lighter,
        width: isSmallSize ? 130 : 200,
        height: isSmallSize ? 130 : 200,
        overflow: 'hidden',
        ...style,
      }}
      sx={sx}
    >
      <HIDImage
        externalUrl={SalesDocuments}
        style={{
          width: isSmallSize ? 94 : 126,
          height: isSmallSize ? 188 : 252,
          objectFit: 'cover',
          marginTop: isSmallSize ? 96 : 104,
        }}
      />
    </Stack>
  );
};

export default SkattioDeclarationBanner;
