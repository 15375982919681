import React, {
  FC,
  useState,
} from 'react';
import {
  Collapse,
  Fade,
  Stack,
  useTheme,
} from '@mui/material';
import {
  Add,
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import HIDButton from '../../../../../components/buttons/HIDButton';
import HIDIconButton from '../../../../../components/buttons/HIDIconButton';
import {
  COLLAPSED_MENU_WIDTH,
  HEADER_HEIGHT,
  MENU_WIDTH,
} from '../../../../../constants/layout';
import MenuItem from './MenuItem';
import HIDLogo from '../../../../../components/HIDLogo';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  FCC,
  MenuProps,
} from '../../../../../types/common';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { getHomePath } from '../../../navigation/navigation.property';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import useGetPropertyPermissions from '../../../hooks/useGetPropertyPermissions';
import { MenuGroupId } from '../../../types/types.menu';
import useGetMenu from '../hooks/useGetMenu';
import PropertyProgressMenuItem from './PropertyProgressMenuItem';
import { hidSpacing } from '../../../../../utils/number';
import { getPropertyProgressPath } from '../../../modules/PropertyProgress/navigation.propertyProgress';

type CategoryIconChevronProps = {
  expanded: boolean;
  onClick: () => void;
};

const CategoryIconChevron: FC<CategoryIconChevronProps> = ({ expanded, onClick }) => {
  const theme = useTheme();

  const iconStyle = {
    color: theme.palette.common.white,
    width: 18,
    height: 18,
    strokeWidth: 1,
  };

  return (
    <Stack
      style={{
        cursor: 'pointer',
        zIndex: 1,
        position: 'absolute',
        backgroundColor: theme.palette.grey[700],
        borderRadius: 4,
        top: -6,
        right: -6,
      }}
      onClick={onClick}
    >
      {
        expanded
          ? <KeyboardArrowUp style={iconStyle} />
          : <KeyboardArrowDown style={iconStyle} />
      }
    </Stack>
  );
};

const Menu: FCC<MenuProps> = ({
  open,
  sx,
  onToggleOpen,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['common', 'home']);

  const [openAddContentDialog] = useDialog(DialogNames.ADD_CONTENT_DIALOG);
  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: { canCreate } = {} } = useGetPropertyPermissions();

  const { isDownMd, isDownXl } = useBreakPointsSizes();

  const [expendedSectionMaps, setExpendedSectionMaps] = useState<Record<MenuGroupId, boolean>>({} as Record<MenuGroupId, boolean>);

  const animationDuration = 600;

  const handleAdd = () => {
    if (isDownXl) {
      onToggleOpen(!open);
    }
    openAddContentDialog();
  };

  const menuGroups = useGetMenu();

  const handleToggleGroup = (groupId: MenuGroupId) => setExpendedSectionMaps({
    ...expendedSectionMaps,
    [groupId]: !(expendedSectionMaps[groupId] !== false),
  });

  const handlePropertyHomeClick = () => {
    navigate(getPathWithPropertyIdOrInit(getHomePath, { propertyId }));
    if (isDownXl) {
      onToggleOpen(!open);
    }
  };

  const handlePropertyProgressClick = () => {
    navigate(getPathWithPropertyIdOrInit(getPropertyProgressPath, { propertyId }));
    if (isDownXl) {
      onToggleOpen(!open);
    }
  };

  return (
    <Collapse
      collapsedSize={isDownMd ? 0 : COLLAPSED_MENU_WIDTH}
      in={open}
      key={isDownMd.toString()}
      orientation="horizontal"
      sx={{
        ...sx,
        flexShrink: 0,
        height: isDownMd ? `calc(100% - ${HEADER_HEIGHT}px)` : '100%',
        backgroundColor: theme.palette.common.white,
        top: isDownMd ? HEADER_HEIGHT : 0,
        position: isDownMd ? 'absolute' : 'unset',
        borderRightColor: theme.palette.grey[200],
        borderRightStyle: 'solid',
        borderRightWidth: isDownMd ? 0 : 1,
      }}
    >
      <Stack
        sx={{
          width: MENU_WIDTH,
          height: '100%',
        }}
      >
        <Fade unmountOnExit in={open} timeout={animationDuration}>
          <Stack
            flex={1}
            justifyContent="space-between"
            sx={{ height: '100%' }}
          >
            <Stack
              spacing={0.5}
              style={{ scrollbarWidth: 'none' }}
              sx={{
                minHeight: 0,
                overflow: 'auto',
              }}
            >
              <Stack
                flexDirection="column"
                sx={{
                  paddingTop: 2,
                  paddingX: 2,
                  borderBottomStyle: 'solid',
                  borderBottomColor: theme.palette.grey[200],
                  borderBottomWidth: 1,
                  position: isDownMd ? 'unset' : 'fixed',
                  width: MENU_WIDTH,
                  top: isDownMd ? HEADER_HEIGHT : 0,
                  left: 0,
                  backgroundColor: theme.palette.common.white,
                  zIndex: 1,
                }}
              >
                <HIDLogo
                  style={{
                    marginLeft: theme.spacing(0.5),
                    marginBottom: theme.spacing(2.875),
                  }}
                  onClick={handlePropertyHomeClick}
                />
                {canCreate && (
                  <HIDButton
                    Icon={Add}
                    color="yellow"
                    size="medium"
                    style={{
                      marginTop: theme.spacing(0.5),
                      marginBottom: theme.spacing(1.5),
                    }}
                    sx={{ height: theme.spacing(5) }}
                    onClick={handleAdd}
                  >
                    {t('common:add_label')}
                  </HIDButton>
                )}
              </Stack>
              <PropertyProgressMenuItem
                open={open}
                style={{
                  marginTop: hidSpacing(1.5) + (isDownMd ? 0 : (canCreate ? 126 : HEADER_HEIGHT)),
                  marginLeft: hidSpacing(1),
                  marginRight: hidSpacing(1),
                }}
                onClick={handlePropertyProgressClick}
              />
              {
                menuGroups
                  .map((group) => (
                    <Stack
                      key={group.id}
                      style={{
                        marginTop: hidSpacing(1),
                        marginBottom: hidSpacing(0.5),
                      }}
                      sx={{ paddingX: 2 }}
                    >
                      <MenuItem
                        isCategory
                        Icon={group.Icon}
                        isExpendedCategory={expendedSectionMaps[group.id] !== false}
                        label={group.label}
                        onClick={() => handleToggleGroup(group.id)}
                      />
                      <Collapse
                        in={expendedSectionMaps[group.id] !== false}
                        orientation="vertical"
                      >
                        <Stack flexDirection="column">
                          {
                            group.items.map((item) => (
                              <MenuItem
                                Icon={item.Icon}
                                count={item.count}
                                isNew={item.isNew}
                                key={item.id}
                                label={item.label}
                                showChevron={false}
                                onClick={() => {
                                  if (item.onClick) {
                                    item.onClick();
                                  } else if (item.path) {
                                    navigate(item.path);
                                  }
                                  if (isDownXl) {
                                    onToggleOpen(!open);
                                  }
                                }}
                              />
                            ))
                          }
                        </Stack>
                      </Collapse>
                    </Stack>
                  ))
              }
            </Stack>
            {!isDownMd && (
              <Stack
                sx={{
                  alignItems: 'flex-start',
                  backgroundColor: theme.palette.common.white,
                  padding: theme.spacing(2),
                  borderTopColor: theme.palette.grey[200],
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  gap: theme.spacing(2),
                }}
              >
                <HIDButton
                  fullWidth
                  Icon={ChevronLeft}
                  color="secondary"
                  sx={{
                    alignSelf: 'stretch',
                    width: `calc(${MENU_WIDTH}px - ${theme.spacing(4)})`,
                    height: '40px',
                  }}
                  onClick={() => onToggleOpen(!open)}
                >
                  {t('common:close')}
                </HIDButton>
              </Stack>
            )}
          </Stack>
        </Fade>
        <Fade
          unmountOnExit
          in={!open}
          timeout={animationDuration}
        >
          <Stack
            justifyContent="space-between"
            sx={{
              position: 'absolute',
              display: isDownMd ? 'none' : 'flex',
              height: '100%',
            }}
          >
            <Stack
              spacing={0.5}
              style={{ scrollbarWidth: 'none' }}
              sx={{
                alignItems: 'flex-start',
                minHeight: 0,
                overflow: 'auto',
              }}
            >
              <Stack
                sx={{
                  paddingTop: 2,
                  paddingX: 2,
                  borderBottomStyle: 'solid',
                  borderBottomColor: theme.palette.grey[200],
                  borderBottomWidth: 1,
                  position: isDownMd ? 'unset' : 'fixed',
                  width: COLLAPSED_MENU_WIDTH,
                  top: 0,
                  left: 0,
                  backgroundColor: theme.palette.common.white,
                  zIndex: 2,
                }}
              >
                <HIDLogo
                  size="small"
                  style={{
                    marginLeft: theme.spacing(0.5),
                    marginBottom: theme.spacing(2.875),
                  }}
                  onClick={handlePropertyHomeClick}
                />
                {canCreate && (
                  <HIDIconButton
                    Icon={Add}
                    color="yellow"
                    style={{
                      marginTop: theme.spacing(0.5),
                      marginBottom: theme.spacing(1.5),
                    }}
                    title={t('common:add_label')}
                    titlePlacement="right"
                    onClick={handleAdd}
                  />
                )}
              </Stack>
              <PropertyProgressMenuItem
                open={open}
                style={{
                  marginTop: hidSpacing(1.5) + (isDownMd ? 0 : (canCreate ? 126 : HEADER_HEIGHT)),
                  marginLeft: hidSpacing(1),
                  marginRight: hidSpacing(1),
                }}
                onClick={handlePropertyProgressClick}
              />
              {
                menuGroups
                  .map((group) => (
                    <Stack
                      key={group.id}
                      style={{
                        marginTop: hidSpacing(1),
                        marginBottom: hidSpacing(0.5),
                      }}
                      sx={{ paddingX: 2 }}
                    >
                      <Stack style={{ position: 'relative' }}>
                        <CategoryIconChevron
                          expanded={expendedSectionMaps[group.id] !== false}
                          onClick={() => handleToggleGroup(group.id)}
                        />
                        <HIDIconButton
                          Icon={group.Icon}
                          title={group.label}
                          titlePlacement="right"
                          onClick={() => handleToggleGroup(group.id)}
                        />
                      </Stack>
                      <Collapse
                        in={expendedSectionMaps[group.id] !== false}
                        orientation="vertical"
                      >
                        <Stack flexDirection="column">
                          {
                            group.items.map((item) => (
                              <HIDIconButton
                                Icon={item.Icon}
                                badge={item.count || (item.isNew ? t('common:new') : '')}
                                color="blank"
                                key={item.id}
                                title={item.label}
                                titlePlacement="right"
                                onClick={() => {
                                  if (item.onClick) {
                                    item.onClick();
                                  } else if (item.path) {
                                    navigate(item.path);
                                  }
                                  onToggleOpen(!open);
                                }}
                              />
                            ))
                          }
                        </Stack>
                      </Collapse>
                    </Stack>
                  ))
              }
            </Stack>
            <Stack
              sx={{
                alignItems: 'flex-start',
                width: open ? MENU_WIDTH : COLLAPSED_MENU_WIDTH,
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2),
                position: 'relative',
                borderColor: theme.palette.grey[200],
                borderStyle: 'solid',
                borderWidth: '1px 1px 0 0',
              }}
            >
              <HIDIconButton
                Icon={ChevronRight}
                color="secondary"
                title={t('common:close')}
                titlePlacement="right"
                onClick={() => onToggleOpen(!open)}
              />
            </Stack>
          </Stack>
        </Fade>
      </Stack>
    </Collapse>
  );
};

export default Menu;
