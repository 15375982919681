import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { Receipt } from '@house-id/houseid-types/dist/content/receipt';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';

import { formatMoney } from '../../../../../../../../../utils/string';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { useGetDeductionsTypesQuery } from '../../../../../../Finances/modules/Declaration/modules/DeclarationHelp/api/api.deductions';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';

type ReceiptSummarySectionProps = {
  receipt: Receipt;
};

const ReceiptSummarySection: FC<ReceiptSummarySectionProps> = ({
  receipt,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'receipts', 'declarations']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: deductionTypes } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const getDeductionNameByType = (deductionTypeId: string) =>
    deductionTypes?.find((deductionType) => deductionType.id === deductionTypeId)?.name;

  const deductionTypeAmounts = receipt?.deductionTypeAmounts || [];

  return (
    <Stack
      gap={1.5}
      sx={{
        padding: 2.5,
        backgroundColor: theme.palette.grey[100],
        borderRadius: BorderRadius.md,
      }}
    >
      <HIDTypography variant="subtitle1">
        {t('receipts:receipts_view_receipt_summary')}
      </HIDTypography>
      {deductionTypeAmounts.length > 0 && (
        <Stack sx={{ gap: 0.5 }}>
          <HIDTypography variant="subtitle1">
            {t('declarations:declaration_help_summary_deduction_section_title')}
          </HIDTypography>
          {
            deductionTypeAmounts.map((deductionTypeAmount) => (
              <Stack key={deductionTypeAmount.deductionType}>
                <HIDTypography>
                  {getDeductionNameByType(deductionTypeAmount.deductionType)}
                </HIDTypography>
                <HIDTypography>
                  {formatMoney(deductionTypeAmount.amount.amount, 'never')}
                </HIDTypography>
              </Stack>
            ))
          }
        </Stack>
      )}
      <Stack gap={0.5}>
        <HIDTypography variant="subtitle1">
          {t('receipts:receipts_receipts_total_label')}
        </HIDTypography>
        <HIDTypography variant="body1">
          {formatMoney(receipt?.amount, 'auto')}
        </HIDTypography>
      </Stack>
    </Stack>
  );
};

export default ReceiptSummarySection;
