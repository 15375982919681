import qs from 'query-string';

import { propertyApi } from './api.property';
import { SearchPropertyShortModel } from '../types/types.onboarding';

export const onboardingApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyRealtors: builder.query<Array<{ id: string, name: string }>, void>({
      query: () => 'enums/realtors',
    }),
    searchForProperties: builder.query<Array<SearchPropertyShortModel>, { query: string }>({
      query: (params) => `/uc/search?${qs.stringify({ q: params.query })}`,
    }),
  }),
});

export const {
  useGetPropertyRealtorsQuery,
  useLazySearchForPropertiesQuery,
} = onboardingApi;
