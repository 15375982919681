import React from 'react';
import {
  Button,
  ButtonProps,
} from '@mui/material';

import { FCC } from '../../types/common';
import GooglePlayLabel from './GooglePlayLabel';

type GooglePlayButtonProps = {
  onClick: ButtonProps['onClick']
};

const GooglePlayButton: FCC<GooglePlayButtonProps> = ({
  onClick,
  sx,
}) => (
  <Button
    style={{ padding: 0 }}
    sx={sx}
    onClick={onClick}
  >
    <GooglePlayLabel />
  </Button>
);

export default GooglePlayButton;
