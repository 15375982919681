/* eslint-disable @typescript-eslint/no-use-before-define */

/* eslint-disable react/destructuring-assignment */
import React, { ReactNode } from 'react';
import { DynamicInputFormFieldType } from '@house-id/houseid-types/dist/dynamicContent/dynamicInputFormFieldType';

import {
  DynamicAddressTextInput,
  DynamicButtonGroup,
  DynamicContentAddButtonProps,
  DynamicContentArrowButtonProps,
  DynamicContentDocumentInput,
  DynamicContentImageInput,
  DynamicContentListInput,
  DynamicDropDownField,
  DynamicElementSettings,
  DynamicExternalServiceInput,
  DynamicFact,
  DynamicFactsContainer,
  DynamicFactsGroup,
  DynamicGeoPointInput,
  DynamicInputCommonField,
  DynamicInputField,
  DynamicInputSingleValueField,
  DynamicInputTextField,
  DynamicMultipleSelectField,
  DynamicRadioButtonListField,
  DynamicSection,
  DynamicSliderField,
  DynamicTextField,
} from '../../../types/types.dynamicContent';
import DynamicContentTextInput from '../components/DynamicContentTextInput';
import DynamicContentAddressTextInput from '../components/DynamicContentAddressTextInput';
import DynamicContentDropDown from '../components/DynamicContentDropDown';
import DynamicContentDatePicker from '../components/DynamicComponentDatePicker';
import DynamicContentSection from '../components/DynamicContentSection';
import DynamicContentCheckBox from '../components/DynamicContentCheckBox';
import DynamicContentCounter from '../components/DynamicContentCounter';
import DynamicContentRadioButtonList from '../components/DynamicContentRadioButtonList';
import DynamicContentText from '../components/DynamicContentText';
import DynamicContentSlider from '../components/DynamicContentSlider';
import DynamicContentList from '../components/DynamicContentList';
import DynamicContentAddButton from '../components/DynamicContentAddButton';
import DynamicContentArrowButton from '../components/DynamicContentArrowButton';
import DynamicContentMultiSelect from '../components/DynamicContentMultiSelect';
import DynamicContentFact from '../components/DynamicContentFact';
import DynamicContentFactsContainer from '../components/DynamicContentFactsContainer';
import DynamicContentFactsGroup from '../components/DynamicContentFactsGroup';
import DynamicContentDocument from '../components/DynamicContentDocument';
import DynamicContentExternalServiceCard from '../components/DynamicContentExternalServiceCard';
import DynamicContentImage from '../components/DynamicContentImage';
import DynamicContentGeoPointInput from '../components/DynamicContentGeoPointInput';
import DynamicContentButtonGroup from '../components/DynamicContentButtonGroup';

type DynamicContentFactory<TField> = (field: TField, settings: DynamicElementSettings) => ReactNode;

const Elements: Record<string, DynamicContentFactory<DynamicInputField>> = {
  [DynamicInputFormFieldType.ADDRESS]:
    (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
      <DynamicContentAddressTextInput
        {...field as DynamicAddressTextInput}
        readonly
        errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
        formSettings={formSettings}
        onBlur={formik.handleBlur(field.id)}
        onValueChange={(value) => formik.setFieldValue(field.id, value)}
      />
    ),
  [DynamicInputFormFieldType.GEO_POINT]:
    (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
      <DynamicContentGeoPointInput
        {...field as DynamicGeoPointInput}
        readonly
        errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
        formSettings={formSettings}
        onBlur={formik.handleBlur(field.id)}
        onValueChange={(value) => formik.setFieldValue(field.id, value)}
      />
    ),

  [DynamicInputFormFieldType.INPUT]:
    (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
      <DynamicContentTextInput
        {...field}
        errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
        formSettings={formSettings}
        value={formik.values[field.id] as string}
        onBlur={formik.handleBlur(field.id)}
        onValueChange={(value) => formik.setFieldValue(field.id, value)}
      />
    ),
  [DynamicInputFormFieldType.INPUT_CURRENCY]:
    (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
      <DynamicContentTextInput
        {...field as DynamicInputTextField}
        errorMessage={
          formik.touched[field.id]
            ? (formik.errors[field.id] as { amount: string } | undefined)?.amount
            : undefined
        }
        formSettings={formSettings}
        value={(formik.values[field.id] as { amount: string })?.amount}
        onBlur={formik.handleBlur(field.id)}
        onValueChange={(value) => {
          const dynamicInputTextField = field as DynamicInputTextField;
          formik.setFieldValue(
            dynamicInputTextField.id,
            ({
              amount: value || 0,
              // @ts-ignore

              currency: formik.values[dynamicInputTextField.id]?.currency || dynamicInputTextField.unitInfo?.code,
            }),
          );
        }}
      />
    ),
  [DynamicInputFormFieldType.DROP_DOWN]:
    (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
      <DynamicContentDropDown
        {...field as DynamicDropDownField}
        errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
        formSettings={formSettings}
        value={formik.values[field.id] as string}
        onValueChange={(value) => {
          formik.setFieldValue(field.id, value);
          formik.handleBlur(field.id);
        }}
      />
    ),
  [DynamicInputFormFieldType.MULTI_SELECT]:
    (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
      <DynamicContentMultiSelect
        {...field as DynamicMultipleSelectField}
        errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
        formSettings={formSettings}
        value={formik.values[field.id] as Array<string>}
        onBlur={formik.handleBlur(field.id)}
        onValueChange={(value) => formik.setFieldValue(field.id, value)}
      />
    ),
  [DynamicInputFormFieldType.DATE_PICKER]:
    (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
      <DynamicContentDatePicker
        {...field as DynamicInputSingleValueField<string | undefined>}
        errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
        formSettings={formSettings}
        value={formik.values[field.id] as string}
        onValueChange={(value) => {
          formik.handleBlur(field.id);
          formik.setFieldValue(field.id, value);
        }}
      />
    ),
  [DynamicInputFormFieldType.SECTION]: (field: DynamicInputField, settings: DynamicElementSettings) => (
    <DynamicContentSection
      {...field as DynamicSection}
      renderItem={(inputField: DynamicInputField, index: number) =>
        renderElementByType(inputField, { ...settings, index })}
      title={field.title}
    />
  ),
  [DynamicInputFormFieldType.CHECKBOX]: (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
    <DynamicContentCheckBox
      {...field as DynamicInputSingleValueField<boolean>}
      errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
      formSettings={formSettings}
      value={formik.values[field.id] as boolean}
      onValueChange={(value) => {
        formik.handleBlur(field.id);
        formik.setFieldValue(field.id, value);
      }}
    />
  ),
  [DynamicInputFormFieldType.COUNTER]: (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
    <DynamicContentCounter
      {...field as DynamicInputSingleValueField<number>}
      errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
      formSettings={formSettings}
      value={formik.values[field.id] as number}
      onValueChange={(value) => {
        formik.handleBlur(field.id);
        formik.setFieldValue(field.id, value);
      }}
    />
  ),
  [DynamicInputFormFieldType.RADIO_BUTTON_LIST]: (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
    <DynamicContentRadioButtonList
      {...field as DynamicRadioButtonListField}
      errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
      formSettings={formSettings}
      value={formik.values[field.id] as string}
      onValueChange={(value) => {
        formik.handleBlur(field.id);
        formik.setFieldValue(field.id, value);
      }}
    />
  ),
  [DynamicInputFormFieldType.TEXT]: (field: DynamicInputField) => (
    <DynamicContentText
      {...field as DynamicTextField}
    />
  ),
  [DynamicInputFormFieldType.SLIDER]: (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
    <DynamicContentSlider
      {...field as DynamicSliderField}
      errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
      formSettings={formSettings}
      value={formik.values[field.id] as string}
      onValueChange={(value) => {
        formik.handleBlur(field.id);
        formik.setFieldValue(field.id, value);
      }}
    />
  ),
  [DynamicInputFormFieldType.BUTTON_GROUP]: (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
    <DynamicContentButtonGroup
      {...field as DynamicButtonGroup}
      errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
      formSettings={formSettings}
      value={formik.values[field.id] as string}
      onValueChange={(value) => {
        formik.handleBlur(field.id);
        formik.setFieldValue(field.id, value);
      }}
    />
  ),
  [DynamicInputFormFieldType.LIST]: (field: DynamicInputField, settings: DynamicElementSettings) => (
    <DynamicContentList
      formSettings={settings.formSettings}
      {...field as DynamicContentListInput}
    />
  ),
  [DynamicInputFormFieldType.ADD_BUTTON]: (field: DynamicInputField, settings: DynamicElementSettings) => (
    <DynamicContentAddButton
      formSettings={settings.formSettings}
      {...field as DynamicContentAddButtonProps}
    />
  ),
  [DynamicInputFormFieldType.ARROW_BUTTON]: (field: DynamicInputField, settings: DynamicElementSettings) => (
    <DynamicContentArrowButton
      formSettings={settings.formSettings}
      {...field as DynamicContentArrowButtonProps}
    />
  ),
  [DynamicInputFormFieldType.FACTS_CONTAINER]: (field: DynamicInputField, settings: DynamicElementSettings) => (
    <DynamicContentFactsContainer
      formSettings={settings.formSettings}
      {...field as DynamicFactsContainer}
      renderItem={(inputField: DynamicInputField, index: number) => renderElementByType(inputField, { ...settings, index })}
      title={field.title}
    />
  ),
  [DynamicInputFormFieldType.FACTS_GROUP]: (field: DynamicInputField, settings: DynamicElementSettings) => (
    <DynamicContentFactsGroup
      formSettings={settings.formSettings}
      {...field as DynamicFactsGroup}
      renderItem={(inputField: DynamicInputField, index: number) => renderElementByType(inputField, { ...settings, index })}
      title={field.title}
    />
  ),
  [DynamicInputFormFieldType.FACT]: (field: DynamicInputField, { formik, formSettings }: DynamicElementSettings) => (
    <DynamicContentFact
      {...field as DynamicFact}
      formSettings={formSettings}
      // @ts-ignore
      title={field.label}
      onValueChange={(value) => formik.setFieldValue(field.id, value)}
    />
  ),
  [DynamicInputFormFieldType.DOCUMENT]: (field: DynamicInputField) => (
    <DynamicContentDocument
      {...field as DynamicContentDocumentInput}
      readonly
    />
  ),
  [DynamicInputFormFieldType.EXTERNAL_SERVICE_INPUT]:
    (field: DynamicInputField, {
      formik, onReload,
    }: DynamicElementSettings) => (
      <DynamicContentExternalServiceCard
        {...field as DynamicExternalServiceInput}
        readonly
        errorMessage={formik.touched[field.id] ? formik.errors[field.id] : undefined}
        onBlur={formik.handleBlur(field.id)}
        onValueChange={() => onReload && onReload()}
      />
    ),
  [DynamicInputFormFieldType.IMAGE]:
    (field: DynamicInputField) => (
      <DynamicContentImage {...field as DynamicContentImageInput} />
    ),
};

export const renderElementByType = (inputField: DynamicInputCommonField, data: DynamicElementSettings) => {
  if (!inputField?.type) {
    return null;
  }

  const factory = Elements[inputField.type];
  if (!factory) {
    return null;
  }

  return factory(inputField, data);
};
