import React from 'react';
import {
  Stack,
  TypographyOwnProps,
  useTheme,
} from '@mui/material';

import HIDTypography from '../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../utils/string';

export type DeclarationTableItem = {
  id?: string;
  label: string;
  labelVariant?: TypographyOwnProps['variant'];
  value?: number | string;
  withDecimals?: boolean;
  prefix?: string;
  suffix?: string;
  wrapValue?: boolean;
  isImportant?: boolean;
  withBackground?: boolean;
  Component?: React.ReactNode;
};

type DeclarationTableProps = {
  item: DeclarationTableItem;
  loading?: boolean;
};

const DeclarationTableRow: React.FC<DeclarationTableProps> = ({
  item,
  loading,
}) => {
  const theme = useTheme();
  const labelTextStyle = item.labelVariant || 'body1' as const;
  const isNumberValue = typeof item.value === 'number';
  const wrapValue = item.wrapValue || false;
  const withDecimals = item.withDecimals !== undefined ? item.withDecimals : true;
  const { Component } = item;

  return (
    <Stack
      sx={[
        {
          flex: wrapValue ? 0 : 1,
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing(1),
        },
        wrapValue && { flexWrap: 'wrap' },
      ]}
    >
      <HIDTypography
        flex={1}
        variant={labelTextStyle}
      >
        {item?.label}
      </HIDTypography>
      {Component || (
        <Stack
          flexDirection="row"
          justifyContent={item.prefix ? 'space-between' : 'flex-end'}
          sx={{
            flexDirection: 'row',
            backgroundColor: !loading && item.withBackground
              ? Number(item.value) >= 0 ? theme.palette.skattio.lighter : theme.palette.error.lighter
              : undefined,
            minWidth: 130,
            maxWidth: wrapValue ? undefined : 200,
          }}
        >
          {item.prefix !== undefined && (
            <HIDTypography
              isLoading={loading}
              variant={item.isImportant ? 'subtitle1' : 'body1'}
            >
              {item.prefix}
            </HIDTypography>
          )}
          {item.value !== undefined && (
            <HIDTypography
              isLoading={loading}
              textAlign="right"
              variant={item.isImportant ? 'subtitle1' : 'body1'}
            >
              {!isNumberValue
                ? item.suffix ? `${item.value}${item.suffix}` : item.value
                : item.suffix ? `${item.value}${item.suffix}` : formatMoney(Number(item.value) || 0, withDecimals ? 'always' : 'never')}
            </HIDTypography>
          )}
        </Stack>
      )}
    </Stack>
  );
};
export default DeclarationTableRow;
