import {
  FC,
  PropsWithChildren,
  Ref,
} from 'react';
import {
  SxProps,
  Theme,
} from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { SvgIconComponent } from '@mui/icons-material';
import { PropertyUserType } from '@house-id/houseid-types/dist/property';
import {
  HIDBlob,
  HIDEntityId,
} from '@house-id/houseid-types/dist/common';
import { NavigationAction } from '@house-id/houseid-types/dist/navigationAction';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import PropertyExternalService from '../modules/Property/constants/constants.externalServices';

export type FCCProps<P, R = any> = P & {
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
  ref?: Ref<R>;
};

export type FCC<P = object, R = any> = FC<PropsWithChildren<FCCProps<P, R>>>;

export enum PARTNERS {
  SVENSKFAST = 'svenskfast',
}

export type PropertyUser = {
  id: string;
  name: string;
  email: string;
  type: PropertyUserType;
  blobs: HIDBlob[];
  surname: string;
  givenName?: string;
};

export type PropertyInvite = {
  data: {
    code: string;
    id: string;
  }
};

export type IconProps = {
  iconColor?: string;
  fillColor?: string;
  size?: number;
  strokeWidth?: number;
  title?: string;
  sx?: SxProps<Theme>;
  className?: string;
};

export type Icon = FC<IconProps> | SvgIconComponent;

export type ResponseData<TData> = {
  data: TData;
};

export type ActionError = {
  message?: string;
  data?: any; // TODO: create dedicated type
  code?: string;
  error: Error;
  status?: number;
  attributes?: Record<string, string>;
  errors?: Array<{ message: string; code: string, param?: string }>;
  skipGlobalHandle?: boolean;
};

export type QueryHookResult<TData> = {
  data: TData;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  isUninitialized?: boolean;
  error?: FetchBaseQueryError | SerializedError | undefined;
};

type QueryHookOptions = {
  skip?: boolean;
};

export type QueryHook<
  TData,
  TArguments = undefined,
> = (args?: TArguments, options?: QueryHookOptions) => QueryHookResult<TData> & {
  refetch: () => void;
};

export type HIDDialogProps = {
  open: boolean;
  onClose: () => void;
};

export type CreateEntity<T> = Omit<T, 'id' | 'key' | 'createdAt' | 'updatedAt'>;

export type HelperTextFormProps = {
  helperText?: React.ReactNode;
  showHelperText?: boolean;
  helperTextWrap?: boolean;
};

export type HIDEntity = HIDEntityId & {
  name: string;
};

export enum YesNoEnum {
  YES = 'yes',
  NO = 'no',
}

export type PaginationParams = {
  pageSize: number;
  offset: number;
};

export type Address = {
  streetAddress: string;
  postalCode: string;
  addressLocality: string;
};

export type GeoPoint = {
  type: string;
  coordinates: Array<string>;
};

type EntityCommonMetadata = {
  type: EntityType,
  propertyId: string,
  id: string
};

export type NavigationMeta = {
  entity: EntityCommonMetadata
};

// @deprecated use NavigationActionId instead
/**
 * @deprecated The component should not be used.
 * use NavigationActionId instead.
 */
export type NavigationData = {
  actionId: NavigationAction;
  params: object;
};

export type NavigationLink = {
  actionId?: NavigationAction;
  params?: object;
  url?: string;
  label?: string;
  source?: string;
  affiliate?: string;
  // @deprecated use _meta actionId instead
  // eslint-disable-next-line deprecation/deprecation
  navigationData?: NavigationData;
  _meta?: NavigationMeta;
  form?: {
    dynamicContentId: string,
  },
};

export type LabelValueObject = {
  value: string;
  label: string;
  description?: string;

};

export type LabelValueWithIconObject = LabelValueObject & {
  iconUrl?: string;
  shortLabel?: string;
};

export enum ExternalServiceStage {
  NOT_STARTED = 'notStarted',
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export type ExternalService = {
  id: string;
  serviceId: PropertyExternalService;
  classifications: Array<string>;
  name: string;
  information: string;
  additionalInformation: string;
  optOutInformation: string;
  connectedInformation: string;
  lastUpdated?: string;
  premiumRequired: boolean;
  bonus?: { points: number };
  buttonText?: string;
  status: {
    status: string;
    text: string;
    stage: ExternalServiceStage;
    footer?: {
      text: string;
      iconUrl: string;
    };
  };
  actions: {
    canSync: boolean;
  }
};

export type IconResource = {
  resourceId: string;
  url: string;
};

export type HIDListItemProps = {
  id: string;
  label: string;
  count?: number;
  Icon?: React.ElementType;
  RightIcon?: React.ElementType;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export type ReportErrorMessage = {
  key?: string;
  title: string;
  description: string;
};

export type MenuProps = {
  open: boolean;
  onToggleOpen: (open: boolean) => void;
};

export type HeaderProps = {
  isMenuOpened: boolean;
  isVisibleSearch: boolean;
  onToggleMenuOpen: (isOpen: boolean) => void;
  onToggleOpenSearch: (isOpen: boolean) => void;
};

export type HIDUploadFileContainer = {
  id: string;
  signedUploadUrl: string;
};

export type EnumDictionary<TValue = string> = Record<string, TValue>;

export enum EnumType {
  ExpensePaymentCategory = 'ExpensePaymentCategory',
  ExpensePaymentInterval = 'ExpensePaymentInterval',
}
