import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import {
  Add,
  Remove,
} from '@mui/icons-material';

import { FCC } from '../types/common';
import HIDIconButton, { HIDIconButtonProps } from './buttons/HIDIconButton';
import HIDInfo from './HIDInfo';
import { hidSpacing } from '../utils/number';

type HIDCounterProps = {
  label: string;
  description?: string;
  value?: number;
  minCount?: number;
  buttonColor?: string;
  onChange: (count: number) => void;
};

const HIDCounter: FCC<HIDCounterProps> = ({
  label,
  description,
  minCount = 0,
  value = 0,
  buttonColor: buttonColorProp,
  onChange,
}) => {
  const buttonColor = buttonColorProp || 'secondary';

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
    >
      <HIDInfo description={description} label={label} sx={{ flex: 1 }} />
      <HIDIconButton
        Icon={Remove}
        color={buttonColor as HIDIconButtonProps['color']}
        disabled={value === minCount}
        size="small"
        onClick={() => onChange(value - 1)}
      />
      <Typography sx={{ minWidth: hidSpacing(3.5) }} textAlign="center">{value}</Typography>
      <HIDIconButton
        Icon={Add}
        color={buttonColor as HIDIconButtonProps['color']}
        size="small"
        onClick={() => onChange(value + 1)}
      />
    </Stack>
  );
};

export default HIDCounter;
