import React, {
  FC,
  useMemo,
} from 'react';
import {
  Grid,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { skipToken } from '@reduxjs/toolkit/query';
import { PropertyValuation } from '@house-id/houseid-types/dist/finances/valuation';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import {
  useNavigateBackOr,
  useRouteParams,
} from '../../../../../../../utils/routes';
import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import { getFinancesPath } from '../../../navigation.finances';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import HIDTextField from '../../../../../../../components/HIDTextField';
import HIDCurrencyField from '../../../../../../../components/HIDCurrencyField';
import { CreateEntity } from '../../../../../../../types/common';
import { getHandleSetField } from '../../../../../../../utils/form';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import CreateContentPageBottomToolbar, {
  CreateContentSaveMode,
  WithSaveMode,
} from '../../../../Content/components/CreateContentPageBottomToolbar';
import { IdPropRoute } from '../../../../../../../types/route';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import {
  useCreatePropertyValuationMutation,
  useGetPropertyValuationsQuery,
  useUpdatePropertyValuationMutation,
} from '../api/api.valuation';
import { getValuationOverviewPath } from '../navigation.valuation';
import HIDFormDatePicker from '../../../../../../../components/datePicker/HIDFormDatePicker';
import { Currency } from '../../../../../../../utils/string';
import { useGetPropertyRealtorsQuery } from '../../../../../api/api.onboarding';
import HIDFormSelect from '../../../../../../../components/HIDFormSelect';
import useManageConnectionAfterCreateOrUpdate from '../../../../Content/hooks/useManageConnectionAfterCreateOrUpdate';
import { OTHER_VALUATION_REALTOR_CODE } from '../constants.valuation';

const today = new Date();

const CreateUpdateBrokerValuation: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation([
    'common',
    'finances',
    'forms_common',
    'property',
  ]);
  const navigateBackOr = useNavigateBackOr();

  const { id: valuationId } = useRouteParams<IdPropRoute>();

  const { isDownXl } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: propertyValuations,
    isLoading,
  } = useGetPropertyValuationsQuery(propertyId ? { propertyId } : skipToken);

  const currentPropertyValuation = useMemo(
    () => propertyValuations
      ?.find((valuation) => valuation.id === valuationId),
    [propertyValuations],
  );

  const isUpdate = Boolean(valuationId);

  const { data: realtors = [] } = useGetPropertyRealtorsQuery();

  const [createPropertyValuation, { isLoading: isCreatingValuation }] = useCreatePropertyValuationMutation();
  const [updatePropertyValuation, { isLoading: isUpdatingValuation }] = useUpdatePropertyValuationMutation();

  const handleGoBack = () => navigateBackOr(getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }));

  const {
    afterUpdate,
    afterCreate,
  } = useManageConnectionAfterCreateOrUpdate({
    entityType: EntityType.VALUATION,
    connections: [],
    onGoBack: handleGoBack,
  });

  const handleFormSubmit = (values: WithSaveMode<CreateEntity<PropertyValuation>>) => {
    const { saveMode } = values;

    if (!propertyId) {
      return;
    }

    if (isUpdate && valuationId) {
      updatePropertyValuation({ id: valuationId, ...values })
        .unwrap()
        .then((entity) => afterUpdate(entity, saveMode));
    } else {
      createPropertyValuation(values)
        .unwrap()
        .then((entity) => {
          afterCreate(entity, saveMode);
        });
    }
  };

  const schema = Yup.object({
    date: Yup.date().required(t('forms_common:field_mandatory')),
    amount: Yup
      .number()
      .transform((_o, value) => value ? Number((value as string).toString().replaceAll(',', '.')) : undefined)
      .typeError(t('forms_common:field_mandatory'))
      .required(t('forms_common:field_mandatory')),
    brokerName: Yup.string().optional().nullable(),
    realtorId: Yup.string().optional().nullable(),
    brokerCompany: Yup.string()
      .test(
        'required',
        t('forms_common:field_mandatory'),
        (value, context) => Boolean(value || (context.parent as PropertyValuation).realtorId !== OTHER_VALUATION_REALTOR_CODE),
      ),
    saveMode: Yup.string().optional().nullable(),
  });

  const formik = useFormik<CreateEntity<PropertyValuation>>({
    initialValues: {
      date: currentPropertyValuation?.date || today.toISOString(),
      amount: currentPropertyValuation?.amount,
      brokerName: currentPropertyValuation?.brokerName,
      realtorId: currentPropertyValuation?.realtorId,
      brokerCompany: currentPropertyValuation?.brokerCompany,
      connections: currentPropertyValuation?.connections || [],
      currencyCode: currentPropertyValuation?.currencyCode || Currency.code,
      propertyId: (currentPropertyValuation?.propertyId || propertyId) as string,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  });

  const handleSave = (saveMode: CreateContentSaveMode) => {
    formik.setFieldValue('saveMode', saveMode);
    formik.submitForm();
  };

  const handleSetField = getHandleSetField<CreateEntity<PropertyValuation>>(formik);

  return (
    <HomeLayout
      SideColumn={isDownXl ? undefined : <div />}
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }),
          name: t('finances:valuation'),
        },
      ]}
      title={
        isUpdate
          ? t('finances:edit_broker_rating')
          : t('finances:fill_in_broker_valuation')
      }
      onBack={handleGoBack}
    >
      <Grid
        container
        columnSpacing={2.5}
        justifyContent="flex-start"
        rowSpacing={1}
      >
        <Grid item lg={6} xxs={12}>
          <HIDFormDatePicker
            fullWidth
            required
            error={Boolean((formik.touched.date) && formik.errors.date)}
            helperText={formik.touched.date ? formik.errors.date : undefined}
            label={t('finances:date_of_valuation')}
            value={formik.values.date ? new Date(formik.values.date) : undefined}
            onBlur={formik.handleBlur('date')}
            onChange={(date) => formik.setFieldValue('date', date?.toISOString())}
          />
        </Grid>
        <Grid item lg={6} xxs={12}>
          <HIDCurrencyField
            required
            error={Boolean(formik.touched.amount && formik.errors.amount)}
            helperText={formik.touched.amount ? formik.errors.amount : undefined}
            label={t('finances:market_value')}
            value={formik.values.amount?.toString() || ''}
            onBlur={formik.handleBlur('amount')}
            onChange={handleSetField('amount')}
          />
        </Grid>
        <Grid item lg={6} xxs={12}>
          <HIDTextField
            label={t('finances:broker')}
            value={formik.values.brokerName || ''}
            onBlur={formik.handleBlur('brokerName')}
            onChange={handleSetField('brokerName')}
          />
        </Grid>
        <Grid item lg={6} xxs={12}>
          <HIDFormSelect
            items={realtors}
            label={t('finances:brokerage_company')}
            value={formik.values.realtorId}
            onBlur={formik.handleBlur('realtorId')}
            onChange={handleSetField('realtorId')}
          />
        </Grid>
        {formik.values.realtorId === OTHER_VALUATION_REALTOR_CODE && (
          <Grid item lg={6} xxs={12}>
            <HIDTextField
              required
              error={Boolean(formik.touched.brokerCompany && formik.errors.brokerCompany)}
              helperText={formik.touched.brokerCompany ? formik.errors.brokerCompany : undefined}
              label={t('finances:broker')}
              value={formik.values.brokerCompany || ''}
              onBlur={formik.handleBlur('brokerCompany')}
              onChange={handleSetField('brokerCompany')}
            />
          </Grid>
        )}
      </Grid>
      <CreateContentPageBottomToolbar
        disabled={isLoading}
        loading={isCreatingValuation || isUpdatingValuation}
        showAddConnections={!isUpdate}
        sx={{ marginTop: theme.spacing(2) }}
        onCancel={handleGoBack}
        onSave={handleSave}
      />
    </HomeLayout>
  );
};

export default CreateUpdateBrokerValuation;
