import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaqCategoryType } from '@house-id/houseid-types/dist/faq';
import { useNavigate } from 'react-router';
import { ConsumableType } from '@house-id/houseid-types/dist/consumables';
import { skipToken } from '@reduxjs/toolkit/query';

import HIDTypography from '../../../../../../../../../../components/HIDTypography';
import SkattioDeclarationBanner from '../../components/SkattioDeclarationBanner';
import DoneAll from '../../../../../../../../../../components/icons/DoneAll';
import Forum from '../../../../../../../../../../components/icons/Forum';
import Guide from '../../../../../../../../../../components/icons/Guide';
import {
  FCC,
  Icon,
} from '../../../../../../../../../../types/common';
import { useGetFaqCategoryQuery } from '../../../../../../../../../Auth/modules/Settings/api/api.faq';
import HomeLayout from '../../../../../../../../pages/Home/components/HomeLayout';
import { BorderRadius } from '../../../../../../../../../../constants/borderRadius';
import { Currency } from '../../../../../../../../../../utils/string';
import HIDButton from '../../../../../../../../../../components/buttons/HIDButton';
import useGetCurrentPropertyId from '../../../../../../../../hooks/useGetCurrentPropertyId';
import HIDLink from '../../../../../../../../../../components/HIDLink';
import { getConsumableBuyPath } from '../../../../../../../../../Consumables/navigation/navigation.consumables';
import SkattioWithHouseIdImage from '../../../../../../../../../../assets/images/skattio_with_house_id.png';
import useBreakPointsSizes from '../../../../../../../../../../hooks/useBreakpointsSizes';
import StaRating from '../../../../../../../../../../components/StarRating';
import HIDFaqItem from '../../../../../../../../../../components/HIDFaqItem';
import { useGetPropertyFeaturesQuery } from '../../../../../../../../api/api.property';
import { getDeclarationHelpPrivacyPolicyPath } from '../../../../../../../../../Auth/navigation/navigation.auth';
import useIsSfSellerProperty from '../../../../../../../../hooks/useIsSfSellerProperty';

const BenefitIcon: FC<{ Icon: Icon }> = ({ Icon }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: theme.spacing(3),
        backgroundColor: theme.palette.skattio.lighter,
      }}
    >
      <Icon
        fillColor={theme.palette.skattio.dark}
        iconColor={theme.palette.skattio.dark}
        size={24}
        strokeWidth={2}
      />
    </Stack>
  );
};

const FeedbackCard: FCC<{ message: string, rating: number }> = ({
  message,
  rating,
  sx,
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        padding: 2.5,
        backgroundColor: theme.palette.info.lightest,
        borderRadius: BorderRadius.sm,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        ...sx,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <StaRating rating={rating} />
        <HIDTypography>
          {message}
        </HIDTypography>
      </Stack>
    </Card>
  );
};

const BuyDeclarationHelp: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(['declarations']);

  const { isDownXl, isDownMd, isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: faqQuestions } = useGetFaqCategoryQuery({ categoryId: FaqCategoryType.PURCHASE_DECLARATION_HELP });
  const { data: propertyFeatures } = useGetPropertyFeaturesQuery(propertyId ? { propertyId } : skipToken);
  const isSfSellerProperty = useIsSfSellerProperty();

  const handleOrder = () => {
    if (propertyId) {
      // TODO: replace with consumable from feature unlock
      navigate(getConsumableBuyPath({ consumableType: ConsumableType.DECLARATION_HELP }));
    }
  };

  const benefits = [
    {
      Icon: DoneAll,
      title: t('declarations:declarations_discover_all_deductions'),
      description: t('declarations:declarations_we_help_you_ensure_that_all_possible_deductions_are_included'),
    },
    {
      Icon: Forum,
      title: t('declarations:declarations_all_the_help_you_need_when_you_need_it'),
      description: t('declarations:declarations_questions_on_the_road_with_our_smart_chat'),
    },
    {
      Icon: Guide,
      title: t('declarations:declarations_step_by_step_guidance'),
      description: t('declarations:declarations_you_will_be_guided_through_the_entire_process'),
    },
  ];

  const DeclarationTerms = (
    <Stack
      sx={{
        justifyContent: isDownMd ? 'center' : 'flex-start',
        flexDirection: 'row',
      }}
    >
      <HIDTypography>
        {t('declarations:declarations_read_our_complete')}
        {' '}
        <HIDLink
          color="skattio"
          label={t('declarations:declarations_terms')}
          style={{ verticalAlign: 'top' }}
          onClick={() => navigate(getDeclarationHelpPrivacyPolicyPath())}
        />
        <span>.</span>
      </HIDTypography>
    </Stack>
  );

  const promotionPrice = propertyFeatures?.declarationHelp?.buyPrice?.promotionPrice;
  const regularPrice = propertyFeatures?.declarationHelp?.buyPrice?.regularPrice;

  return (
    <HomeLayout
      Header={() => (
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.skattio.main,
            padding: 4,
          }}
        >
          <Stack sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <HIDTypography
              color={theme.palette.common.white}
              sx={{ whiteSpace: 'pre-wrap' }}
              variant="h5"
            >
              {propertyFeatures?.declarationHelp?.buyText}
            </HIDTypography>
            <HIDButton
              color="skattio-secondary"
              size="large"
              style={{ minWidth: 200, alignSelf: isDownSm ? 'center' : undefined }}
              title={t('declarations:declarations_buy_for')}
              onClick={handleOrder}
            />
          </Stack>
          {!isDownSm && (
            <Stack sx={{ justifyContent: 'center' }}>
              <SkattioDeclarationBanner isSmall />
            </Stack>
          )}
        </Stack>
      )}
      SideColumn={isDownXl ? undefined : <div />}
      title={t('declarations:declarations_the_declaration_aid')}
    >
      <Stack sx={{ gap: 4 }}>
        <HIDTypography
          textAlign="center"
          variant="h3"
        >
          {t('declarations:declarations_declare_easily_and_maximize_your_refund')}
        </HIDTypography>
        <Stack sx={{ alignItems: 'center', gap: 2.5 }}>
          <img
            src={SkattioWithHouseIdImage}
            style={{
              width: isDownSm ? 300 : 360,
              height: isDownSm ? 203 : 244,
              marginTop: isDownMd ? theme.spacing(3) : theme.spacing(2),
              marginBottom: theme.spacing(-2),
            }}
          />
          <HIDTypography sx={{ textAlign: 'center' }} variant="h4">
            {t('declarations:declarations_why_choose_the_declaration_help')}
          </HIDTypography>
          <HIDTypography sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
            {t('declarations:declarations_get_the_most_out_of_your_home_sale_without_the_hassle')}
          </HIDTypography>
        </Stack>
        <Stack sx={{ gap: 2.5 }}>
          <HIDTypography variant="h4">
            {t('declarations:declarations_get_full_power')}
          </HIDTypography>
          <Stack
            sx={{
              gap: 2,
              flexDirection: isDownMd ? 'column' : 'row',
            }}
          >
            {benefits.map((benefit, index) => (
              <Stack
                key={index}
                sx={{
                  flex: 1,
                  flexDirection: 'column',
                  gap: 2,
                  padding: 3,
                  alignItems: 'center',
                  borderStyle: 'solid',
                  borderColor: theme.palette.grey[300],
                  borderRadius: BorderRadius.sm,
                }}
              >
                <BenefitIcon Icon={benefit.Icon} />
                <Stack sx={{ alignItems: 'center', gap: 1 }}>
                  <HIDTypography textAlign="center" variant="subtitle1">
                    {benefit.title}
                  </HIDTypography>
                  <HIDTypography textAlign="center">
                    {benefit.description}
                  </HIDTypography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack sx={{ gap: 2.5 }}>
          <HIDTypography variant="h4">
            {t('declarations:declarations_what_our_customers_say')}
          </HIDTypography>
          <Stack sx={{ flexDirection: isDownMd ? 'column' : 'row', gap: 2 }}>
            <FeedbackCard
              message={t('declarations:declarations_user_review_1')}
              rating={5}
              sx={{ flex: 1 }}
            />
            <FeedbackCard
              message={t('declarations:declarations_user_review_2')}
              rating={4}
              sx={{ flex: 1 }}
            />
          </Stack>
        </Stack>
        <Stack sx={{ gap: 2.5 }}>
          <HIDTypography variant="h4">
            {t('declarations:declarations_skattio_plus_house_id_declaration_service')}
          </HIDTypography>
          <HIDTypography>
            {t('declarations:declarations_we_are_dedicated_to_helping_you_declare_with_confidence')}
          </HIDTypography>
        </Stack>
        {Boolean(faqQuestions?.length) && (
          <Stack sx={{ gap: 2.5 }}>
            <HIDTypography variant="h4">
              {t('declarations:declarations_frequently_asked_questions')}
            </HIDTypography>
            <Stack sx={{ gap: 2 }}>
              {faqQuestions?.map((faq, index) => (
                <HIDFaqItem
                  answer={faq.answer}
                  key={index}
                  question={faq.question}
                  sx={{ backgroundColor: theme.palette.skattio.lightest }}
                />
              ))}
            </Stack>
          </Stack>
        )}
        <Stack
          sx={{
            gap: 2.5,
            padding: 2,
            backgroundColor: theme.palette.skattio.lighter,
            flexDirection: isDownMd ? 'column' : 'row',
            justifyContent: 'space-between',
          }}
        >
          <Stack
            sx={{
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <Stack
              sx={{
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: isDownMd ? 'center' : undefined,
                gap: 0.5,
              }}
            >
              {
                promotionPrice
                  ? (
                    <>
                      <HIDTypography>
                        {t('declarations:declarations_only')}
                      </HIDTypography>
                      <HIDTypography variant="subtitle1">
                        {promotionPrice} {Currency.label}
                      </HIDTypography>
                      <HIDTypography>
                        {`(${t('declarations:declarations_ordinary_price')} ${regularPrice} ${Currency.label})`}
                      </HIDTypography>
                    </>
                  )
                  : (
                    <>
                      <HIDTypography>
                        {t('declarations:declarations_only')}
                      </HIDTypography>
                      <HIDTypography variant="subtitle1">
                        {regularPrice} {Currency.label}
                      </HIDTypography>
                    </>
                  )
              }
            </Stack>
            {!isDownMd && !isSfSellerProperty && DeclarationTerms}
          </Stack>
          <HIDButton
            color="skattio-primary"
            size="large"
            style={{ alignSelf: 'center', minWidth: 200 }}
            title={t('declarations:declarations_buy_for')}
            onClick={handleOrder}
          />
          {isDownMd && !isSfSellerProperty && DeclarationTerms}
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default BuyDeclarationHelp;
