export const HEADER_HEIGHT = 72;
export const ICON_SIZE = 24;
export const MENU_WIDTH = 300;
export const COLLAPSED_MENU_WIDTH = 72;
export const MAIN_CONTENT_MAX_SIZE = 1400;

export const SIDE_BAR_WIDTH_XL = 350;
export const SIDE_BAR_WIDTH_LG = 320;
export const SIDE_BAR_WIDTH_MD = 280;

export const HEADER_BANNER_HEIGHT_SM = 90;
export const HEADER_BANNER_HEIGHT_MD = 56;

export const HIDZIndex: Record<string, number> = {
  LOADER: 10,
  ALERT: 6,
  HEADER: 5,
  MENU: 5,
  SIDE_BAR: 1,
  BACKDROP: 1,
  LOGO: 1,
  LABEL: 1,
  PROPERTY_PROGRESS: 1,
  CLOSE: 1,
};

export const LOGIN_PAGE_OUTER_SPACING = {
  lg: 5,
  sm: 3,
  xxs: 2.5,
};

export const PAGE_OUTER_SPACING = {
  sm: 3,
  xxs: 2.5,
};

export const LANDING_PAGE_OUTER_SPACING = {
  md: 3,
  sm: 2.5,
  xxs: 0,
};

export const FILTER_DEBOUNCE_TIME = 1000;

export const INPUT_FIELDS_DEBOUNCE_TIME = 350;

export const WIZARD_DIALOG_HEIGHT = 600;
