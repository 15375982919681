import React, {
  FC,
} from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeductionCategory } from '@house-id/houseid-types/dist/deductions';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { skipToken } from '@reduxjs/toolkit/query';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import { useNavigateBackOr } from '../../../../../../../../utils/routes';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { getFinancesPath } from '../../../../navigation.finances';
import { getDeclarationCalculationPath } from '../../navigation.declaration';
import useGetDeductionCategoryLabel from '../../modules/DeclarationHelp/hooks/useGetDeductionCategoryLabel';
import CreateContentPageBottomToolbar from '../../../../../Content/components/CreateContentPageBottomToolbar';
import {
  useGetPropertyFinanceSaleSummaryQuery,
  usePatchPropertyPreliminaryDeductionsMutation,
} from '../../../../api/api.propertyFinance';
import DeclarationCalculationsDeductionCategoryCard from './components/DeclarationCalculationsDeductionCategoryCard';
import {
  DeductionCategoryValueType,
  DeductionCategoryValues,
  ManagePropertyEstimatedDeductionsValues,
} from './types';

const emptyCategoryData = {
  type: DeductionCategoryValueType.SINGLE_VALUE,
  amount: '',
  receiptIds: [],
  hasReceipts: false,
  categoryReceipts: [],
  description: {
    preamble: '',
    full: '',
  },
};

const DeclarationCalculationsDeductions: FC = () => {
  const theme = useTheme();
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'finances', 'declarations', 'deductions']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const getDeductionCategoryLabel = useGetDeductionCategoryLabel();

  const handleGoBack = () => navigateBackOr(propertyId ? getDeclarationCalculationPath({ propertyId }) : undefined);

  const {
    data: saleSummary,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const [patchPropertyPreliminaryDeductions, { isLoading: isPatching }] = usePatchPropertyPreliminaryDeductionsMutation();

  const categorySchema = Yup.object<DeductionCategoryValues>({
    type: Yup.string(),
    amount: Yup.number(),
    receiptIds: Yup.array().of(Yup.string()),
  });

  const mapCategory = (category: DeductionCategory) => {
    const categoryData = saleSummary?.deductions[category];
    if (!categoryData || saleSummary === undefined) {
      return emptyCategoryData;
    }
    const categoryReceipts = categoryData.deductionReceipts;
    const hasReceipts = categoryReceipts.length > 0;
    const type = categoryData.deductionReceiptsAmount
      ? DeductionCategoryValueType.VALUE_WITH_RECEIPTS
      : DeductionCategoryValueType.SINGLE_VALUE;
    return {
      type,
      amount: categoryData?.amount?.toString() || '',
      categoryReceipts,
      receiptIds: categoryReceipts
        ?.filter((receipt) => receipt.selected)
        .map((receipt) => receipt.receipt.id) || [],
      hasReceipts,
      description: categoryData.description,
    };
  };

  const initialValues: ManagePropertyEstimatedDeductionsValues = {
    [DeductionCategory.PURCHASE_EXPENSE]: mapCategory(DeductionCategory.PURCHASE_EXPENSE),
    [DeductionCategory.SALES_EXPENSE]: mapCategory(DeductionCategory.SALES_EXPENSE),
    [DeductionCategory.RENOVATION]: mapCategory(DeductionCategory.RENOVATION),
  };

  const schema = Yup.object().shape({
    [DeductionCategory.PURCHASE_EXPENSE]: categorySchema,
    [DeductionCategory.SALES_EXPENSE]: categorySchema,
    [DeductionCategory.RENOVATION]: categorySchema,
  });

  const handleSubmit = (values: ManagePropertyEstimatedDeductionsValues) => {
    if (propertyId) {
      const result = {
        [DeductionCategory.PURCHASE_EXPENSE]: {
          amount: Number(values[DeductionCategory.PURCHASE_EXPENSE].amount),
          receiptIds: values[DeductionCategory.PURCHASE_EXPENSE].receiptIds,
        },
        [DeductionCategory.SALES_EXPENSE]: {
          amount: Number(values[DeductionCategory.SALES_EXPENSE].amount),
          receiptIds: values[DeductionCategory.SALES_EXPENSE].receiptIds,
        },
        [DeductionCategory.RENOVATION]: {
          amount: Number(values[DeductionCategory.RENOVATION].amount),
          receiptIds: values[DeductionCategory.RENOVATION].receiptIds,
        },
      };

      patchPropertyPreliminaryDeductions({ propertyId, deductions: result })
        .unwrap()
        .then(handleGoBack);
    }
  };

  const formik = useFormik<ManagePropertyEstimatedDeductionsValues>({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const categories = [
    {
      id: DeductionCategory.PURCHASE_EXPENSE,
      label: getDeductionCategoryLabel(DeductionCategory.PURCHASE_EXPENSE),
      description: t('deductions:preliminary_deductions_purchase_expenses_description'),
    },
    {
      id: DeductionCategory.SALES_EXPENSE,
      label: getDeductionCategoryLabel(DeductionCategory.SALES_EXPENSE),
      description: t('deductions:preliminary_deductions_sales_expenses_description'),
    },
    {
      id: DeductionCategory.RENOVATION,
      label: getDeductionCategoryLabel(DeductionCategory.RENOVATION),
      description: t('deductions:preliminary_deductions_renovation_description'),
    },
  ];

  return (
    <HomeLayout
      SideColumn={<div />}
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getDeclarationCalculationPath, { propertyId }),
          name: t('finances:declaration'),
        },
      ]}
      title={t('declarations:declarations_declaration_your_deductions_manage_deductions')}
      onBack={handleGoBack}
    >
      <Stack gap={3}>
        {categories.map((deductionCategory) => (
          <DeclarationCalculationsDeductionCategoryCard
            deductionCategoryId={deductionCategory.id}
            formik={formik}
            key={deductionCategory.id}
            label={deductionCategory.label}
          />
        ))}
        <CreateContentPageBottomToolbar
          disabled={false}
          loading={isPatching}
          showAddConnections={false}
          sx={{ marginTop: theme.spacing(2) }}
          onCancel={handleGoBack}
          onSave={() => formik.submitForm()}
        />
      </Stack>
    </HomeLayout>
  );
};

export default DeclarationCalculationsDeductions;
