import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router';

import Wallet from '../../../../../components/icons/Wallet';
import HIDButton from '../../../../../components/buttons/HIDButton';
import useBreakPointsSizes from '../../../../../hooks/useBreakpointsSizes';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import { formatPercentage } from '../../../../../utils/number';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import { formatMoney } from '../../../../../utils/string';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { getReceiptCategoriesPath } from '../../../modules/Content/modules/Receipt/navigation.receipt';
import { useGetPropertyFinanceInformationQuery } from '../../../modules/Finances/api/api.propertyFinance';

const AccommodationCostWidget: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['home']);
  const navigate = useNavigate();

  const { isDownLg, isDownXs } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: financeInfo } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken);
  const history = financeInfo?.expenses?.history || [];

  const lastMonth = R.last(history);
  const monthBeforeLast = history.length > 1 ? R.nth(history.length - 2, history) : undefined;

  const lastMonthName = lastMonth
    ? formatDate(new Date(lastMonth.year, lastMonth.month - 1, 1), DateTimeFormats.MONTH_FULL)
    : undefined;

  const diff = lastMonth && monthBeforeLast
    ? (lastMonth.amount / monthBeforeLast.amount) - 1
    : undefined;
  const periodFormatted = t('home:home_last_month').toLowerCase();

  const isEmpty = !lastMonth || !lastMonthName;

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.ACCOMMODATION_COST);

  const handleGetStarted = () => {
    if (propertyId) {
      navigate(getReceiptCategoriesPath({ propertyId }));
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: isEmpty ? theme.palette.primary.lightest : theme.palette.common.white,
        padding: theme.spacing(2.5),
        minHeight: widgetHeight,
        height: '100%',
      }}
    >
      <Stack
        alignItems="center"
        alignSelf="center"
        direction="row"
        spacing={1.5}
        sx={{ height: '100%' }}
      >
        <Stack
          alignSelf="center"
          sx={{
            padding: 2,
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: theme.spacing(6),
          }}
        >
          <Wallet iconColor={theme.palette.primary.main} />
        </Stack>
        {
          !isEmpty
            ? (
              <Stack spacing={0.5}>
                <Typography variant="body1">
                  {`${t('home:home_accommodation_cost')} ${lastMonthName}`}
                </Typography>
                <Typography variant="h4">
                  {formatMoney(lastMonth?.amount, 'never')}
                </Typography>
                {diff !== undefined && (
                  <Typography variant="body1">
                    {`${formatPercentage(diff, { withPrefixSign: true, wholeNumber: true })} vs ${periodFormatted}`}
                  </Typography>
                )}
              </Stack>
            )
            : (
              <Stack
                direction={isDownXs ? 'column' : 'row'}
                justifyContent="space-between"
                spacing={isDownXs ? 1 : 0}
                sx={{ width: '100%' }}
              >
                <Stack sx={{ flex: 1 }}>
                  <Typography variant="body1">
                    {t('home:home_get_a_handle_on_yours')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t('home:home_accommodation_cost')}
                  </Typography>
                </Stack>
                <HIDButton
                  noWrap
                  size={isDownLg ? 'small' : 'medium'}
                  sx={{
                    alignSelf: isDownXs ? 'flex-start' : 'center',
                    maxWidth: 128,
                    minWidth: 104,
                  }}
                  onClick={handleGetStarted}
                >
                  {t('home:home_get_started')}
                </HIDButton>
              </Stack>
            )
        }
      </Stack>
    </Card>
  );
};

export default AccommodationCostWidget;
