import { useAuthState } from 'react-firebase-hooks/auth';
import {
  EventReportSource,
  AnalyticsEvent,
  HIDEventCategory,
} from '@house-id/houseid-types/dist/analytics';
import { PartnersSource } from '@house-id/houseid-types/dist/partners';

import { getActiveAuth } from '../../../external-services/firebase';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import { useReportEventMutation } from '../api/api.analytics';
import { logCustomEvent } from '../external-services/googleAnalytics';

export type ReportEventParams = {
  event: AnalyticsEvent;
  source?: PartnersSource | string,
  hidCategory: HIDEventCategory,
  eventReportSource?: EventReportSource,
  params?: object
};

type UseGetReportEvent = () => (params: ReportEventParams) => void;

const useGetLogAnalyticsEvent: UseGetReportEvent = () => {
  const [reportEventMutation] = useReportEventMutation();

  const [currentUser] = useAuthState(getActiveAuth());
  const { data: propertyId } = useGetCurrentPropertyId(undefined, { skip: !currentUser });

  const logEvent = (
    params: ReportEventParams,
  ) => {
    const defaultQueryParams = { propertyId };
    const eventReportSource = params.eventReportSource || EventReportSource.GA;
    const { hidCategory } = params;
    const source = params.source || PartnersSource.HOUSE_ID;

    if ([EventReportSource.ALL, EventReportSource.DATABASE].includes(eventReportSource)) {
      reportEventMutation({
        type: params.event,
        source,
        hidCategory,
        ...defaultQueryParams,
        ...params.params,
      });
    }
    if ([EventReportSource.ALL, EventReportSource.GA].includes(eventReportSource)) {
      logCustomEvent(params.event, source, hidCategory, { ...defaultQueryParams, ...params.params });
    }
  };

  return logEvent;
};

export default useGetLogAnalyticsEvent;
