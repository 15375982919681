import { useNavigate } from 'react-router';
import { PropertyActivityId } from '@house-id/houseid-types/dist/property';
import {
  SubscriptionFeature,
  FeatureStatus,
} from '@house-id/houseid-types/dist/subscriptionPlans';

import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import { useLazyGetCurrentSubscriptionPlanQuery } from '../../../../../../../../SubscriptionPlans/api/api.subscriptionPlans';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import useGetPropertyDeclarationHelpClaimed from './useGetPropertyDeclarationHelpClaimed';
import { useLazyGetPropertyActivitiesQuery } from '../../../../../../../api/api.property';
import {
  getBuyDeclarationHelpPath,
  getDeclarationHelpOverviewPath,
} from '../navigation/navigation.declarationHelp';
import useDialogWasShown from '../../../../../../../../../hooks/useDialogWasShown';

type UseGetNavigateToDeclarationHelp = (params?: { skipWelcomeDialog: boolean }) => [() => void, boolean];

const useGetNavigateToDeclarationHelp: UseGetNavigateToDeclarationHelp = (params) => {
  const navigate = useNavigate();
  const { data: propertyId } = useGetCurrentPropertyId();
  const [getCurrentSubscriptionPlan] = useLazyGetCurrentSubscriptionPlanQuery();
  const getPropertyDeclarationHelpClaimed = useGetPropertyDeclarationHelpClaimed();

  const declarationHelpDialogWasShown = useDialogWasShown(DialogNames.DECLARATION_HELP_DIALOG);
  const skipWelcomeDialog = params?.skipWelcomeDialog || declarationHelpDialogWasShown;

  const [openDeclarationHelpDialog] = useDialog(DialogNames.DECLARATION_HELP_DIALOG);
  const [openDeclarationHelpWizardDialog] = useDialog(DialogNames.DECLARATION_HELP_WIZARD);
  const [getPropertyActivities, { isLoading }] = useLazyGetPropertyActivitiesQuery();

  const navigateToDeclarationHelp = async () => {
    const declarationHelpClaimed = await getPropertyDeclarationHelpClaimed(propertyId);

    if (!propertyId) {
      return;
    }

    if (declarationHelpClaimed) {
      getPropertyActivities({ propertyId, activity: PropertyActivityId.PROPERTY_DECLARATION_HELP_WIZARD_COMPLETED }, true)
        .unwrap()
        .then((activities) => {
          if (activities.length) {
            navigate(getDeclarationHelpOverviewPath({ propertyId }));
          } else if (skipWelcomeDialog) {
            openDeclarationHelpWizardDialog();
          } else {
            openDeclarationHelpDialog();
          }
        });
      return;
    }

    const subscriptionPlan = await getCurrentSubscriptionPlan(undefined, true).unwrap();

    if (subscriptionPlan?.me[SubscriptionFeature.NOT_SPENT_DECLARATION_HELP_CONSUMABLE]?.status === FeatureStatus.ENABLED) {
      if (skipWelcomeDialog) {
        openDeclarationHelpWizardDialog();
      } else {
        openDeclarationHelpDialog();
      }
    } else {
      navigate(getBuyDeclarationHelpPath({ propertyId }));
    }
  };
  return [navigateToDeclarationHelp, isLoading];
};

export default useGetNavigateToDeclarationHelp;
