import { EntityType } from '@house-id/houseid-types/dist/entityType';

import { GeoPoint } from '../../../types/common';

export type PropertyId = { propertyId: string };

export type PropertyIdNavigation = PropertyId;

type BonusPoints = {
  available: number;
  earned: number;
  used: number;
};

type Notifications = {
  total: number;
};

type Messages = {
  total: number;
  unread: number;
};

export type PropertyStatus = {
  activeMaintenanceTasks: number;
  activeTasks: number;
  bonusPoints: BonusPoints;
  notifications: Notifications;
  messages: Messages;
  suggestions: number;
  suggestionInsights: number;
  files: number;
};

export type PropertyInboundEmail = {
  inboundEmail: string;
  inboundEmailDomain: string;
  token: string;
};

export type UserRemovalRequest = PropertyId & {
  id: string;
  userId: string;
  createdByUserId: string;
  state: 'pending' | 'completed';
  createdAt: string;
};

export type PropertyBonusOffers = {
  availablePoints?: number;
  earnedBonusPoints?: {
    from: string;
    points: number;
  },
  bonusOffers?: {
    count?: number;
    readyToActivate?: number;
  },
};

export type StorageSummaryItem = {
  _meta: {
    entity: {
      type: EntityType;
      propertyId: string;
    },
  };
  type: string;
  count: number;
  totalSize: number;
};

export type StorageUsage = {
  quota: number;
  quotaExceeded: boolean;
  totalSize: number;
};

export type PropertyAddress = {
  postalCode: string;
  streetAddress: string;
  addressCountry: string;
  addressLocality: string;
};

export type PropertyStorageUsage = {
  propertyId: string;
  address: PropertyAddress;
  alias?: string;
  totalSize: number;
  summary: Array<StorageSummaryItem>;
};

export type UserStorageUsage = StorageUsage & {
  properties: Array<PropertyStorageUsage>;
};

export enum UserSyncDataActionType {
  REFRESH_TOKEN = 'reloadAuthToken',
  RESET_PROPERTY_DATA = 'resetPropertyData',
  RELOAD_PROPERTY_PROGRESS = 'reloadPropertyProgress',
}

export type InviteCode = {
  type: string;
  code: string;
  propertyId: string;
  propertyAddress?: PropertyAddress;
  inviterName: string;
};

export enum FormOfOwnership {
  OWNERSHIP = 'ownership',
  LAND_LEASE = 'land-lease',
  COOPERATIVE_LAW = 'cooperative-law',
  TENANCY_LAW = 'tenancy-law',
  RENT = 'rent',
  CONDOMINIUM = 'condominium',
  SHARE_IN_HOUSING_ASSOCIATION = 'share-in-housing-association',
  SHARE_IN_HOUSING_LIMITED_COMPANY = 'share-in-housing-limited-company',
}

export enum HousingType {
  VILLA_DETACHED = 'villa-detached',
  CHAIN_HOUSE = 'chain-house',
  SEMI_DETACHED_HOUSE = 'semi-detached-house',
  TOWNHOUSE = 'townhouse',
  APARTMENT = 'apartment',
  SUMMER_HOUSE = 'summer-house',
  WINTER_HOUSE = 'winter-house',
  PLOT = 'plot',
  AGRICULTURE = 'agriculture',
}

type CurrencyAmount = {
  amount: number;
  currency: string;
};

export type PropertySpecification = PropertyId & {
  formOfOwnership?: FormOfOwnership;
  floor?: string;
  housingType?: HousingType;
  yearOfConstruction?: number;
  apartmentNumber?: string;
  livingArea?: number;
  secondaryArea?: number;
  typeOfLiving: 'year-around';
  adultsInTheHousehold: number;
  childrenInTheHousehold: number;
  accessDate: string;
  totalNumberOfStoreysInBuilding?: number;
  moveInDate: string;
  memberOfCooperative: boolean;
  cooperativeName?: string;
  cooperativeOrganizationNumber?: string;
  propertyDesignation: string;
  centralPoint: GeoPoint;
  areaLand: number;
  areaWater: number;
  heatingSystem: string;
  secondaryHeatingSystem: string;
  powerCompany: string;
  powerMeterIdentificationNumber: string;
  powerCompanyCustomerNumber: string;
  mainFuse: string;
  grounding: string;
  hasPerformedEnergyDeclaration: boolean;
  energyClass: string;
  energyPerformance: number;
  energyDeclarationPerformed: string;
  energyDeclarationValid: string;
  hasPerformedRadonMeasurement: boolean;
  radonMeasurementPerformed: string;
  radonConcentration: number;
  water: string;
  drain: string;
  purchaseDate: string;
  purchasePrice: CurrencyAmount;
  realtor: string;
  taxationYear: number;
  taxationTotalValue: CurrencyAmount;
  taxationPropertyValue: CurrencyAmount;
  taxationLandValue: CurrencyAmount;
  numberOfMortgages: number;
  mortgageAmount: CurrencyAmount;
  alarmInstallationDate: string;
  alarmOperator: string;
  alarmNumberOfCameras: number;
  alarmNumberOfMainUnits: number;
  alarmNumberOfVibrationSensors: number;
  numberOfFireBlankets: number;
};

type PropertySpecificationOptionItem = {
  value: string;
  label: string;
};

export type PropertySpecificationOptions = {
  housingType: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  typeOfLiving: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  formOfOwnership: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  floor: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  heatingSystem: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  secondaryHeatingSystem: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  powerCompany: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  mainFuse: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  grounding: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  energyClass: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  water: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  drain: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  realtor: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
  alarmOperator: {
    id: string;
    options: Array<PropertySpecificationOptionItem>;
  };
};
