import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import {
  Card,
  Divider,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { DeclarationCalculationType } from '@house-id/houseid-types/dist/finances/finances';
import { useNavigate } from 'react-router';

import HomeLayout from '../../../../../../pages/Home/components/HomeLayout';
import { getPathWithPropertyIdOrInit } from '../../../../../../../Auth/navigation/navigation.auth';
import { useNavigateBackOr } from '../../../../../../../../utils/routes';
import { getHomePath } from '../../../../../../navigation/navigation.property';
import useGetCurrentPropertyId from '../../../../../../hooks/useGetCurrentPropertyId';
import { getFinancesPath } from '../../../../navigation.finances';
import HIDTypography from '../../../../../../../../components/HIDTypography';
import HIDLink from '../../../../../../../../components/HIDLink';
import useDialog from '../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../hooks/useDialog/DialogNames';
import { TutorialDialogTypes } from '../../../../../../components/dialogs/tutorialDialog/useGetTutorialDialogSections';
import FinancesQuickNavigation from '../../../../components/FinancesQuickNavigation';
import { FinancePageNames } from '../../../../types.finances';
import ViewEntityActions from '../../../../../Content/components/actions/ViewEntityActions';
import useShowDialogs from '../../../../../../../../hooks/useShowDialogs';
import {
  ShowDialogItemTargetType,
  ShowDialogItem,
} from '../../../../../../../../types/dialogs';
import DeclarationCalculationsSalesNumber from './components/DeclarationCalculationsSalesNumber';
import DeclarationCalculationsIncomeStatement from './components/DeclarationCalculationsIncomeStatement';
import DeclarationCalculationsYourDeductions from './components/DeclarationCalculationsYourDeductions';
import HIDSection from '../../../../../../../../components/HIDSection';
import useBreakPointsSizes from '../../../../../../../../hooks/useBreakpointsSizes';
import useGetPropertyPermissions from '../../../../../../hooks/useGetPropertyPermissions';
import {
  useGetPropertyFinanceSaleSummaryQuery,
  useUpdatePropertyFinanceInformationMutation,
} from '../../../../api/api.propertyFinance';
import Loan from '../../../../../../../../components/icons/Loan';
import BarChart from '../../../../../../../../components/icons/BarChart';
import HIDFormSelect from '../../../../../../../../components/HIDFormSelect';
import DeclarationCalculationsWithNewHousePurchase from './components/DeclarationCalculationsWithNewHousePurchase';
import DeclarationCalculationsRemainingResidentSituation from './components/DeclarationCalculationsRemainingResidentSituation';
import { DocMoney } from '../../../../../../../../components/icons/Icons';
import { getDeclarationCalculationsDeductionsPath } from '../../navigation.declaration';

const useGetDeclarationCalculationTypes = () => {
  const { t } = useTranslation(['declarations']);

  return [
    {
      id: DeclarationCalculationType.SALE_WITHOUT_NEW_HOME,
      name: t('declarations:declarations_declaration_sales_number_sale_without_new_home'),
    },
    {
      id: DeclarationCalculationType.SALE_WITH_NEW_HOME,
      name: t('declarations:declarations_declaration_sales_number_sale_with_new_home'),
    },
    {
      id: DeclarationCalculationType.SALE_WITH_DIVISION_OF_PROPERTY,
      name: t('declarations:declarations_declaration_sales_number_sale_with_division_of_property'),
    },
  ];
};

const DeclarationCalculations: FC = () => {
  const navigate = useNavigate();
  const navigateBackOr = useNavigateBackOr();
  const { t } = useTranslation(['common', 'finances', 'declarations']);

  const { isDownSm } = useBreakPointsSizes();
  const { data: { canUpdate } = {} } = useGetPropertyPermissions();

  const declarationCalculationTypes = useGetDeclarationCalculationTypes();
  const [selectedDeclarationCalculationType, setSelectedDeclarationCalculationType] = useState<DeclarationCalculationType>(
    DeclarationCalculationType.SALE_WITHOUT_NEW_HOME,
  );

  const { data: propertyId } = useGetCurrentPropertyId();

  const handleGoBack = () => navigateBackOr(propertyId ? getHomePath({ propertyId }) : undefined);

  const [openTutorialDialog] = useDialog(DialogNames.TUTORIAL_DIALOG);

  useShowDialogs(DeclarationCalculations.name, [
    {
      uniqueId: DialogNames.TUTORIAL_DIALOG + TutorialDialogTypes.DECLARATION,
      name: DialogNames.TUTORIAL_DIALOG,
      target: ShowDialogItemTargetType.USER,
      props: { type: TutorialDialogTypes.DECLARATION },
      maxCountToShow: 1,
    } as ShowDialogItem,
  ]);

  const [openManagePurchasePriceModal] = useDialog(DialogNames.MANAGE_PROPERTY_SALES_NUMBERS_DIALOG);

  const [openManageLoanRepaymentModal] = useDialog(DialogNames.MANAGE_LOAN_REPAYMENT_DIALOG);

  const handleManagePropertyEstimatedDeductionsDialog = () => {
    if (propertyId) {
      navigate(getDeclarationCalculationsDeductionsPath({ propertyId }));
    }
  };

  const {
    data: saleSummary,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const [updatePropertyFinanceInformation] = useUpdatePropertyFinanceInformationMutation();

  const handleManageLoanRepayment = openManageLoanRepaymentModal;

  const actions = canUpdate
    ? [
      {
        id: 'manage-property-purchase-price',
        Icon: BarChart,
        label: t('declarations:declarations_declaration_sales_number_manage'),
        onClick: openManagePurchasePriceModal,
      },
      {
        id: 'manage-loan-repayment',
        Icon: Loan,
        label: t('declarations:declarations_declaration_loan_repayment_manage'),
        onClick: handleManageLoanRepayment,
      },
      {
        id: 'manage-deductions',
        Icon: DocMoney,
        label: t('declarations:declarations_declaration_deductions_manage'),
        onClick: handleManagePropertyEstimatedDeductionsDialog,
      },
    ] : [];

  const handleChangeDeclarationCalculationType = (event: SelectChangeEvent) => {
    setSelectedDeclarationCalculationType(event.target.value as DeclarationCalculationType);
    if (propertyId) {
      updatePropertyFinanceInformation({
        propertyId,
        financeInformation: {
          salesCalculationTemplateId: event.target.value as DeclarationCalculationType,
        },
      });
    }
  };

  useEffect(() => {
    if (saleSummary?.salesCalculationTemplateId) {
      setSelectedDeclarationCalculationType(saleSummary.salesCalculationTemplateId);
    }
  }, [saleSummary]);

  return (
    <HomeLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              customActions={actions}
            />
          </Card>
          <Card sx={{ padding: 2 }}>
            <FinancesQuickNavigation currentPage={FinancePageNames.DECLARATION} />
          </Card>
        </>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
      ]}
      sideDrawerElements={[
        <ViewEntityActions
          customActions={actions}
          key={ViewEntityActions.name}
        />,
        <FinancesQuickNavigation
          currentPage={FinancePageNames.DECLARATION}
          key={FinancesQuickNavigation.name}
        />,
      ]}
      title={t('finances:declaration')}
      onBack={handleGoBack}
    >
      <Stack gap={3}>
        <Stack>
          <Stack direction="row">
            <HIDTypography>
              {t('finances:read_more_about_declaration_calculation_features')}
            </HIDTypography>
            {' '}
            <HIDLink
              label={t('common:here')}
              sx={{ flexShrink: 0, ml: 0.5 }}
              onClick={() => openTutorialDialog({ type: TutorialDialogTypes.DECLARATION })}
            />
            .
          </Stack>
        </Stack>
        <HIDSection
          sx={{ paddingX: 2, paddingY: isDownSm ? 2 : 3 }}
          title={t('declarations:declarations_select_sales_calculation_template')}
        >
          <HIDFormSelect
            items={declarationCalculationTypes}
            label={t('declarations:declarations_declaration_sales_number_calculation_type')}
            placeholder={t('declarations:declarations_declaration_sales_number_calculation_type')}
            showHelperText={false}
            value={selectedDeclarationCalculationType}
            onChange={handleChangeDeclarationCalculationType}
          />
        </HIDSection>
        <HIDSection sx={{ gap: 3, padding: 0, paddingY: isDownSm ? 2 : 3 }}>
          <DeclarationCalculationsSalesNumber sx={{ paddingX: 2 }} />
          <Divider flexItem sx={{ width: '100%' }} variant="fullWidth" />
          <DeclarationCalculationsYourDeductions sx={{ paddingX: 2 }} />
          <Divider flexItem sx={{ width: '100%' }} variant="fullWidth" />
          <DeclarationCalculationsIncomeStatement sx={{ paddingX: 2 }} />
        </HIDSection>
        {selectedDeclarationCalculationType === DeclarationCalculationType.SALE_WITH_NEW_HOME && (
          <HIDSection sx={{ gap: 3, padding: 0, paddingY: isDownSm ? 2 : 3 }}>
            <DeclarationCalculationsWithNewHousePurchase sx={{ paddingX: 2 }} />
          </HIDSection>
        )}
        {
          selectedDeclarationCalculationType === DeclarationCalculationType.SALE_WITH_DIVISION_OF_PROPERTY && (
            <HIDSection sx={{ gap: 3, padding: 0, paddingY: isDownSm ? 2 : 3 }}>
              <DeclarationCalculationsRemainingResidentSituation sx={{ paddingX: 2 }} />
            </HIDSection>
          )
        }
      </Stack>
    </HomeLayout>
  );
};

export default DeclarationCalculations;
