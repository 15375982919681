import React, {
  useEffect,
  useState,
} from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import {
  DeleteOutline,
  EditOutlined,
} from '@mui/icons-material';
import { ReceiptDeductionAmount } from '@house-id/houseid-types/dist/content/receipt';

import { FCC } from '../../../../../../../../../types/common';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { getNoWrapStyle } from '../../../../../../../../../utils/style';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetDeductionsTypesQuery } from '../../../../../../Finances/modules/Declaration/modules/DeclarationHelp/api/api.deductions';
import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import HIDCurrencyField from '../../../../../../../../../components/HIDCurrencyField';
import HIDFormMultiSelect from '../../../../../../../../../components/HIDFormMultiSelect';
import { parseFloatNumber } from '../../../../../../../../../utils/string';

export enum ReceiptDeductionMode {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  LINES = 'LINES',
}

type ReceiptDeductionCardProps = {
  deductionAmount: ReceiptDeductionAmount;
  amount?: number;
  receiptAmount?: number;
  linesModeEnabled: boolean;
  lines: Array<{ id: string, index: number, name: string, amount?: number }>;
  selectedLineIds?: Array<string>;
  onChangeAmount: (amount?: number) => void;
  onChangeSelectedLineIds: (lineIds: Array<string>) => void;
  onEdit: () => void;
  onDelete: () => void;
};

const ReceiptDeductionCard: FCC<ReceiptDeductionCardProps> = ({
  deductionAmount,
  amount,
  receiptAmount,
  linesModeEnabled,
  lines,
  selectedLineIds,
  style,
  onChangeAmount,
  onChangeSelectedLineIds,
  onEdit,
  onDelete,
  sx,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'receipts', 'finances']);

  const ReceiptDeductionModeNameMap: Record<ReceiptDeductionMode, string> = {
    [ReceiptDeductionMode.FULL]: t('receipts:receipts_full_amount'),
    [ReceiptDeductionMode.PARTIAL]: t('receipts:receipts_subtotal'),
    [ReceiptDeductionMode.LINES]: t('receipts:receipts_amounts_from_receipt_lines'),
  };

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: deductionTypes } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const getDeductionNameByType = (deductionTypeId: string) =>
    deductionTypes?.find((deductionType) => deductionType.id === deductionTypeId)?.name;

  const [receiptDeductionMode, setReceiptDeductionMode] = useState<ReceiptDeductionMode>();

  useEffect(() => {
    if (!receiptDeductionMode) {
      if (Boolean(selectedLineIds?.length) && linesModeEnabled) {
        setReceiptDeductionMode(ReceiptDeductionMode.LINES);
      } else if (receiptAmount !== undefined && amount !== undefined && receiptAmount === amount) {
        setReceiptDeductionMode(ReceiptDeductionMode.FULL);
      } else {
        setReceiptDeductionMode(ReceiptDeductionMode.PARTIAL);
      }
    }
  }, [deductionAmount]);

  const handleChangeReceiptDeductionType = (deductionMode: ReceiptDeductionMode) => {
    setReceiptDeductionMode(deductionMode);

    if (deductionMode === ReceiptDeductionMode.FULL) {
      onChangeAmount(receiptAmount);
    }
  };

  useEffect(() => {
    if (receiptDeductionMode === ReceiptDeductionMode.FULL) {
      onChangeAmount(receiptAmount);
    } else if (receiptDeductionMode === ReceiptDeductionMode.LINES) {
      const linesAmount = R.sum(
        lines
          .filter((line) => selectedLineIds?.includes(line.id))
          .map((line) => line.amount || 0),
      );

      onChangeAmount(linesAmount);
    }
  }, [receiptDeductionMode, receiptAmount, selectedLineIds]);

  const linesSelectedList = lines
    .map((line) => ({
      id: line.id,
      name: line.name || `${t('receipts:receipts_receipt_line')} ${line.index + 1}`,
      count: line.amount,
    }));

  return (
    <Stack
      style={style}
      sx={{
        padding: 2.5,
        borderRadius: BorderRadius.sm,
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        gap: 1,
        ...sx,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <HIDTypography
          key={deductionAmount.deductionType}
          sx={getNoWrapStyle(1)}
          variant="subtitle1"
        >
          {getDeductionNameByType(deductionAmount.deductionType)}
        </HIDTypography>
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 2,
          }}
        >
          <HIDIconButton
            Icon={EditOutlined}
            onClick={onEdit}
          />
          <HIDIconButton
            Icon={DeleteOutline}
            onClick={onDelete}
          />
        </Stack>
      </Stack>
      {receiptDeductionMode && (
        <Stack>
          <RadioGroup
            sx={{ alignItems: 'flex-start' }}
            value={receiptDeductionMode}
            onChange={(_event, value) => handleChangeReceiptDeductionType(value as ReceiptDeductionMode)}
          >
            {Object.keys(ReceiptDeductionMode)
              .filter((deductionMode) => deductionMode !== ReceiptDeductionMode.LINES || linesModeEnabled)
              .map((deductionMode) => (
                <FormControlLabel
                  control={<Radio />}
                  key={deductionMode}
                  label={ReceiptDeductionModeNameMap[deductionMode as ReceiptDeductionMode]}
                  sx={{ marginRight: 1 }}
                  value={deductionMode}
                />
              ))}
          </RadioGroup>
        </Stack>
      )}
      {receiptDeductionMode === ReceiptDeductionMode.LINES && (
        <HIDFormMultiSelect
          items={linesSelectedList || []}
          label={t('receipts:receipts_select_lines')}
          value={selectedLineIds?.map((id) => id) || []}
          onChange={({ target: { value } }) => onChangeSelectedLineIds(value as Array<string>)}
        />
      )}
      <HIDCurrencyField
        disabled={receiptDeductionMode === ReceiptDeductionMode.FULL}
        label={t('finances:amount')}
        value={amount?.toString() || ''}
        onChange={({ target: { value } }) => onChangeAmount(parseFloatNumber(value))}
      />
    </Stack>
  );
};

export default ReceiptDeductionCard;
