import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

import { BorderRadius } from '../../../../../../../constants/borderRadius';
import HIDTypography from '../../../../../../../components/HIDTypography';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import HIDImage from '../../../../../../../components/image/HIDImage';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import SalesDocuments from '../../../../../../../assets/images/sales_documents.png';

type DeclarationHelpCardProps = {
  title: string;
  removable?: boolean;
  onPress: () => void;
  onHide: () => void;
};

const DeclarationHelpCard: FC<DeclarationHelpCardProps> = ({
  title,
  removable,
  onPress,
  onHide,
}) => {
  const { t } = useTranslation(['declarations']);
  const theme = useTheme();

  return (
    <Card
      sx={{
        width: '100%',
        padding: 3,
        paddingBottom: 0,
        borderRadius: BorderRadius.md,
        backgroundColor: theme.palette.skattio.lighter,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
        }}
      >
        <Stack sx={{ gap: 2.5, paddingBottom: 3 }}>
          <HIDTypography variant="h3">
            {title}
          </HIDTypography>
          <HIDButton
            color="yellow"
            sx={{ alignSelf: 'flex-start' }}
            title={t('declarations:declarations_the_declaration_aid')}
            onClick={onPress}
          />
        </Stack>
        <Stack
          sx={{
            flex: 1,
            alignItems: 'center',
          }}
        >
          <HIDImage
            externalUrl={SalesDocuments}
            style={{
              width: 85,
              height: 114,
              objectFit: 'cover',
              objectPosition: '100% 0',
            }}
          />
        </Stack>
        {removable && (
          <Stack
            sx={{
              alignItems: 'center',
              alignSelf: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <HIDIconButton
              Icon={Close}
              color="blank"
              onClick={onHide}
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default DeclarationHelpCard;
