import React, {
  FC,
  useState,
} from 'react';
import {
  IconButtonProps,
  Popover,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import ReactMarkdown, { Options } from 'react-markdown';
import { match } from 'ts-pattern';
import { SystemStyleObject } from '@mui/system';

import HIDIconButton from './buttons/HIDIconButton';
import { FCC } from '../types/common';
import HIDTypography from './HIDTypography';

type ColorVariant = 'orange';

export type HIDInfoProps = {
  color?: ColorVariant;
  label?: string;
  LabelComponent?: React.ReactElement;
  IconComponent?: FC<{ disabled?: boolean, onClick: (event: React.MouseEvent<HTMLButtonElement>) => void }>;
  labelVariant?: TypographyProps['variant'];
  component?: React.ElementType;
  labelSx?: SxProps<Theme>;
  iconButtonSx?: SxProps<Theme>;
  bodyTextSx?: SxProps<Theme>;
  description?: string;
  isMarkdown?: boolean;
  isLoading?: boolean;
  skeletonWidth?: string | number;
  iconSize?: IconButtonProps['size'];
  markdownTags?: Options['components'];
};

const HIDInfo: FCC<HIDInfoProps> = ({
  label,
  color,
  labelVariant = 'body1',
  component,
  labelSx,
  description,
  iconSize,
  LabelComponent,
  IconComponent,
  skeletonWidth,
  isMarkdown = false,
  markdownTags,
  isLoading = false,
  iconButtonSx,
  bodyTextSx,
  sx,
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleShowInfo = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const containerSx = match<ColorVariant | undefined, SystemStyleObject<Theme>>(color)
    .with('orange', () => ({
      padding: description ? theme.spacing(0, 0, 0, 1) : theme.spacing(0.5, 1.3),
      color: theme.palette.common.black,
      backgroundColor: theme.palette.warning.main,
      borderRadius: theme.spacing(5),
    }))
    .otherwise(() => ({}));

  const buttonSx = match<ColorVariant | undefined, SystemStyleObject<Theme>>(color)
    .with('orange', () => ({
      padding: theme.spacing(0.5),
      '&:hover': {
        backgroundColor: theme.palette.warning.light,
      },
    }))
    .otherwise(() => ({}));

  return (
    <Stack
      alignItems="center"
      direction="row"
      sx={{ ...containerSx, ...sx }}
    >
      {LabelComponent
        || (
          <HIDTypography
            component={component || 'p'}
            isLoading={isLoading}
            skeletonWidth={skeletonWidth}
            sx={labelSx}
            variant={labelVariant}
          >
            {label}
          </HIDTypography>
        )}
      {Boolean(description) && (
        IconComponent
          ? <IconComponent disabled={isLoading} onClick={handleShowInfo} />
          : (
            <HIDIconButton
              Icon={InfoOutlined}
              color="blank"
              disabled={isLoading}
              size={iconSize}
              sx={{ marginLeft: theme.spacing(iconSize === 'small' ? 0 : 0.25), ...buttonSx, ...iconButtonSx }}
              onClick={handleShowInfo}
            />
          )
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Typography
          sx={{
            p: 2,
            maxWidth: {
              lg: 600,
              sm: 500,
              xs: '100%',
              xxs: '100%',
            },
            ...bodyTextSx,
          }}
        >
          {
            isMarkdown && description
              ? (
                <ReactMarkdown components={{ p: 'span', ...markdownTags }}>
                  {description}
                </ReactMarkdown>
              )
              : description
          }
        </Typography>
      </Popover>
    </Stack>
  );
};

export default HIDInfo;
