import React, { lazy } from 'react';
import { RouteObject } from 'react-router';

import RouteNames from '../../../routes/RouteNames';
import ProtectedRoute from '../../../routes/ProtectedRoute';

const BuyConsumable = lazy(() => import('../pages/BuyConsumable'));
const ConsumableOrder = lazy(() => import('../pages/ConsumableOrder'));
const ConsumableOrderError = lazy(() => import('../pages/ConsumableOrderError'));

const ConsumablesRouteConfig: Array<RouteObject> = [
  {
    path: RouteNames.CONSUMABLE_BUY,
    element: (
      <ProtectedRoute
        path={RouteNames.CONSUMABLE_BUY}
        propertyRequired={false}
      >
        <BuyConsumable />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.CONSUMABLE_ORDER,
    element: (
      <ProtectedRoute
        path={RouteNames.CONSUMABLE_ORDER}
        propertyRequired={false}
      >
        <ConsumableOrder />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.CONSUMABLE_ORDER_ERROR,
    element: (
      <ProtectedRoute
        path={RouteNames.CONSUMABLE_ORDER}
        propertyRequired={false}
      >
        <ConsumableOrderError />
      </ProtectedRoute>
    ),
  },
];

export default ConsumablesRouteConfig;
