import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  AmountSpecificationType,
  DeductionCategory,
  DeductionChatContext,
  DeductionChatContextType,
  DeductionReceiptAction,
  DeductionState,
  DeductionTypeId,
  DeductionTypeReceipt,
  WellKnownDeductionTypes,
} from '@house-id/houseid-types/dist/deductions';
import {
  Add,
  ArrowDropDown,
  Check,
  Remove,
} from '@mui/icons-material';
import { useDebounce } from 'usehooks-ts';
import * as R from 'ramda';
import {
  useTheme,
  Card,
  Stack,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import {
  useBlocker,
} from 'react-router';
import { useSearchParams } from 'react-router-dom';

import {
  useNavigateBackOr,
  useNavigationParamsAndState,
} from '../../../../../../../../../../utils/routes';
import HIDTypography from '../../../../../../../../../../components/HIDTypography';
import useGetCurrentPropertyId from '../../../../../../../../hooks/useGetCurrentPropertyId';
import DynamicContentInputForm, { getDynamicFormInitialValues }
  from '../../../../../../../Content/modules/DynamicContent/components/dynamicInputForm/DynamicContentInputForm';
import { DynamicInputField } from '../../../../../../../Content/modules/DynamicContent/types/types.dynamicContent';
import {
  generateEntityContentConnectionsPatchActionData,
  mapConnectionsFullModelToIds,
} from '../../../../../../../Content/utils/contentConnections';
import {
  useGetDeductionQuery,
  useCreateDeductionMutation,
  useUpdateDeductionMutation,
  useGetDeductionsTypesQuery,
  useDeleteDeductionMutation,
  useGetDeductionsConnectedReceiptsQuery,
} from '../../api/api.deductions';
import DeclarationHelpSummarySection from '../../components/DeclarationHelpSummarySection';
import DeductionCalculationResultCard from '../../components/DeductionCalculationResultCard';
import DeductionsSupportBox from '../../components/DeductionsSupportBox';
import DeductionReceiptsAndAmounts from './components/DeductionReceiptsAndAmounts';
import RenovationDeductionReceiptsAndAmounts from './components/RenovationDeductionReceiptsAndAmounts';
import {
  CreateDeductionMode,
  CreateEditDeductionValues,
} from './types';
import { Currency } from '../../../../../../../../../../utils/string';
import HomeLayout from '../../../../../../../../pages/Home/components/HomeLayout';
import HIDTextField from '../../../../../../../../../../components/HIDTextField';
import HIDIconButton from '../../../../../../../../../../components/buttons/HIDIconButton';
import HIDButton from '../../../../../../../../../../components/buttons/HIDButton';
import { getObjDiff } from '../../../../../../../../../../utils/object';
import HIDFormDatePicker from '../../../../../../../../../../components/datePicker/HIDFormDatePicker';
import { DateTimeFormats } from '../../../../../../../../../../utils/date';
import ViewEntityActions from '../../../../../../../Content/components/actions/ViewEntityActions';
import DeductionCardContainer from './components/DeductionCardContainer';
import {
  getDeclarationHelpOverviewPath,
} from '../../navigation/navigation.declarationHelp';
import EntityConnectionsGroup from '../../../../../../../Content/components/EntityConnectionsGroup';
import useDialog from '../../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../../hooks/useDialog/DialogNames';
import HIDLink from '../../../../../../../../../../components/HIDLink';
import InfoBox from '../../../../../../../../../../components/InfoBox';
import { capitalize } from '../../../../../../../../../../utils/url';
import useBreakPointsSizes from '../../../../../../../../../../hooks/useBreakpointsSizes';
import DeductionArticlesSection from '../../components/DeductionArticlesSection';
import DeductionArticlesCarousel from '../../components/DeductionArticlesCarousel';

const generateReceiptsActionData = (newReceipts: Array<string>, oldReceipts: Array<string>, allReceipts: DeductionTypeReceipt[]) => {
  const noActionItems = R.intersection(newReceipts, oldReceipts);
  const allReceiptsMap = allReceipts.reduce((acc, connection) => {
    acc[connection.receipt.id] = connection;
    return acc;
  }, {} as Record<string, DeductionTypeReceipt>);

  const addActions: Array<DeductionReceiptAction> = R.pipe(
    R.without(noActionItems),
    R.uniq,
    R.map((id: string) => ({
      action: 'create',
      amount: allReceiptsMap[id].amount.amount || 0,
      id,
    } as DeductionReceiptAction)),
  )(newReceipts);

  const removeActions: Array<DeductionReceiptAction> = R.pipe(
    R.without(noActionItems),
    R.uniq,
    R.map((id: string) => ({
      action: 'delete',
      id,
    } as DeductionReceiptAction)),
  )(oldReceipts);

  return [...addActions, ...removeActions];
};

const renovationFastTrackTypes: Array<string> = [
  WellKnownDeductionTypes.GENERIC_IMPROVEMENT, WellKnownDeductionTypes.GENERIC_REPAIR_AND_MAINTENANCE,
];

type CreateEditDeductionNavigationOperation = 'create' | 'update';
type CreateEditDeductionQueryParams = {
  id?: string;
  category?: DeductionCategory;
  type?: string;
  prefillRecommendedReceipts?: boolean;
};

const CreateEditDeduction: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation(['declarations', 'deductions', 'forms_common', 'common']);
  const {
    routeParams,
    queryParams,
  } = useNavigationParamsAndState<{ id?: string }, CreateEditDeductionQueryParams, any>();

  const [_, setSearchParams] = useSearchParams();

  const { isDownSm } = useBreakPointsSizes();

  const operation: CreateEditDeductionNavigationOperation = routeParams.id ? 'update' : 'create';
  const isCreate = operation === 'create';
  const routeCategory = operation === 'create' ? queryParams?.category : undefined;
  const prefillRecommendedReceipts = operation === 'create' ? queryParams?.prefillRecommendedReceipts : undefined;

  const [createdDeductionId, setCreatedDeductionId] = React.useState<string | undefined>(undefined);
  const id = operation === 'update' ? routeParams.id : createdDeductionId || queryParams?.id;

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: deduction,
    isLoading: isDeductionLoading,
    isFetching: isDeductionFetching,
  } = useGetDeductionQuery(id && propertyId ? { propertyId, id } : skipToken);

  const [createDeduction, { isLoading: isCreatingDeduction }] = useCreateDeductionMutation();
  const [updateDeduction, { isLoading: isUpdatingDeduction }] = useUpdateDeductionMutation();
  const [completeDeduction, { isLoading: isCompletingDeduction }] = useUpdateDeductionMutation();

  const {
    data: deductionTypes,
    isLoading: isDeductionTypesLoading,
  } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const {
    data: connectedReceipts = [],
    isLoading: isReceiptsLoading,
  } = useGetDeductionsConnectedReceiptsQuery(propertyId ? { propertyId } : skipToken);

  const type = operation === 'create' ? queryParams?.type : deduction?.type;
  const category = routeCategory || deduction?.categoryId;

  const createDeductionMode = category === DeductionCategory.RENOVATION
    ? type && renovationFastTrackTypes.includes(type)
      ? CreateDeductionMode.RENOVATION_FAST_TRACK
      : CreateDeductionMode.RENOVATION
    : CreateDeductionMode.SALES_PURCHASE_EXPENSES;

  const schema = Yup.object().shape({
    type: Yup.string().required(t('forms_common:forms_common_required_field_generic')),
  });

  const mapInitValues = () => ({
    id: deduction?.id,
    category: category || DeductionCategory.RENOVATION,
    amount: deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR ? '' : deduction?.amount?.amount.toString() || '',
    amountSpecificationType: deduction?.amountSpecificationType || AmountSpecificationType.TOTAL,
    type: deduction?.type || type || '',
    name: deduction?.name || '',
    receiptConnections: {
      [EntityType.RECEIPT]: deduction?.receipts
        // ?.filter((connection) => connection.selected)
        .map((connection) => connection.receipt.id) || [],
    },
    amounts: category === DeductionCategory.RENOVATION
      ? deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR
        ? !R.isEmpty(deduction?.amounts)
          ? Object.entries(deduction?.amounts).map(([key, value]) => ({
            year: key,
            number: value.amount ? value.amount.toString() : '',
          }))
          : [{ year: '', number: '' }]
        : [{ year: '', number: '' }]
      : [],
    otherConnections: deduction?.connections
      ? mapConnectionsFullModelToIds(deduction?.connections || {})
      : {},
    answers: deduction?.calculation?.questions
      ? getDynamicFormInitialValues(deduction?.calculation?.questions as unknown as DynamicInputField[])
      : {},
  });

  const formik = useFormik<CreateEditDeductionValues>({
    initialValues: mapInitValues(),
    validationSchema: schema,
    onSubmit: () => { },
  });

  const currentType = deductionTypes?.find((deductionType) => deductionType.id === type || deductionType.id === formik.values.type);

  const isRenovationEditMode = createDeductionMode === CreateDeductionMode.RENOVATION;

  const hasType = Boolean(formik.values.type);
  const showTypePicker = isRenovationEditMode;

  const showAmounts = isRenovationEditMode && category === DeductionCategory.RENOVATION
    ? hasType && formik.values.amounts?.some(({ year }) => year)
    : hasType;

  const showEntityConnections = showAmounts;

  const showQuestions = hasType && isRenovationEditMode && Boolean(deduction?.calculation?.questions?.length)
    && showAmounts
    && formik.values.amounts?.some(({ number }) => number);

  const isResultAvailable = isRenovationEditMode && showQuestions && Boolean(deduction?.calculation?.showResult);
  const [showResult, setShowResult] = React.useState(isCreate ? false : isResultAvailable);

  const showCompleteButton = showAmounts;

  const isShowLoading = isCreate ? isDeductionTypesLoading : isDeductionLoading || isDeductionTypesLoading || isReceiptsLoading;
  const isUpdating = isUpdatingDeduction || isCreatingDeduction;
  const typeName = currentType?.name?.toLowerCase() || t('deductions:measure').toLowerCase();

  const deductionTypeReceipts = useMemo(
    () => R.uniqBy(
      ({ receipt }) => receipt.id,
      connectedReceipts.filter(({ deductionTypeId, deductionIds }) =>
        (deductionIds.length === 0 || (id && deductionIds.includes(id))) && deductionTypeId === currentType?.id),
    ),
    [connectedReceipts, id, currentType],
  );

  const supportContext: DeductionChatContext = useMemo(
    () => id
      ? {
        type: DeductionChatContextType.DEDUCTION_ENTITY,
        id,
      } : currentType
        ? {
          type: DeductionChatContextType.DEDUCTION_TYPE,
          deductionType: currentType.id,
        } : {
          type: DeductionChatContextType.DEDUCTION_CATEGORY,
          deductionCategory: category || DeductionCategory.RENOVATION,
        },
    [currentType, category, id],
  );

  const title = useMemo(
    () => isCreate
      ? category === DeductionCategory.RENOVATION
        ? t('deductions:create_renovation_deductions_title', { typeName })
        : t('deductions:create_deductions_title', { typeName })
      : t('deductions:edit_deductions_title', { typeName }),
    [typeName],
  );

  const navigateBackOr = useNavigateBackOr();
  const handleGoBack = () => navigateBackOr(propertyId ? getDeclarationHelpOverviewPath({ propertyId }) : undefined);

  const handleChangeRouteToUpdateDeduction = (deductionId: string) => {
    if (propertyId) {
      const params: Record<string, string> = {
        id: deductionId,
        ...(queryParams.category && { category: queryParams.category }),
        ...(queryParams.type && { type: queryParams.type }),
      };
      const newQueryParams = new URLSearchParams(params);

      setSearchParams(newQueryParams, { replace: true });
    }
  };

  const setValues = (fieldName: string, value: any) => formik.setFieldValue(fieldName, value);
  const debouncedValues = useDebounce(formik.values, 500);
  const handleValueChanged = (fieldName: string, value: any) => setValues(fieldName, value);

  const handleCreateValues = (values: CreateEditDeductionValues) => {
    if (propertyId) {
      if (isCreate && !id) {
        const receiptsActionData = connectedReceipts
          .filter(({ receipt }) => values.receiptConnections[EntityType.RECEIPT]?.includes(receipt.id))
          .map(({ receipt, amount }) => ({
            id: receipt.id,
            action: 'create',
            amount: amount.amount,
          } as DeductionReceiptAction));

        const otherConnectionsActionData = generateEntityContentConnectionsPatchActionData({}, values.otherConnections);

        const amountsPerYear = values.amountSpecificationType === AmountSpecificationType.PER_YEAR
          ? values.amounts
            .filter(({ year }) => year)
            .reduce((acc, { year, number }) => {
              acc[Number(year)] = {
                amount: Number(number),
                currency: Currency.code,
              };
              return acc;
            }, {} as Record<string, { amount: number; currency: string }>)
          : undefined;

        const body = {
          propertyId,
          name: values.name,
          type: values.type,
          amount: values.amountSpecificationType === AmountSpecificationType.TOTAL
            ? {
              amount: Number(values.amount),
              currency: Currency.code,
            } : undefined,
          amounts: R.isNotEmpty(amountsPerYear) ? amountsPerYear : undefined,
          receipts: receiptsActionData,
          connections: otherConnectionsActionData,
          state: DeductionState.DRAFT,
        };

        return createDeduction(body)
          .unwrap()
          .then((deduction) => {
            setCreatedDeductionId(deduction.id);
            handleChangeRouteToUpdateDeduction(deduction.id);
          });
      }
    }
  };

  const handleUpdateValues = (values: CreateEditDeductionValues) => {
    if (propertyId) {
      if (deduction) {
        const receiptsOld = deduction?.receipts
          // ?.filter(({ selected }) => selected)
          .map((connection) => connection.receipt.id) || [];
        const receiptsNew = values.receiptConnections[EntityType.RECEIPT] || [];

        const receiptsActionData = generateReceiptsActionData(receiptsNew, receiptsOld, connectedReceipts || []);

        const otherConnectionsActionData = generateEntityContentConnectionsPatchActionData(
          mapConnectionsFullModelToIds(deduction?.connections || {}),
          values.otherConnections,
        );

        const body = {
          id: deduction.id,
          propertyId,
          name: values.name,
          type: values.type,
          amount: values.amountSpecificationType === AmountSpecificationType.TOTAL
            ? {
              amount: Number(values.amount),
              currency: Currency.code,
            } : undefined,
          amounts: values.amountSpecificationType === AmountSpecificationType.PER_YEAR
            ? values.amounts.reduce((acc, { year, number }) => {
              acc[Number(year)] = {
                amount: Number(number),
                currency: Currency.code,
              };
              return acc;
            }, {} as Record<string, { amount: number; currency: string }>)
            : undefined,
          receipts: receiptsActionData,
          connections: otherConnectionsActionData,
          answers: values.answers,
          state: deduction.state,
        };

        return updateDeduction(body)
          .unwrap()
          .then(() => {
            if (!isCreate) {
              enqueueSnackbar(t('deductions:deduction_saved'), { variant: 'success' });
            }
          });
      }
    }
  };

  const handleReceiptsChange = (receiptIds: string[], shouldResetAmounts: boolean = false) => {
    formik.setFieldValue('receiptConnections', { [EntityType.RECEIPT]: receiptIds });
    const allReceiptsConnections = (deductionTypeReceipts || [])
      .filter(({ receipt }) => receiptIds.includes(receipt.id));

    if (category) {
      if (currentType?.amountSpecificationType === AmountSpecificationType.PER_YEAR) {
        const existingYears = formik.values.amounts.map(({ year }) => year);

        const amountsWithNewYears = Object.entries(
          allReceiptsConnections
            .reduce((acc, { receipt, amount }) => {
              const year = new Date(receipt.date).getFullYear().toString();
              acc[year] = (acc[year] || 0) + amount.amount;
              return acc;
            }, {} as Record<string, number>),
        )
          .map(([year, total]) => ({
            year,
            number: total.toString(),
          }))
          .filter(({ year }) => !existingYears.includes(year));

        formik.setFieldValue('amounts', shouldResetAmounts ? amountsWithNewYears : [...formik.values.amounts, ...amountsWithNewYears]);
      } else if (!formik.values?.amount) {
        const total = R.sum(allReceiptsConnections.map(({ amount }) => amount.amount));
        formik.setFieldValue('amount', total);
      }
    }
  };

  const handleAddYear = () => {
    handleValueChanged('amounts', [...formik.values.amounts, { year: '', number: '' }]);
  };

  const handleRemoveYear = (index: number) => {
    const newAmounts = formik.values.amounts.filter((_, i) => i !== index);
    formik.setValues({ ...formik.values, amounts: newAmounts });
  };

  const [deleteDeduction] = useDeleteDeductionMutation();

  const handleDelete = () => {
    if (propertyId && deduction) {
      deleteDeduction({ id: deduction?.id, propertyId })
        .unwrap()
        .then(handleGoBack);
    }
  };

  useEffect(() => {
    if (id) {
      const deductionValues = mapInitValues();
      const newValues = debouncedValues;

      if (!R.equals(newValues, deductionValues)) {
        const difference = getObjDiff(deductionValues, newValues);
        if (!R.isEmpty(difference)) {
          handleUpdateValues(formik.values);
        }
      }
    } else if (formik.values.type && formik.values.name) {
      handleCreateValues(formik.values);
    }
  }, [debouncedValues]);

  useEffect(() => {
    if ((!isCreate && !formik.values.id)
      || (deduction?.receipts?.length !== formik.values.receiptConnections[EntityType.RECEIPT]?.length)) {
      // Initial values for the form when is update flow
      formik.setValues(mapInitValues());
    }
  }, [deduction]);

  useEffect(() => {
    if (currentType) {
      if (!formik.values.name) {
        formik.setFieldValue('name', currentType?.name || '');
      }
      formik.setFieldValue('amountSpecificationType', currentType?.amountSpecificationType);
    }
  }, [currentType]);

  const DynamicContentForm = useMemo(() => (
    <DynamicContentInputForm
      enableReinitializeValues
      showElementDivider
      fields={(deduction?.calculation?.questions || []) as unknown as Array<DynamicInputField>}
      formSettings={{
        color: 'skattio',
        backgroundColor: theme.palette.skattio.lightest,
        primaryButtonColor: 'skattio-primary',
        secondaryButtonColor: 'skattio-secondary',
      }}
      onValuesChanged={(values) => handleValueChanged('answers', values)}
    />
  ), [deduction?.calculation?.questions]);

  useEffect(() => {
    setShowResult(isCreate ? false : isResultAvailable);
  }, [isResultAvailable, isCreate]);

  const [openDeductionTypePickerDialog] = useDialog(DialogNames.DEDUCTION_TYPE_PICKER_DIALOG);
  const handleShowDeductionPickerDialogForAdding = () => {
    openDeductionTypePickerDialog({
      color: 'skattio',
      deductionTypeId: formik.values.type,
      filters: { categoryId: DeductionCategory.RENOVATION },
      onSelect: (newSelectedDeductionTypeId?: DeductionTypeId) => {
        if (newSelectedDeductionTypeId) {
          handleValueChanged('type', newSelectedDeductionTypeId);
        }
      },
    });
  };

  const getDeductionNameByType = (deductionTypeId: string) =>
    deductionTypes?.find((deductionType) => deductionType.id === deductionTypeId)?.name;

  const [openReadMoreDialog] = useDialog(DialogNames.INFO_DIALOG);
  const handleOpenReadMoreDialog = () => {
    if (currentType && typeName) {
      openReadMoreDialog({
        color: 'skattio',
        title: capitalize(typeName),
        description: currentType.description,
      });
    }
  };

  const handleShowResults = () => {
    setShowResult(true);

    setTimeout(() => window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    }), 100);
  };

  const blocker = useBlocker(Boolean(deduction && deduction?.state !== DeductionState.ACTUAL));
  const releaseBlocker = () => {
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  };

  const [openSaveDraftDeductionDialog] = useDialog(DialogNames.YES_NO_CANCEL_DIALOG, releaseBlocker);

  const deductionMarkedAsCompleted = useRef(false);
  const skipBlockingOnAddConnection = useRef(false);
  const handleDeductionAsCompleted = (skipGoBack: boolean = false) => {
    if (deduction && propertyId && isCreate) {
      const body = {
        id: deduction.id,
        propertyId,
        state: DeductionState.ACTUAL,
      };
      return completeDeduction(body)
        .unwrap()
        .then(() => {
          deductionMarkedAsCompleted.current = true;
          if (!skipGoBack) {
            handleGoBack();
          }
        });
    }
    if (!skipGoBack) {
      handleGoBack();
    }
  };

  const handleBeforeAddConnection = () => {
    skipBlockingOnAddConnection.current = true;
  };

  useEffect(() => {
    if (isCreate
      && currentType
      && prefillRecommendedReceipts
      && deductionTypeReceipts.length
      && formik.values.receiptConnections[EntityType.RECEIPT].length === 0) {
      handleReceiptsChange(deductionTypeReceipts.map(({ receipt }) => receipt.id), true);
    }
  }, [deductionTypeReceipts, currentType]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (skipBlockingOnAddConnection.current) {
        skipBlockingOnAddConnection.current = false;
        blocker.proceed();
      } else if (!deductionMarkedAsCompleted.current) {
        openSaveDraftDeductionDialog({
          yesButtonColor: 'skattio-primary',
          noButtonColor: 'skattio-secondary',
          title: t('deductions:save_draft_deduction_title'),
          showBorder: false,
          onYes: () => {
            handleDeductionAsCompleted(true);
          },
          onNo: () => { },
        });
      } else {
        releaseBlocker();
      }
    }
  }, [blocker.state]);

  return (
    <HomeLayout
      SideColumn={
        <>
          <Card sx={{ padding: 2 }}>
            <ViewEntityActions
              onDelete={handleDelete}
            />
          </Card>
          <DeductionsSupportBox
            context={supportContext}
            linkText={t('forms_common:forms_common_read_more_and_chat')}
            title={t('deductions:questions_per_type', { typeName })}
          />
          <DeductionArticlesSection
            context={supportContext}
          />
        </>
      }
      manageButtonColor="skattio-secondary"
      sideDrawerElements={
        [
          <ViewEntityActions
            key={ViewEntityActions.name}
            onDelete={handleDelete}
          />,
          <DeductionsSupportBox
            context={supportContext}
            key={DeductionsSupportBox.name}
            linkText={t('forms_common:forms_common_read_more_and_chat')}
            title={t('deductions:questions_per_type', { typeName })}
          />,
          <DeductionArticlesSection
            context={supportContext}
            key={DeductionArticlesSection.name}
          />,
        ]
      }
      title={title}
      onBack={handleGoBack}
    >
      {!isShowLoading ? (
        <Stack gap={2.5}>
          {createDeductionMode === CreateDeductionMode.RENOVATION
            ? (
              <DeductionCardContainer sx={{ padding: theme.spacing(2.5) }}>
                <HIDTypography variant="subtitle1">
                  {t('deductions:deduction_select_deduction')}
                </HIDTypography>
                {showTypePicker && (
                  <HIDTextField
                    InputProps={{
                      readOnly: true, // Makes the field read-only
                    }}
                    backgroundColor={theme.palette.skattio.lightest}
                    color="skattio"
                    endAdornment={<ArrowDropDown />}
                    label={t('deductions:deduction_select_deduction')}
                    placeholder={t('deductions:deduction_select_deduction')}
                    showHelperText={false}
                    value={getDeductionNameByType(formik.values.type)}
                    onClick={handleShowDeductionPickerDialogForAdding}
                  />
                )}
                <HIDTextField
                  required
                  backgroundColor={theme.palette.skattio.lightest}
                  color="skattio"
                  label={t('deductions:deduction_your_custom_name')}
                  value={formik.values.name}
                  onChange={({ target: { value } }) =>
                    handleValueChanged('name', value)}
                />
                {hasType && (
                  <Stack gap={1.5}>
                    {formik.values.amounts?.length
                      ? formik.values.amounts.map(({ year }, index) => (
                        <Stack
                          alignItems="center"
                          flexDirection="row"
                          gap={1}
                          justifyContent="space-between"
                          key={index}
                        >
                          <HIDFormDatePicker
                            required
                            color="skattio"
                            dateTimeFormat={DateTimeFormats.YEAR_ONLY}
                            label={t('deductions:deduction_year_of_activation')}
                            maxDate={new Date()}
                            value={year ? new Date(String(year)) : undefined}
                            views={['year']}
                            onChange={(value) =>
                              handleValueChanged(`amounts[${index}]`, { ...formik.values.amounts[index], year: value?.getFullYear() })}
                          />
                          {index > 0 && (
                            <HIDIconButton
                              Icon={Remove}
                              sx={{ marginBottom: theme.spacing(2.5) }}
                              onClick={() => handleRemoveYear(index)}
                            />
                          )}
                        </Stack>
                      ))
                      : null}
                    <HIDButton
                      Icon={Add}
                      color="skattio-primary"
                      sx={{ alignSelf: 'flex-end' }}
                      title={t('deductions:deduction_add_one_more_year')}
                      onClick={handleAddYear}
                    />
                  </Stack>
                )}
              </DeductionCardContainer>
            ) : undefined}
          {createDeductionMode === CreateDeductionMode.RENOVATION_FAST_TRACK
            ? (
              <HIDTextField
                required
                backgroundColor={theme.palette.skattio.lightest}
                color="skattio"
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                label={t('deductions:deduction_your_custom_name')}
                value={formik.values.name}
                onBlur={formik.handleBlur('name')}
                onChange={(value) => handleValueChanged('name', value.target.value)}
              />
            ) : undefined}
          {currentType?.description
            ? (
              <InfoBox
                RightComponent={
                  <HIDLink
                    color="skattio"
                    label={t('common:show_more')}
                    linkColor={theme.palette.common.black}
                    variant="body1"
                    onClick={handleOpenReadMoreDialog}
                  />
                }
                iconColor="skattio"
                message={t('declarations_read_more_about_type', { deductionItem: typeName })}
                sx={{
                  backgroundColor: theme.palette.skattio.lightest,
                }}
              />
            )
            : undefined}
          {isDownSm && (
            <DeductionsSupportBox
              context={supportContext}
              linkText={t('forms_common:forms_common_read_more_and_chat')}
              title={t('deductions:questions_per_type', { typeName })}
            />
          )}
          {showAmounts
            ? createDeductionMode === CreateDeductionMode.RENOVATION
              ? (
                <RenovationDeductionReceiptsAndAmounts
                  deduction={deduction}
                  deductionTypeReceipts={deductionTypeReceipts}
                  formik={formik}
                  type={type}
                  onBeforeAdd={handleBeforeAddConnection}
                  onConnectedReceiptsChange={handleReceiptsChange}
                  onValueChanged={handleValueChanged}
                />
              )
              : (
                <DeductionReceiptsAndAmounts
                  deduction={deduction}
                  deductionTypeReceipts={deductionTypeReceipts}
                  formik={formik}
                  type={type}
                  onBeforeAdd={handleBeforeAddConnection}
                  onConnectedReceiptsChange={handleReceiptsChange}
                  onValueChanged={handleValueChanged}
                />
              )
            : undefined}
          {showEntityConnections && (
            <DeductionCardContainer sx={{ padding: theme.spacing(2.5) }}>
              <Stack gap={1}>
                <HIDTypography variant="subtitle1">
                  {t('deductions:create_edit_deductions_add_other_connections_section_title')}
                </HIDTypography>
                <HIDTypography variant="body1">
                  {currentType?.connectionsDescription
                    || t('deductions:create_edit_deductions_add_other_connections_section_description')}
                </HIDTypography>
              </Stack>
              <EntityConnectionsGroup
                addButtonText={t('deductions:create_edit_deductions_add_other_connections')}
                buttonColor="skattio-primary"
                connectionsMap={deduction?.connections || {}}
                entityId={deduction?.id}
                entityType={EntityType.DEDUCTION}
                sections={[EntityType.DOCUMENT, EntityType.PHOTO, EntityType.TIMELINE_ENTRY, EntityType.NOTE]}
                onBeforeAdd={handleBeforeAddConnection}
                onChange={(data) => handleValueChanged('otherConnections', data)}
              />
            </DeductionCardContainer>

          )}
          {showQuestions && (
            <DeductionCardContainer sx={{ padding: theme.spacing(2.5) }}>
              <HIDTypography variant="subtitle1">
                {t('deductions:create_edit_deductions_questions_title')}
              </HIDTypography>
              {DynamicContentForm}
            </DeductionCardContainer>
          )}
          {deduction !== undefined && isResultAvailable
            ? showResult
              ? (
                <DeclarationHelpSummarySection
                  Icon={() => <Check color="warning" />}
                  title={t('deductions:deductions_result')}
                  titleContainerSx={{ backgroundColor: theme.palette.grey[200] }}
                >
                  <DeductionCalculationResultCard
                    deductionTypeId={deduction.type}
                    isLoading={isDeductionFetching || isUpdating}
                    result={deduction.calculation?.result}
                    style={{ padding: theme.spacing(2.5) }}
                    totalAmount={deduction.summary.totalAmount}
                  />
                </DeclarationHelpSummarySection>
              ) : (
                <DeductionCardContainer sx={{ padding: theme.spacing(2.5) }}>
                  <HIDTypography variant="subtitle1">
                    {t('deductions:deductions_show_result_title')}
                  </HIDTypography>
                  <HIDTypography>
                    {t('deductions:deductions_show_result_description')}
                  </HIDTypography>
                  <HIDButton
                    Icon={Check}
                    color="skattio-primary"
                    sx={{ alignSelf: 'flex-end' }}
                    title={t('deductions:deductions_show_result')}
                    onClick={handleShowResults}
                  />
                </DeductionCardContainer>
              )
            : undefined}
          {showCompleteButton && (
            <HIDButton
              color="skattio-primary"
              loading={isCompletingDeduction}
              sx={{ alignSelf: 'flex-end' }}
              title={deduction?.state !== DeductionState.ACTUAL ? t('common:save') : t('deductions:go_to_overview')}
              onClick={() => handleDeductionAsCompleted()}
            />
          )}
          {isDownSm && (
            <DeductionArticlesCarousel
              context={supportContext}
              sx={{ marginTop: theme.spacing(2.5) }}
            />
          )}
        </Stack>)
        : null}

    </HomeLayout>
  );
};

export default CreateEditDeduction;
