import { ConsumableType } from '@house-id/houseid-types/dist/consumables';

import RouteNames from '../../../routes/RouteNames';
import { generateRoutePath } from '../../../utils/routes';

export const getConsumableBuyPath = (params: { consumableType: ConsumableType }) =>
  generateRoutePath(RouteNames.CONSUMABLE_BUY, params);

export const getConsumableOrderPath = (params: { orderId: string }) =>
  generateRoutePath(RouteNames.CONSUMABLE_ORDER, params);

export const getConsumableOrderErrorPath = (params: { consumableType: ConsumableType, orderId?: string, isFailedByTimeout?: boolean }) =>
  generateRoutePath(RouteNames.CONSUMABLE_ORDER_ERROR, {}, params);
