import { FeatureStatus } from '@house-id/houseid-types/dist/subscriptionPlans';

import { useLazyGetCurrentSubscriptionPlanQuery } from '../../../../../../../../SubscriptionPlans/api/api.subscriptionPlans';

type UseGetPropertyDeclarationHelpClaimed = () => (propertyId?: string) => Promise<boolean>;

const useGetPropertyDeclarationHelpClaimed: UseGetPropertyDeclarationHelpClaimed = () => {
  const [getCurrentSubscriptionPlan] = useLazyGetCurrentSubscriptionPlanQuery();

  return async (propertyId) => {
    const subscriptionPlan = await getCurrentSubscriptionPlan(undefined, true)
      .unwrap();

    const featureStatus = subscriptionPlan?.properties
      .find(({ id }) => id === propertyId)?.declarationHelp?.status;

    return featureStatus === FeatureStatus.ENABLED;
  };
};

export default useGetPropertyDeclarationHelpClaimed;
