import { HIDBlob } from '@house-id/houseid-types/dist/common';
import {
  AmountSpecificationType,
  DeductionCategory,
} from '@house-id/houseid-types/dist/deductions';

export type CreateEditDeductionValues = {
  id?: string;
  amountSpecificationType: AmountSpecificationType;
  category: DeductionCategory;
  amount: string;
  amounts: Array<{ year: string; number: string }>
  type: string;
  name: string;
  receiptConnections: Record<string, Array<string>>;
  otherConnections: Record<string, Array<string>>;
  answers?: Record<string, any>;
};

export enum CreateDeductionMode {
  SALES_PURCHASE_EXPENSES = 'sales_purchase_expenses',
  RENOVATION = 'renovation',
  RENOVATION_FAST_TRACK = 'renovation_fast_track',
}

export type MappedDeductableReceipt = {
  id: string;
  title: string;
  blob: HIDBlob | undefined;
  formattedDate: string | undefined;
  amount: number;
  deductableAmount: number;
};
