import qs from 'query-string';
import {
  useEffect,
  useMemo,
} from 'react';
import * as R from 'ramda';
import { useLocalStorage } from 'usehooks-ts';
import { UtmParameters } from '@house-id/houseid-types/dist/auth';

import { useRouteQueryParams } from '../../../utils/routes';
import { removeUndefinedValues } from '../../../utils/form';

export const INTRODUCTORY_PARAMS = 'INTRODUCTARY_PARAMS';

type UseIntroductoryParams = (params?: { resetOldUtmParams?: boolean }) => {
  data?: UtmParameters,
  clear: () => void;
};

const getUtmQueryParams = (queryParams: Record<string, string>) => {
  const { action } = queryParams;
  if (action) {
    const decodedActionUrl = action ? decodeURIComponent(action) : '';

    const parsedUrl = decodedActionUrl ? qs.parseUrl(decodedActionUrl) : null;
    if (R.not(R.isEmpty(parsedUrl?.query))) {
      return parsedUrl?.query as Record<string, any>;
    }
    return {
      utm_campaign: queryParams.utm_campaign,
      utm_source: queryParams.utm_source,
      utm_medium: queryParams.utm_medium,
      utm_term: queryParams.utm_term,
      utm_content: queryParams.utm_content,
      utm_id: queryParams.utm_id,
    };
  }
  return {
    utm_campaign: queryParams.utm_campaign,
    utm_source: queryParams.utm_source,
    utm_medium: queryParams.utm_medium,
    utm_term: queryParams.utm_term,
    utm_content: queryParams.utm_content,
    utm_id: queryParams.utm_id,
  };
};

export const useIntroductoryParams: UseIntroductoryParams = (params) => {
  const queryParams = useRouteQueryParams<{
    action?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_term?: string;
    utm_content?: string;
    utm_id?: string;
  }>();

  const [data, setData] = useLocalStorage<UtmParameters | undefined>(INTRODUCTORY_PARAMS, {});

  const newIntroductoryParams = useMemo(() => removeUndefinedValues(getUtmQueryParams(queryParams)), [queryParams]);

  useEffect(() => {
    if ((params?.resetOldUtmParams || !R.isEmpty(newIntroductoryParams)) && !R.equals(data, newIntroductoryParams)) {
      setData(newIntroductoryParams as UtmParameters);
    }
  }, [newIntroductoryParams]);

  return {
    data,
    clear: () => setData(undefined),
  };
};
