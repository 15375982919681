import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import {
  DeclarationCalculationType,
  SaleTaxableResult,
} from '@house-id/houseid-types/dist/finances/finances';
import { DeductionCategory } from '@house-id/houseid-types/dist/deductions';

import { QueryHook } from '../../../../../../../types/common';
import useGetCurrentProperty from '../../../../../hooks/useCurrentProperty';
import {
  useGetPropertyFinanceSaleSummaryQuery,
  useGetPropertyFinanceInformationQuery,
} from '../../../api/api.propertyFinance';
import { DeclarationTableItem } from '../components/DeclarationTableRow';

export enum DeductionCalculationSectionType {
  SALES_NUMBER = 'SALES_NUMBER',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  DEDUCTIONS = 'DEDUCTIONS',
  NEW_PROPERTY = 'NEW_PROPERTY',
  REMAINING_RESIDENT_SITUATION = 'REMAINING_RESIDENT_SITUATION',
}

export enum DeductionCalculationItemId {
  SELLING_PRICE = 'selling-price',
  PURCHASE_PRICE = 'purchase-price',
  NEW_HOME_PURCHASE_PRICE = 'new-home-purchase-price',
  DEDUCTION_AMOUNT = 'deduction-amount',
  GROSS_PROFIT = 'gross-profit',
  DEFERRED_TAX_LIABILITY = 'deferred-tax-liability',
  TAXABLE_PROFIT = 'taxable-profit',
  REQUESTED_POSTPONEMENT_TAXES = 'requested-postponement-taxes',
  LOAN_AMOUNT = 'loan-amount',
  EXCESS = 'excess',
  DOWN_PAYMENT = 'down-payment',
  LOAN_TO_VALUE_RATIO = 'loan-to-value-ratio',
  MIN_DOWN_PAYMENT = 'min-down-payment',
}

export type DeductionCalculationSectionGroup = Array<DeclarationTableItem>;

type UseGetDeductionCalculationSections =
  QueryHook<Array<DeductionCalculationSectionGroup> | undefined, { section: DeductionCalculationSectionType }>;

const useGetDeductionCalculationSections: UseGetDeductionCalculationSections = (args, options) => {
  const { t } = useTranslation(['declarations', 'finances']);

  const { data: { id: propertyId } = {} } = useGetCurrentProperty();

  const {
    data: saleSummary,
    isLoading: isFinanceSaleInformationLoading,
    isFetching: isFinanceSaleInformationFetching,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken, options);

  const {
    isLoading: financeInformationLoading,
    isFetching: financeInformationFetching,
    ...rest
  } = useGetPropertyFinanceInformationQuery(propertyId ? { propertyId } : skipToken, options);

  const sellingExpensesAmount = saleSummary?.resultSummary?.sellingExpenses || 0;

  const isProfitable = saleSummary?.type === SaleTaxableResult.PROFIT;

  const currentSalePrice = saleSummary?.taxCalculation?.salesPrice || 0;
  const currentPurchasePrice = saleSummary?.taxCalculation?.purchasePrice || 0;
  const totalDeductionsAmount = saleSummary?.taxCalculation?.totalDeductionsAmount || 0;

  const deductionGroup: Array<DeclarationTableItem> = [
    {
      id: DeductionCategory.PURCHASE_EXPENSE,
      label: t('declarations:purchase_costs_deductions_section_title'),
      value: saleSummary?.deductions?.[DeductionCategory.PURCHASE_EXPENSE]?.totalAmount || 0,
    },
    {
      id: DeductionCategory.SALES_EXPENSE,
      label: t('declarations:selling_expenses_deductions_section_title'),
      value: saleSummary?.deductions?.[DeductionCategory.SALES_EXPENSE]?.totalAmount || 0,
    },
    {
      id: DeductionCategory.RENOVATION,
      label: t('declarations:improvements_and_maintenance_deductions_section_title'),
      value: saleSummary?.deductions?.[DeductionCategory.RENOVATION]?.totalAmount || 0,
    },
  ];

  const saleNumberGroup1: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.SELLING_PRICE,
      label: t('declarations:declarations_declaration_sales_number_selling_price'),
      value: currentSalePrice,
    },
    {
      id: DeductionCalculationItemId.PURCHASE_PRICE,
      label: t('declarations:declarations_declaration_sales_number_purchase_price'),
      value: currentPurchasePrice,
      prefix: '-',
    },
    {
      id: DeductionCalculationItemId.DEDUCTION_AMOUNT,
      label: t('declarations:declarations_total_deduction_amount'),
      value: totalDeductionsAmount || 0,
      prefix: '-',
    },
  ];

  const profit = saleSummary?.taxCalculation?.grossResult || 0;

  const saleNumberGroup2: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.GROSS_PROFIT,
      label: isProfitable
        ? t('declarations:declarations_gross_profit')
        : t('declarations:declarations_gross_loss'),
      value: Math.abs(profit),
      prefix: '=',
      isImportant: true,
    },
    {
      id: DeductionCalculationItemId.DEFERRED_TAX_LIABILITY,
      label: t('declarations:declarations_returning_deferral_amounts'),
      value: saleSummary?.taxCalculation?.remindingCapitalGainsTaxDeferralPaybackAmount || 0,
      prefix: '+',
    },
  ];

  const saleNumberGroup3: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.TAXABLE_PROFIT,
      label: isProfitable ? t('declarations:declarations_taxable_profit') : t('declarations:declarations_taxable_loss'),
      value: saleSummary?.taxCalculation?.taxableResult || 0,
      withBackground: true,
      isImportant: true,
    },
    {
      id: DeductionCalculationItemId.REQUESTED_POSTPONEMENT_TAXES,
      label: isProfitable ? t('declarations:declarations_tax_profit_amount') : t('declarations:declarations_tax_loss_amount'),
      value: saleSummary?.taxCalculation?.taxAmount || 0,
    },
    {
      id: DeductionCalculationItemId.REQUESTED_POSTPONEMENT_TAXES,
      label: t('declarations:declarations_requested_postponement_taxes'),
      value: saleSummary?.capitalGainsTaxDeferralAmount || 0,
    },
  ];

  const deferredTaxLiability = saleSummary?.resultSummary?.taxAmountAfterDeferral || 0;

  const incomeStatementGroup1: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.SELLING_PRICE,
      label: t('declarations:declarations_declaration_sales_number_selling_price'),
      value: currentSalePrice || 0,
    },
    {
      id: DeductionCalculationItemId.DEDUCTION_AMOUNT,
      label: t('declarations:declarations_total_sales_expenditures'),
      value: saleSummary?.resultSummary?.sellingExpenses || 0,
      prefix: '-',
    },
    {
      id: DeductionCalculationItemId.LOAN_AMOUNT,
      label: t('declarations:declarations_declaration_loan_repayment_loan_amount'),
      value: Math.abs(saleSummary?.resultSummary?.loanRepaymentAmount || 0),
      prefix: '-',
    },
    {
      id: DeductionCalculationItemId.DEFERRED_TAX_LIABILITY,
      label: deferredTaxLiability > 0
        ? t('declarations:declarations_declaration_deferred_tax_liability')
        : t('declarations:declarations_declaration_tax_deduction'),
      value: Math.abs(deferredTaxLiability),
      prefix: deferredTaxLiability > 0 ? '-' : '+',
    },
  ];

  const balance = saleSummary?.resultSummary?.balance || 0;

  const incomeStatementGroup2: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.EXCESS,
      label: isProfitable ? t('declarations:declarations_excess') : t('declarations:declarations_deficit'),
      value: balance,
      withBackground: true,
      isImportant: true,
    },
    saleSummary?.salesCalculationTemplateId === DeclarationCalculationType.SALE_WITH_DIVISION_OF_PROPERTY && {
      id: DeductionCalculationItemId.LOAN_TO_VALUE_RATIO,
      label: isProfitable ? t('declarations:declarations_to_the_one_who_moves_out') : t('declarations:declarations_to_the_one_who_stays'),
      value: saleSummary?.split?.leaving?.amount || 0,
    },
  ].filter(Boolean) as Array<DeclarationTableItem>;

  const newPropertyGroup1: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.NEW_HOME_PURCHASE_PRICE,
      label: t('finances:purchase_price'),
      value: saleSummary?.nextHome?.purchasePrice || 0,
    },
    {
      id: DeductionCalculationItemId.DOWN_PAYMENT,
      label: t('declarations:declarations_own_effort'),
      value: saleSummary?.nextHome?.downPayment || 0,
    },
  ];

  const newHomeBalance = saleSummary?.nextHome?.balance || 0;
  const newPropertyGroup2: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.LOAN_AMOUNT,
      label: t('declarations:declarations_declaration_loan_repayment_loan_amount'),
      value: Math.abs(saleSummary?.nextHome?.mortgageAmount || 0),
      isImportant: true,
    },
    {
      id: DeductionCalculationItemId.LOAN_TO_VALUE_RATIO,
      label: t('declarations:declarations_loan_to_value_ratio'),
      value: saleSummary?.nextHome?.loanToValueRatio ? Math.round(saleSummary.nextHome.loanToValueRatio * 100) : 0,
      isImportant: true,
      suffix: '%',
    },
    {
      id: DeductionCalculationItemId.MIN_DOWN_PAYMENT,
      label: t('declarations:declarations_loan_min_value'),
      value: saleSummary?.nextHome?.minDownPayment || 0,
    },
    {
      id: DeductionCalculationItemId.DEFERRED_TAX_LIABILITY,
      label: newHomeBalance > 0 ? t('declarations:declarations_remaining_on_account') : t('declarations:declarations_balance'),
      withBackground: true,
      isImportant: true,
      value: newHomeBalance,
    },
  ];

  const remainingResidenceSituationGroup1: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.SELLING_PRICE,
      label: t('declarations:declarations_declaration_sales_number_selling_price'),
      value: currentSalePrice || 0,
    },
    {
      id: DeductionCalculationItemId.DEDUCTION_AMOUNT,
      label: t('declarations:declarations_total_deduction_amount'),
      value: sellingExpensesAmount || 0,
      prefix: '-',
    },
    {
      id: DeductionCalculationItemId.LOAN_AMOUNT,
      label: t('declarations:declarations_declaration_loan_repayment_loan_amount'),
      value: Math.abs(saleSummary?.resultSummary?.loanRepaymentAmount || 0),
      prefix: '-',
    },
    {
      id: DeductionCalculationItemId.LOAN_TO_VALUE_RATIO,
      label: balance > -1 ? t('declarations:declarations_to_the_one_who_moves_out') : t('declarations:declarations_to_the_one_who_stays'),
      value: saleSummary?.split?.leaving?.amount || 0,
      prefix: '-',
    },
    {
      id: DeductionCalculationItemId.DEFERRED_TAX_LIABILITY,
      label: t('declarations:declarations_declaration_deferred_tax_liability'),
      value: deferredTaxLiability,
      prefix: '-',
    },
  ];

  const remainingResidenceExcess = balance / 2;

  const remainingResidenceSituationGroup2: Array<DeclarationTableItem> = [
    {
      id: DeductionCalculationItemId.EXCESS,
      label: remainingResidenceExcess > 0 ? t('declarations:declarations_excess') : t('declarations:declarations_deficit'),
      value: saleSummary?.split?.remaining?.amount || 0,
      withBackground: true,
      isImportant: true,
    },
  ];

  const sections: Record<DeductionCalculationSectionType, Array<DeductionCalculationSectionGroup>> = {
    [DeductionCalculationSectionType.SALES_NUMBER]: [
      saleNumberGroup1,
      saleNumberGroup2,
      saleNumberGroup3,
    ],
    [DeductionCalculationSectionType.DEDUCTIONS]: [
      deductionGroup,
    ],
    [DeductionCalculationSectionType.INCOME_STATEMENT]: [
      incomeStatementGroup1,
      incomeStatementGroup2,
    ],
    [DeductionCalculationSectionType.NEW_PROPERTY]: [
      newPropertyGroup1,
      newPropertyGroup2,
    ],
    [DeductionCalculationSectionType.REMAINING_RESIDENT_SITUATION]: [
      remainingResidenceSituationGroup1,
      remainingResidenceSituationGroup2,
    ],
  };

  return {
    ...rest,
    data: args?.section ? sections[args.section] : undefined,
    isLoading: isFinanceSaleInformationLoading,
    isFetching: isFinanceSaleInformationFetching,
  };
};

export default useGetDeductionCalculationSections;
