import React, {
  FC,
  SyntheticEvent,
} from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
} from '@mui/material';

import {
  DynamicInputCommonFieldProps,
  DynamicInputSingleValueField,
} from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';
import HIDInfo from '../../../../../../../../../components/HIDInfo';

type DynamicContentCheckBoxProps = DynamicInputCommonFieldProps<boolean> & DynamicInputSingleValueField<boolean>;

const DynamicContentCheckBox: FC<DynamicContentCheckBoxProps> = ({
  title,
  description,
  value,
  source,
  formSettings,
  onValueChange,
}) => {
  const handleValueChange = (_event: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (onValueChange) {
      onValueChange(checked);
    }
  };

  return (
    <Stack>
      <FormControlLabel
        checked={value}
        control={<Checkbox checked={value} sx={{ color: formSettings?.color }} />}
        label={
          <HIDInfo
            isMarkdown
            description={description}
            label={title}
            labelVariant="body1"
          />
        }
        sx={{ width: 'fit-content' }}
        onChange={handleValueChange}
      />
      {source
        ? <DynamicContentSource source={source} />
        : undefined}
    </Stack>
  );
};

export default DynamicContentCheckBox;
