import React from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';

import { range } from '../utils/array';
import Star from './icons/Star';
import { FCC } from '../types/common';

type StarRatingProps = {
  rating: number;
};

const StarRating: FCC<StarRatingProps> = ({ rating, sx }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: 1.5,
        ...sx,
      }}
    >
      {range(1, 5).map((starNumber) => (
        <Star
          fillColor={rating >= starNumber ? theme.palette.warning.light : theme.palette.common.white}
          iconColor={theme.palette.warning.light}
          key={starNumber}
        />
      ))}
    </Stack>
  );
};

export default StarRating;
