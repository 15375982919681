import React, { lazy } from 'react';
import { RouteObject } from 'react-router';

import RouteNames from './RouteNames';
import ProtectedRoute from './ProtectedRoute';

const DeclarationHelpSfSellerCallCenterInstructions = lazy(
  () => import('../modules/Property/modules/Finances/modules/Declaration/modules/DeclarationHelp/screens/SfSellerCallCenterInstructions'),
);
const ReadMoreAboutTheService = lazy(
  () => import('../modules/Property/modules/Finances/modules/Declaration/modules/DeclarationHelp/screens/ReadMoreAboutTheService'),
);

const DeclarationHelpOnboardingUser = lazy(
  () => import('../modules/Property/modules/Finances/modules/Declaration/modules/DeclarationHelp/screens/DeclarationHelpOnboardingUser'),
);

const LandingPagesRouteConfig: Array<RouteObject> = [
  {
    path: RouteNames.DECLARATION_HELP_SF_SELLER_CALL_CENTER_INSTRUCTIONS,
    element: (
      <ProtectedRoute
        authRequired={false}
        path={RouteNames.DECLARATION_HELP_SF_SELLER_CALL_CENTER_INSTRUCTIONS}
        propertyRequired={false}
      >
        <DeclarationHelpSfSellerCallCenterInstructions />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.DECLARATION_HELP_READ_MORE_ABOUT_THE_SERVICE,
    element: (
      <ProtectedRoute
        authRequired={false}
        path={RouteNames.DECLARATION_HELP_READ_MORE_ABOUT_THE_SERVICE}
        propertyRequired={false}
      >
        <ReadMoreAboutTheService />
      </ProtectedRoute>
    ),
  },
  {
    path: RouteNames.DECLARATION_HELP_ONBOARDING,
    element: (
      <ProtectedRoute
        authRequired={false}
        path={RouteNames.DECLARATION_HELP_ONBOARDING}
        propertyRequired={false}
      >
        <DeclarationHelpOnboardingUser />
      </ProtectedRoute>
    ),
  },
];

export default LandingPagesRouteConfig;
