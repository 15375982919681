import qs, { StringifyOptions } from 'query-string';
import { useAuthState } from 'react-firebase-hooks/auth';

import { useGetTokenDataQuery } from '../../Auth/api/api.user';
import useGetCurrentPropertyId from '../../Property/hooks/useGetCurrentPropertyId';
import { getActiveAuth } from '../../../external-services/firebase';
import useGetLogAnalyticsEvent, { ReportEventParams } from './useGetLogAnalyticsEvent';
import { openUrl } from '../../../utils/file';

type UseGetOpenUrlWithAnalyticsParams = ReportEventParams & { url: string, openInNewTab?: boolean };

type UseGetOpenUrlWithAnalytics = () => (params: UseGetOpenUrlWithAnalyticsParams) => void;

const useGetOpenUrlWithAnalytics: UseGetOpenUrlWithAnalytics = () => {
  const [currentUser] = useAuthState(getActiveAuth());
  const { data: propertyId } = useGetCurrentPropertyId(undefined, { skip: !currentUser });

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });
  const defaultQueryParams = {
    propertyId,
    userId: tokenData?.userId,
    subscriptionPlanId: tokenData?.subscriptionPlanId,
  };

  const querySettings = { arrayFormat: 'none', skipNull: true } as StringifyOptions;

  return ({
    url,
    event,
    source,
    hidCategory: entityType,
    eventReportSource,
    params,
    openInNewTab = true,
  }) => {
    const currentParams = {
      ...defaultQueryParams,
      ...params,
      source,
      eventReportSource,
      event,
    };

    const queryParams = qs.stringify({ ...currentParams, url }, querySettings);
    const redirectUrl = `${import.meta.env.VITE_APP_BACKEND_URL}/statistics/redirect?${queryParams}`;

    openUrl(redirectUrl, openInNewTab);
    logAnalyticsEvent({
      ...currentParams,
      hidCategory: entityType,
    });
  };
};

export default useGetOpenUrlWithAnalytics;
