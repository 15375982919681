import RouteNames from '../../../../../../routes/RouteNames';
import { generateRoutePath } from '../../../../../../utils/routes';
import { PropertyIdNavigation } from '../../../../types/types.property';

export const getDeclarationCalculationPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.DECLARATION_CALCULATIONS,
  { propertyId },
);

export const getDeclarationCalculationsDeductionsPath = ({ propertyId }: PropertyIdNavigation) => generateRoutePath(
  RouteNames.DECLARATION_CALCULATIONS_DEDUCTIONS,
  { propertyId },
);
