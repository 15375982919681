import React from 'react';
import {
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { FinanceSaleSummaryDeductionReceipt } from '@house-id/houseid-types/dist/finances/finances';
import { DeleteOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router';

import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import { FCC } from '../../../../../../../../../types/common';
import { hidSpacing } from '../../../../../../../../../utils/number';
import ContentImage from '../../../../../../Content/components/ContentImage';
import useGetEntityInfo from '../../../../../../Content/hooks/useGetEntityInfo';
import { formatMoney } from '../../../../../../../../../utils/string';
import HIDSection from '../../../../../../../../../components/HIDSection';
import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { getReceiptPath } from '../../../../../../Content/modules/Receipt/navigation.receipt';

const StyledStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
  '&:active': {
    backgroundColor: theme.palette.grey[200],
  },
}));

type FinanceSaleSummaryDeductionReceiptCardProps = {
  deductionReceipt: FinanceSaleSummaryDeductionReceipt;
  onRemove: () => void
};

const FinanceSaleSummaryDeductionReceiptCard: FCC<FinanceSaleSummaryDeductionReceiptCardProps> = ({
  deductionReceipt,
  onRemove,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: propertyId } = useGetCurrentPropertyId();

  const getEntityInfo = useGetEntityInfo();
  const entityInfo = getEntityInfo(EntityType.RECEIPT);

  const { receipt } = deductionReceipt;
  const formattedDate = receipt.date ? formatDate(new Date(receipt.date), DateTimeFormats.DATE_ONLY) : undefined;
  const amount = receipt.amount ? receipt.amount : undefined;
  const deductableAmount = deductionReceipt.amount;

  const navigateToReceipt = () => {
    if (receipt.id && propertyId && deductionReceipt.selected) {
      navigate(getReceiptPath({ propertyId, id: receipt.id }));
    }
  };

  return (
    <StyledStack
      alignItems="center"
      direction="row"
      gap={1.5}
      sx={{
        backgroundColor: theme.palette.grey[100],
        borderRadius: '5px',
        padding: 1.5,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        flex={1}
        onClick={navigateToReceipt}
      >
        {
          receipt.mainImage !== null
            ? (
              <ContentImage
                blobs={[receipt.mainImage]}
                size={hidSpacing(6)}
                sx={{
                  marginRight: 1.5,
                  minHeight: hidSpacing(6),
                  justifyContent: 'center',
                }}
              />
            )
            : (
              <HIDSection
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  aspectRatio: 1,
                  height: hidSpacing(6),
                  marginRight: 1.5,
                }}
              >
                <entityInfo.Icon />
              </HIDSection>
            )
        }
        <Stack
          alignItems="center"
          direction="row"
          gap={1.5}
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Stack justifyContent="center" sx={{ minWidth: 0 }}>
            <Typography noWrap variant="subtitle1">
              {receipt.name}
            </Typography>
            {formattedDate && (
              <Typography noWrap color={theme.palette.grey[500]} variant="body2">
                {formattedDate}
              </Typography>
            )}
          </Stack>
          <Stack alignItems="center" direction="row" gap={2}>
            <Stack>
              <Typography textAlign="right">
                {formatMoney(deductableAmount)}
              </Typography>
              <Typography color={theme.palette.grey[500]} textAlign="right" variant="body2">
                {formatMoney(amount)}
              </Typography>
            </Stack>

          </Stack>
        </Stack>
      </Stack>
      <HIDIconButton
        Icon={DeleteOutline}
        onClick={onRemove}
      />

    </StyledStack>
  );
};

export default FinanceSaleSummaryDeductionReceiptCard;
