import React, {
  FC,
  PropsWithChildren,
  Suspense,
} from 'react';
import {
  Navigate,
  ScrollRestoration,
} from 'react-router-dom';
import { FeatureStatus } from '@house-id/houseid-types/dist/subscriptionPlans';

import useGetPropertyDeclarationHelpFeatureValue from '../../hooks/useGetPropertyDeclarationHelpFeatureValue';
import HIDFullScreenLoader from '../../../../../../../../../../components/HIDFullScreenLoader';
import HIDErrorPage from '../../../../../../../../../../components/HIDErrorPage';
import RouteNames from '../../../../../../../../../../routes/RouteNames';
import useGetPropertyDeclarationHelpFeatureAvailable from '../../hooks/useGetPropertyDeclarationHelpFeatureAvailable';
import useDialog from '../../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../../hooks/useDialog/DialogNames';
import useDialogWasShown from '../../../../../../../../../../hooks/useDialogWasShown';
import useGetNavigateToDeclarationHelp from '../../hooks/useGetNavigateToDeclarationHelp';

type ProtectedRouteProps = PropsWithChildren;

const DeclarationHelpEnabledRoute: FC<ProtectedRouteProps> = ({
  children,
}) => {
  const declarationHelpDialogWasShown = useDialogWasShown(DialogNames.DECLARATION_HELP_DIALOG);
  const [openDeclarationHelpDialog] = useDialog(DialogNames.DECLARATION_HELP_DIALOG);
  const [navigateToDeclarationHelp] = useGetNavigateToDeclarationHelp();
  const {
    data: propertyDeclarationHelpFeatureValue,
    isLoading: propertyDeclarationHelpFeatureValueIsLoading,
    error: propertyDeclarationHelpFeatureValueError,
  } = useGetPropertyDeclarationHelpFeatureValue();

  const {
    data: propertyDeclarationHelpFeatureAvailable,
  } = useGetPropertyDeclarationHelpFeatureAvailable();

  if (propertyDeclarationHelpFeatureValueError) {
    return <HIDErrorPage />;
  }

  if (propertyDeclarationHelpFeatureValueIsLoading) {
    return <HIDFullScreenLoader />;
  }

  if (propertyDeclarationHelpFeatureValue !== FeatureStatus.ENABLED) {
    if (propertyDeclarationHelpFeatureAvailable?.status === FeatureStatus.ENABLED) {
      if (declarationHelpDialogWasShown) {
        navigateToDeclarationHelp();
      } else {
        openDeclarationHelpDialog();
      }
      return <Navigate replace to={RouteNames.PROPERTY_HOME} />;
    }
    return <Navigate replace to={RouteNames.BUY_DECLARATION_HELP} />;
  }

  return (
    <Suspense fallback={<HIDFullScreenLoader />}>
      {children}
      <ScrollRestoration />
    </Suspense>
  );
};

export default DeclarationHelpEnabledRoute;
