import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  AnalyticsEvent,
  HIDEventCategory,
  HIDEventCustomCategory,
} from '@house-id/houseid-types/dist/analytics';

import DialogNames from '../../../hooks/useDialog/DialogNames';
import useGetLogAnalyticsEvent from './useGetLogAnalyticsEvent';

// add all values for the HIDScreen enum
const dialogHidEventCategory: Record<DialogNames, HIDEventCategory> = {
  [DialogNames.ADD_CONTENT_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.ADD_DEDUCTION_RENOVATION_DIALOG]: EntityType.DEDUCTION,
  [DialogNames.DECLARATION_HELP_DIALOG]: EntityType.DECLARATION,
  [DialogNames.DECLARATION_HELP_SUBMISSION_RESULT_DIALOG]: EntityType.DECLARATION,
  [DialogNames.DECLARATION_HELP_WELCOME_BACK_DIALOG]: EntityType.DECLARATION,
  [DialogNames.DEDUCTION_TYPE_PICKER_DIALOG]: EntityType.DEDUCTION,
  [DialogNames.DELETE_PROPERTY_DIALOG]: EntityType.PROPERTY,
  [DialogNames.ENROLL_SECOND_FACTOR_DIALOG]: HIDEventCustomCategory.AUTH,
  [DialogNames.EXPERTS_COMMUNICATION_FORM_DIALOG]: EntityType.EXPERT,
  [DialogNames.EXTERNAL_SERVICE_INTRO_DIALOG]: EntityType.EXTERNAL_SERVICE,
  [DialogNames.INFO_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.MANAGE_DECLARATION_CALCULATION_NEW_PROPERTY_DIALOG]: EntityType.DECLARATION,
  [DialogNames.MANAGE_LOAN_REPAYMENT_DIALOG]: EntityType.LOAN,
  [DialogNames.MANAGE_PROPERTY_SALES_NUMBERS_DIALOG]: EntityType.PROPERTY,
  [DialogNames.MANAGE_PROPERTY_SELLING_STAGE_CONDITIONS_DIALOG]: EntityType.PROPERTY,
  [DialogNames.MANAGE_PROPERTY_SOLD_STAGE_CONDITIONS_DIALOG]: EntityType.PROPERTY,
  [DialogNames.OPEN_BANK_ID_DIALOG]: HIDEventCustomCategory.AUTH,
  [DialogNames.PRODUCT_EXTERNAL_MEDIA_INFO_DIALOG]: EntityType.PRODUCT,
  [DialogNames.RATE_ACTION_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.REWARD_OFFER_DIALOG]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [DialogNames.SCAN_RECEIPT_DIALOG]: EntityType.RECEIPT,
  [DialogNames.SELECT_DEDUCTION_TYPE_DIALOG]: EntityType.DEDUCTION,
  [DialogNames.SELECT_RECOMMENDED_DEDUCTION_TYPE_DIALOG]: EntityType.DECLARATION,
  [DialogNames.SELECT_DEDUCTION_SUPPORT_DIALOG]: EntityType.DECLARATION,
  [DialogNames.SELLING_PROPERTY_PROGRESS_DIALOG]: EntityType.PROPERTY,
  [DialogNames.SET_PROPERTY_STAGE_DIALOG]: EntityType.PROPERTY,
  [DialogNames.SHOW_DEDUCTION_CALCULATION_RESULT_DIALOG]: EntityType.DEDUCTION,
  [DialogNames.START_RECURRING_EXPENSES_COLLECTION_DIALOG]: EntityType.RECURRING_EXPENSE,
  [DialogNames.YES_NO_CANCEL_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.ADD_PASSWORD_AUTH_TO_ACCOUNT_DIALOG]: HIDEventCustomCategory.AUTH,
  [DialogNames.APPLY_PROMO_CODE_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.ASK_FORUM_QUESTION_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.BONUS_OFFER_ACTIVATED_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.BROKER_VALUATION_DIALOG]: EntityType.VALUATION,
  [DialogNames.BUDGET_MANAGE_CATEGORIES_DIALOG]: EntityType.BUDGET,
  [DialogNames.CANCEL_SUBSCRIPTION_DIALOG]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [DialogNames.CANCEL_SUBSCRIPTION_QUESTIONNAIRE_DIALOG]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [DialogNames.CHANGE_INBOUND_EMAIL_NAME_DIALOG]: HIDEventCustomCategory.DEFAULT,

  // Adding missing entries
  [DialogNames.CHANGE_PROPERTY_DISPLAY_NAME]: EntityType.PROPERTY,
  [DialogNames.CONNECT_NEW_DEVICE_DIALOG]: HIDEventCustomCategory.AUTH,
  [DialogNames.CONTENT_FILE_PICKER_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.CREATE_BUDGET_FROM_EXISTING_DIALOG]: EntityType.BUDGET,
  [DialogNames.CREATE_SURVEY_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.DECLARATION_HELP_WIZARD]: EntityType.DECLARATION,
  [DialogNames.DECLARATION_HELP_QUESTIONS_WIZARD]: EntityType.DECLARATION,
  [DialogNames.DECLARATION_HELP_SUPPORT_DIALOG]: EntityType.DECLARATION,
  [DialogNames.DECLARATION_HELP_ONBOARDING_WIZARD]: EntityType.DECLARATION,
  [DialogNames.DELETE_ACCOUNT_DIALOG]: HIDEventCustomCategory.AUTH,
  [DialogNames.DELETE_RECURRING_EXPENSES_BANK_ACCOUNT_DIALOG]: EntityType.RECURRING_EXPENSE,
  [DialogNames.DELETE_RECURRING_EXPENSES_BANK_DIALOG]: EntityType.RECURRING_EXPENSE,
  [DialogNames.END_TRIAL_PERIOD_DIALOG]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [DialogNames.ENTER_INVITE_CODE_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.HIDE_DECLARATION_HELP_BANNER_QUESTIONNAIRE_DIALOG]: EntityType.DECLARATION,
  [DialogNames.INVITE_USER_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.LEAVE_PROPERTY_DIALOg]: EntityType.PROPERTY,
  [DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.MANAGE_RECURRING_EXPENSE_BANK_ACCOUNT_CONSENTS_DIALOG]: EntityType.RECURRING_EXPENSE,
  [DialogNames.MANAGE_RECURRING_EXPENSES_CANDIDATES_DIALOG]: EntityType.RECURRING_EXPENSE,
  [DialogNames.MANAGE_DEDUCTION_RECEIPTS_DIALOG]: EntityType.DEDUCTION,
  [DialogNames.MANAGE_DECLARATION_HELP_DEDUCTION_RECEIPTS_DIALOG]: EntityType.DECLARATION,
  [DialogNames.MDD_PAYMENTS_INTRO_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.MY_TOTAL_BONUS_POINTS_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.NO_INVITED_MEMBERS_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.OPEN_MOBILE_APP_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.PRODUCT_TYPE_GUIDE_DIALOG]: EntityType.PRODUCT,
  [DialogNames.PROMPT_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.PROMPT_LINK_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.RECURRING_EXPENSES_COLLECTION_COMPLETED_DIALOG]: EntityType.RECURRING_EXPENSE,
  [DialogNames.SEARCH_PRODUCTS_DIALOG]: EntityType.PRODUCT,
  [DialogNames.SELECT_DEDUCTION_CATEGORY_DIALOG]: EntityType.DEDUCTION,
  [DialogNames.SELECT_RECURRING_EXPENSES_BANK_ACCOUNTS_DIALOG]: EntityType.RECURRING_EXPENSE,
  [DialogNames.SELECT_RECURRING_EXPENSES_DIALOG]: EntityType.RECURRING_EXPENSE,
  [DialogNames.SELLING_PROPERTY_CHECKLIST_INFO_DIALOG]: EntityType.PROPERTY,
  [DialogNames.START_TRIAL_PERIOD_DIALOG]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [DialogNames.SUBSCRIPTIONS_SHARING_DIALOG]: HIDEventCustomCategory.SUBSCRIPTION_PLAN,
  [DialogNames.TIMELINE_EVENT_TYPE_PICKER_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.TRY_FEATURE_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.TUTORIAL_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.VALUATION_INDICATION_WIZARD_DIALOG]: EntityType.VALUATION,
  [DialogNames.WAIT_BANK_ID_COMPLETED_DIALOG]: HIDEventCustomCategory.AUTH,
  [DialogNames.WELCOME_DIALOG]: HIDEventCustomCategory.DEFAULT,
  [DialogNames.WELCOME_TO_FORUM_DIALOG]: HIDEventCustomCategory.DEFAULT,
};

const useGetOpenDialogLogEvent = () => {
  const logEvent = useGetLogAnalyticsEvent();

  const logDialogEvent = (dialogName: DialogNames) => {
    const hidCategory = dialogHidEventCategory[dialogName];
    logEvent({
      event: AnalyticsEvent.OPEN_DIALOG,
      params: {
        dialog: dialogName,
      },
      hidCategory,
    });
  };

  return logDialogEvent;
};

export default useGetOpenDialogLogEvent;
