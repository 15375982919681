import { EntityType } from '@house-id/houseid-types/dist/entityType';

export const ENTITY_POSSIBLE_CONNECTION_TYPES: Array<EntityType> = [
  EntityType.PRODUCT,
  EntityType.DOCUMENT,
  EntityType.RECEIPT,
  EntityType.PHOTO,
  EntityType.NOTE,
  EntityType.TASK,
  EntityType.RECURRING_EXPENSE,
  EntityType.BUILDING,
  EntityType.ROOM,
  EntityType.BUILDING_PART,
  EntityType.OUTDOOR,
  EntityType.TIMELINE_ENTRY,
];

const CUSTOM_CONNECTIONS_TYPES: Partial<Record<EntityType, Array<EntityType>>> = {
  [EntityType.VALUATION]: [EntityType.DOCUMENT],
  [EntityType.DEDUCTION]: [EntityType.DOCUMENT, EntityType.PHOTO, EntityType.TIMELINE_ENTRY, EntityType.NOTE],
};

type UseGetEntityPossiblyConnectionTypes = (entityType: EntityType) => Array<EntityType>;

const getEntityPossiblyConnectionTypes: UseGetEntityPossiblyConnectionTypes = (entityType) =>
  ENTITY_POSSIBLE_CONNECTION_TYPES.includes(entityType)
    ? ENTITY_POSSIBLE_CONNECTION_TYPES.filter((possibleEntityType) => possibleEntityType !== entityType)
    : CUSTOM_CONNECTIONS_TYPES[entityType] || [];

export default getEntityPossiblyConnectionTypes;
