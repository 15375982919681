import { DeductionCategory } from '@house-id/houseid-types/dist/deductions';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

const useGetDeductionCategoryLabel = () => {
  const { t } = useTranslation(['declarations']);

  const getDeductionCategoryLabel = (type: DeductionCategory) => match(type)
    .with(DeductionCategory.PURCHASE_EXPENSE, () => t('declarations:purchase_costs_deductions_section_title'))
    .with(DeductionCategory.SALES_EXPENSE, () => t('declarations:selling_expenses_deductions_section_title'))
    .with(DeductionCategory.RENOVATION, () => t('declarations:improvements_and_maintenance_deductions_section_title'))
    .exhaustive();

  return getDeductionCategoryLabel;
};

export default useGetDeductionCategoryLabel;
