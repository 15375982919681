import {
  FetchBaseQueryError,
  skipToken,
} from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import {
  useMemo,
  useState,
} from 'react';
import * as R from 'ramda';
import { SerializedError } from '@reduxjs/toolkit';
import { HIDBlob } from '@house-id/houseid-types/dist/common';
import { useDispatch } from 'react-redux';

import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import { PropertyId } from '../../../../../types/types.property';
import {
  ContentFile,
  FileBlobUploadingStatus,
} from '../types.contentFile';
import { DeleteContentParams } from '../../../types/types.content';
import { propertyApi } from '../../../../../api/api.property';
import { resolveUploadUrl } from '../../../../../../../utils/env';
import { FileMimeType } from '../../../../../../../constants/mimeTypes';
import { provideArrayTags } from '../../../../../../../api/HIDBaseQuery';

const FIRST_INFO_BYTES_LENGTH = 12;

function isHeic(buffer?: Uint8Array) {
  if (!buffer || buffer.length < FIRST_INFO_BYTES_LENGTH) {
    return false;
  }

  const heicFirstBytes = [
    0x66, // f
    0x74, // t
    0x79, // y
    0x70, // p
    0x68, // h
    0x65, // e
    0x69, // i
    0x63, // c
  ];

  return buffer.slice(4, FIRST_INFO_BYTES_LENGTH).join('') === heicFirstBytes.join('');
}

type ShortFileInfo = { name: string; type: string; };

const resolveMimeTypes = (files: Array<File>): Promise<Array<ShortFileInfo>> =>
  Promise.all(
    files.map(
      async (file) => {
        const buffer = await file.arrayBuffer();
        return {
          name: file.name,
          type: buffer.byteLength >= FIRST_INFO_BYTES_LENGTH && isHeic(new Uint8Array(buffer, 0, FIRST_INFO_BYTES_LENGTH))
            ? FileMimeType.HEIC
            : file.type,
        };
      },
    ),
  );

type CreateBlobsSignedUrl = PropertyId & {
  files: Array<{
    fileName: string;
    mime: string;
    size?: number;
    blobId?: string;
  }>
};

export const contentFileApi = propertyApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllContentFiles: builder.query<Array<ContentFile>, PropertyId>({
      query: ({ propertyId }) => `/v2/properties/${propertyId}/files`,
      providesTags: (result) => provideArrayTags(HIDApiTags.CONTENT_FILE, result),
    }),
    createContentFiles: builder.mutation<Array<ContentFile>, CreateBlobsSignedUrl>({
      query: ({ propertyId, files }) => ({
        url: `/v2/properties/${propertyId}/files`,
        method: 'POST',
        body: files,
      }),
      invalidatesTags: () => [
        HIDApiTags.PROPERTY_STATUS,
        HIDApiTags.CONTENT_FILE,
      ],
    }),
    deleteContentFiles: builder.mutation<Array<string>, DeleteContentParams>({
      query: ({ propertyId, ids }) => ({
        url: `/v2/properties/${propertyId}/files?${qs.stringify({ ids })}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: (_result, _error, arg) => [
        ...arg.ids.map((id) => ({ type: HIDApiTags.CONTENT_FILE as const, id })),
        HIDApiTags.PROPERTY_STATUS,
      ],
    }),
    uploadContentFileBlob: builder.mutation<void, { signedUploadUrl: string, contentFileId: string, file: File, type: string }>({
      queryFn: async ({ signedUploadUrl, file, type }) => {
        try {
          await fetch(signedUploadUrl, {
            method: 'PUT',
            body: file,
            credentials: 'same-origin',
            headers: {
              'Content-Type': type,
              'Content-Disposition': `attachment; filename="${encodeURIComponent(file.name)}"`,
            },
          });
        } catch (e) {
          return { error: { status: 500, statusText: 'Internal Server Error', data: undefined } };
        }

        return { data: undefined };
      },
    }),
    createBlobFromExternalMedia: builder.mutation<HIDBlob, PropertyId & { url: string }>({
      query: ({ propertyId, url }) => ({
        url: `/properties/${propertyId}/blobs`,
        method: 'POST',
        body: { url },
      }),
      transformResponse: (response: { blobs: Array<HIDBlob> }) => response.blobs[0],
      invalidatesTags: () => [
        HIDApiTags.CONTENT_FILE,
        HIDApiTags.PROPERTY_STATUS,
      ],
    }),
    createContentFileFromExternalMedia: builder.mutation<ContentFile, PropertyId & { url: string }>({
      query: ({ propertyId, url }) => ({
        url: `/v2/properties/${propertyId}/files`,
        method: 'POST',
        body: { url },
      }),
      transformResponse: (response: Array<ContentFile>) => response[0],
      invalidatesTags: (result) => [
        { type: HIDApiTags.CONTENT_FILE as const, id: result?.id },
        HIDApiTags.PROPERTY_STATUS,
      ],
    }),
  }),
});

export const {
  useGetAllContentFilesQuery,
  useDeleteContentFilesMutation,
  useCreateContentFilesMutation,
  useUploadContentFileBlobMutation,
  useCreateBlobFromExternalMediaMutation,
  useCreateContentFileFromExternalMediaMutation,
} = contentFileApi;

export const useGetContentFileBlobsByIdsQuery = ({
  propertyId,
  contentFileIds,
}: {
  propertyId?: string,
  contentFileIds?: Array<string>
}) => useGetAllContentFilesQuery(
  propertyId && contentFileIds?.length ? { propertyId } : skipToken,
  {
    selectFromResult: ({ data: contentFiles, isLoading, isSuccess }) => ({
      data: contentFiles
        ?.filter((contentFile: ContentFile) => contentFileIds?.includes(contentFile.id))
        .map((file: ContentFile) => file.blob) || [],
      isLoading,
      isSuccess,
    }),
  },
);

type UseUploadContentFilesMutation = () => [
  (propertyId: string, files: Array<File>) => void,
  {
    data: ContentFile[],
    status: Record<string, FileBlobUploadingStatus>,
    isLoading: boolean,
    isSuccess: boolean,
    isUploading: boolean,
    isBlobsUploaded: boolean,
    isError: boolean,
    error: FetchBaseQueryError | SerializedError | undefined
  },
];

export const useUploadContentFilesMutation: UseUploadContentFilesMutation = () => {
  const dispatch = useDispatch();

  const [createContentFiles, {
    data: createdContentFiles = [],
    isLoading,
    isError,
    error,
    isSuccess,
  }] = useCreateContentFilesMutation();

  const [uploadContentFileBlob] = useUploadContentFileBlobMutation();

  const [filesUploading, setFilesUploading] = useState<Record<string, FileBlobUploadingStatus>>({});
  const isUploading = useMemo(() => {
    const statuses = R.values(filesUploading);
    return Boolean(statuses.length) && R.any((status) => status === 'loading', statuses);
  }, [filesUploading]);

  const isBlobsUploaded = useMemo(() => {
    const statuses = R.values(filesUploading);
    return Boolean(statuses.length) && R.all((status) => status === 'success' || status === 'error', statuses);
  }, [filesUploading]);

  const upload = (propertyId: string, files: Array<File>) => {
    if (!files.length) {
      return;
    }

    resolveMimeTypes(files)
      .then(
        (filesInfo) =>
          createContentFiles({
            propertyId,
            files: filesInfo.map(({ name, type }) => ({
              fileName: name,
              mime: type,
            })),
          })
            .unwrap(),
      )
      .then((createdFiles) => {
        setFilesUploading(R.fromPairs(createdFiles.map((file) => [file.id, 'loading'])));

        const fileUploadTasks = R.map((contentFile: ContentFile) => {
          const fileToUpload = files.find((f) => f.name === contentFile.blob.name);

          if (!fileToUpload) {
            return Promise.reject(new Error('No file'));
          }

          return uploadContentFileBlob({
            signedUploadUrl: resolveUploadUrl(contentFile.blob.signedUploadUrl) as string,
            contentFileId: contentFile.id,
            file: fileToUpload,
            type: contentFile.blob.mime || fileToUpload.type,
          })
            .unwrap()
            .then(() => setFilesUploading((prev) => ({ ...prev, [contentFile.id]: 'success' })))
            .catch(() => setFilesUploading((prev) => ({ ...prev, [contentFile.id]: 'error' })));
        }, createdFiles);

        return Promise.allSettled(fileUploadTasks)
          .then(
            () => setTimeout(() => dispatch(propertyApi.util.invalidateTags([HIDApiTags.CONTENT_FILE, HIDApiTags.PROPERTY_STATUS])), 1000),
          );
      });
  };

  return [upload, {
    data: createdContentFiles,
    status: filesUploading,
    isSuccess,
    isLoading,
    isError,
    isUploading,
    isBlobsUploaded,
    error,
  }];
};
