export enum HIDApiTags {
  ARTICLE = 'article',
  BONUS_OFFERS = 'bonusOffers',
  BOUGHT_BY = 'boughtBy',
  BUDGET = 'budget',
  BUDGET_COMPARISON = 'budgetComparison',
  BUDGET_FILTRATION = 'budgetFiltration',
  BUDGET_LIST = 'budgetList',
  BUILDING = 'building',
  BUILDING_ENTITIES_LIST = 'buildingEntitiesList',
  BUILDING_ENTITY = 'buildingEntity',
  BUILDING_RECOMMENDED_TEMPLATE = 'buildingRecommendedTemplate',
  CONTACT = 'contact',
  CONTACT_CATEGORIES = 'contactCategories',
  CONTENT_CONNECTIONS = 'contentConnections',
  CONTENT_FILE = 'contentFile',
  CONSUMABLE = 'consumable',
  DEDUCTION = 'deduction',
  DEDUCTION_OVERVIEW = 'deductionOverview',
  DEDUCTION_RECEIPTS = 'deductionReceipts',
  DEDUCTION_SETTINGS = 'deductionSettings',
  DIALOG_SETTINGS = 'dialogSettings',
  DOCUMENT = 'document',
  DOCUMENT_CATEGORY = 'documentCategory',
  DYNAMIC_CONTENT = 'dynamicContent',
  EMAIL = 'email',
  EMAIL_ATTACHMENT = 'emailAttachment',
  EXPERTS = 'experts',
  FINANCE_INFORMATION = 'financeInformation',
  FORUM_REPLY = 'forumReply',
  FORUM_THREAD = 'forumThread',
  INSIGHTS = 'insights',
  INSURANCE = 'insurance',
  INSURANCE_COMPANY = 'insuranceCompany',
  INSURANCES = 'insurances',
  LABELING_QUEUE = 'labelingQueue',
  LABELING_TEMPLATES_TASK = 'labelingQueuesTask',
  LABELING_TEMPLATES_TASK_ATTACHMENT = 'labelingQueuesTaskAttachment',
  LOAN = 'loan',
  LOANS_OVERVIEW = 'loansOverview',
  MESSAGE = 'message',
  NOTE = 'note',
  PHOTO = 'photo',
  PLACE = 'place',
  PRODUCT = 'product',
  PRODUCT_ACTIVITY = 'propertyActivity',
  PRODUCT_CATEGORY = 'productCategory',
  PRODUCT_DYNAMIC_CONTENT = 'productDynamicContent',
  PRODUCT_SUMMARY = 'productSummary',
  PRODUCT_TYPE_GUIDE = 'productTypeGuide',
  PROPERTY = 'property',
  PROPERTY_BLUEPRINTS = 'propertyBlueprints',
  PROPERTY_CHECK_DETAILS = 'propertyCheckDetails',
  PROPERTY_DOCUMENTATION = 'propertyDocumentation',
  PROPERTY_EXTERNAL_SERVICE = 'propertyExternalService',
  PROPERTY_FEATURES = 'propertyFeatures',
  PROPERTY_FINANCE = 'propertyFinance',
  PROPERTY_FINANCE_INFORMATION = 'propertyFinanceInformation',
  PROPERTY_FINANCE_SALE_INFORMATION = 'propertyFinanceSaleInformation',
  PROPERTY_INBOUND_EMAIL = 'propertyInboundEmail',
  PROPERTY_INDICATION_VALUATION = 'propertyIndicationValuation',
  PROPERTY_INDICATION_VALUATION_HISTORY = 'propertyIndicationValuationHistory',
  PROPERTY_INDICATION_VALUATION_SETTINGS = 'propertyIndicationValuationSettings',
  PROPERTY_INSURANCE = 'propertyInsurance',
  PROPERTY_INVITE_CODE = 'propertyInviteCode',
  PROPERTY_MAIN_BUILDING = 'propertyMainBuilding',
  PROPERTY_PLANNING = 'propertyPlanning',
  PROPERTY_PRODUCTS = 'propertyProducts',
  PROPERTY_PROFILE = 'propertyProfile',
  PROPERTY_PROGRESS = 'propertyProgress',
  PROPERTY_PROGRESS_FINANCE = 'propertyProgressFinance',
  PROPERTY_PROGRESS_SPECIFICATION = 'propertyProgressSpecification',
  PROPERTY_PURCHASE = 'propertyPurchase',
  PROPERTY_REFERRAL = 'propertyReferral',
  PROPERTY_SETTINGS = 'propertySettings',
  PROPERTY_SPECIFICATION = 'propertySpecification',
  PROPERTY_STATUS = 'propertyStatus',
  PROPERTY_STORAGE = 'propertyStorage',
  PROPERTY_SUBSCRIPTION_STATUS = 'propertySubscriptionStatus',
  PROPERTY_STRUCTURE_AND_FACTS = 'propertyStructureAndFacts',
  PROPERTY_TODO = 'propertyToDo',
  PROPERTY_USER = 'propertyUser',
  PROPERTY_USER_REMOVAL_REQUEST = 'propertyUserRemovalRequest',
  PROPERTY_VALUATION = 'propertyValuation',
  PROPERTY_VALUATION_BOOK_INFO_FORM = 'propertyValuationBookInfoForm',
  RECEIPT = 'receipt',
  RECEIPT_CATEGORY = 'receiptCategory',
  RECEIPT_FILTERS = 'receiptFilters',
  RECEIPT_SUMMARY = 'receiptSummary',
  RECURRING_EXPENSE = 'recurringExpense',
  RECURRING_EXPENSE_BANK_ACCOUNT = 'recurringExpenseBankAccount',
  RECURRING_EXPENSE_CANDIDATE = 'recurringExpenseCandidate',
  RECURRING_EXPENSE_COMPARISON = 'recurringExpenseComparison',
  RECURRING_EXPENSE_CONSENTS = 'recurringExpenseConsents',
  RECURRING_EXPENSE_FILTRATION = 'recurringExpenseFiltration',
  RECURRING_EXPENSE_SHARED_CONSENTS = 'recurringExpenseSharedConsents',
  RECURRING_EXPENSE_SUMMARY = 'recurringExpenseSummary',
  SELLING_PROPERTY_ARCHIVE = 'sellingPropertyArchive',
  SELLING_PROPERTY_TRANSFER = 'sellingPropertyTransfer',
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_MEMBERS = 'sharingSubscriptionMembers',
  SUBSCRIPTION_PLAN_OFFER = 'subscriptionPlanOffer',
  SUBSCRIPTION_PLAN_STATUS = 'subscriptionPlanStatus',
  SUGGESTION = 'suggestion',
  SUGGESTIONS = 'suggestions',
  SUGGESTIONS_FILTERS = 'suggestionsFilters',
  SUGGESTIONS_SETTING = 'suggestionsSetting',
  SUGGESTIONS_SUMMARY = 'suggestionsSummary',
  SURVEY = 'survey',
  TASK = 'task',
  TASK_GROUP = 'taskGroup',
  TASK_SUMMARY = 'taskSummary',
  TASK_TEMPLATE = 'taskTemplate',
  TIMELINE = 'timeline',
  TIMELINE_EVENT = 'timelineEvent',
  TIMELINE_FILTER = 'timelineFilter',
  TIMELINE_GET_STARTED_WIZARD = 'timelineGetStartedWizard',
  USER = 'user',
  USER_DEVICE_SETTINGS = 'userDeviceSettings',
  USER_FINANCE_INFORMATION = 'userFinanceInformation',
  USER_REMOTE_FEATURES = 'userRemoteFeatures',
  USER_SETTINGS = 'userSettings',
  USER_STATISTICS = 'userStatistics',
  USER_STATUS = 'userStatus',
  USER_STORAGE = 'userStorage',
  USER_TOKEN_DATA = 'userTokenData',
  TRUSTLY_DEPOSIT_SESSION = 'trustlyDepositSession',
  VALUATION = 'valuation',
}
