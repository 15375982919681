import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { Language } from '@house-id/houseid-types/dist/common';

import HIDTextField, {
  CurrencyAdornment,
  HIDTextFieldProps,
  useGetTextFieldProps,
} from './HIDTextField';
import {
  FCC,
  FCCProps,
} from '../types/common';
import { getCurrentLanguage } from '../utils/locale';

const tryParseNumber = (value?: string) => {
  try {
    const parsed = Number(value);
    if (!Number.isNaN(parsed) && typeof parsed === 'number') {
      return parsed;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

type HIDCurrencyFieldProps = Omit<HIDTextFieldProps, 'multiline' | 'minRows' | 'endAdornment' | 'type'>;

type HIDCurrencyFieldPropsWithRef = FCCProps<HIDCurrencyFieldProps, HTMLInputElement>;

const HIDCurrencyField: FCC<HIDTextFieldProps> = forwardRef<HTMLInputElement, HIDCurrencyFieldPropsWithRef>((
  {
    id,
    label,
    placeholder,
    variant = 'standard',
    inputProps,
    value = '',
    fullWidth = true,
    required,
    error,
    helperText,
    helperTextWrap = false,
    showHelperText = true,
    InputProps,
    InputLabelProps,
    valid,
    disabled,
    showValidationIcon = false,
    sx,
    color,
    backgroundColor,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
  },
  ref,
) => {
  const textFieldProps = useGetTextFieldProps({
    endAdornment: <CurrencyAdornment />,
    error,
    helperTextWrap,
    InputLabelProps,
    InputProps,
    showValidationIcon,
    valid,
    value,
    backgroundColor,
    color,
  });

  const handleOnChange = (value: string) => {
    if (onChange) {
      // @ts-ignore
      onChange({ target: { value } });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      const step: number = inputProps?.step || 1;

      const delta = event.key === 'ArrowUp'
        ? step
        : event.key === 'ArrowDown' ? -step : 0;

      const currentValue = (value ? tryParseNumber(value) : 0);
      const newValue = currentValue + delta;

      const minValue = inputProps?.min;
      const maxValue = inputProps?.max;

      if ((minValue === undefined || minValue <= newValue) && (maxValue === undefined || maxValue >= newValue)) {
        handleOnChange(newValue.toString());
      }
    }
  };

  return (
    <NumericFormat
      {...textFieldProps}
      valueIsNumericString
      color={color}
      customInput={HIDTextField}
      decimalSeparator={getCurrentLanguage() === Language.EN ? '.' : ','}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      getInputRef={ref}
      helperText={helperText}
      id={id}
      inputProps={inputProps}
      label={label}
      placeholder={placeholder}
      required={required}
      showHelperText={showHelperText}
      sx={sx}
      thousandSeparator=" "
      value={value}
      variant={variant}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      onValueChange={({ value }) => handleOnChange(value)}
    />
  );
});

HIDCurrencyField.displayName = 'HIDCurrencyField';

export default HIDCurrencyField;
