import React, {
  PropsWithChildren,
  forwardRef,
} from 'react';
import { match } from 'ts-pattern';
import {
  ButtonProps,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  SxProps,
  styled,
} from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

import {
  FCC,
  FCCProps,
} from '../../types/common';
import { ICON_SIZE } from '../../constants/layout';

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.common.transparent,
  borderRadius: theme.spacing(5),
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    color: theme.palette.grey[500],
    borderColor: theme.palette.grey[500],
  },
}));

const PrimaryButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SecondaryButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.lightest,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.lighter,
  },
}));

const YellowButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.light,
  },
  '&:active': {
    backgroundColor: theme.palette.warning.dark,
  },
}));

const RedButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.light,
  },
  '&:active': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const BlankButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: theme.palette.common.transparent,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SkattioPrimaryButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.skattio.main,
  '&:hover': {
    backgroundColor: theme.palette.skattio.light,
  },
  '&:active': {
    backgroundColor: theme.palette.skattio.dark,
  },
}));

const SkattioSecondaryButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.skattio.main,
  borderColor: theme.palette.skattio.main,
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.skattio.lightest,
  },
  '&:active': {
    backgroundColor: theme.palette.skattio.lighter,
  },
}));

export type HIDButtonProps = {
  title?: string;
  Icon?: React.ElementType;
  iconPosition?: 'left' | 'right';
  color?: 'primary' | 'secondary' | 'yellow' | 'red' | 'blank' | 'skattio-primary' | 'skattio-secondary';
  buttonColor?: ButtonProps['color'];
  iconColor?: string;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  fullWidth?: ButtonProps['fullWidth'];
  noWrap?: boolean;
  disabled?: ButtonProps['disabled'];
  loading?: boolean;
  childrenContainerSx?: SxProps;
  onClick: ButtonProps['onClick'];
};

type HIDButtonPropsWithRef = FCCProps<PropsWithChildren<HIDButtonProps>, HTMLButtonElement>;

const HIDButton: FCC<HIDButtonProps> = forwardRef<HTMLButtonElement, HIDButtonPropsWithRef>((
  {
    title,
    Icon,
    iconPosition = 'right',
    color = 'primary',
    buttonColor,
    variant = 'contained',
    size,
    sx,
    style,
    fullWidth,
    noWrap = false,
    disabled,
    loading,
    children,
    childrenContainerSx,
    onClick,
  },
  ref,
) => {
  const theme = useTheme();

  const ButtonColor = match(color)
    .with('primary', () => PrimaryButton)
    .with('secondary', () => SecondaryButton)
    .with('yellow', () => YellowButton)
    .with('blank', () => BlankButton)
    .with('red', () => RedButton)
    .with('skattio-primary', () => SkattioPrimaryButton)
    .with('skattio-secondary', () => SkattioSecondaryButton)
    .exhaustive();

  const loaderColor = match(color)
    .with('primary', () => theme.palette.primary.main)
    .with('secondary', () => theme.palette.grey[600])
    .with('yellow', () => theme.palette.warning.main)
    .with('red', () => theme.palette.common.white)
    .with('blank', () => theme.palette.common.white)
    .with('skattio-primary', () => theme.palette.skattio.main)
    .with('skattio-secondary', () => theme.palette.grey[600])
    .exhaustive();

  const iconColor = disabled ? theme.palette.grey[500] : match(color)
    .with('primary', () => theme.palette.common.white)
    .with('secondary', () => theme.palette.primary.main)
    .with('yellow', () => theme.palette.common.white)
    .with('red', () => theme.palette.common.white)
    .with('blank', () => theme.palette.common.white)
    .with('skattio-primary', () => theme.palette.common.white)
    .with('skattio-secondary', () => theme.palette.skattio.main)
    .exhaustive();

  return (
    <ButtonColor
      disableRipple
      color={buttonColor}
      disabled={disabled}
      fullWidth={fullWidth}
      loading={loading}
      loadingIndicator={
        <CircularProgress
          size={ICON_SIZE}
          sx={{
            color: loaderColor,
            strokeLinecap: 'round',
          }}
        />
      }
      ref={ref}
      size={size}
      style={style}
      sx={sx}
      variant={variant}
      onClick={onClick}
    >
      <Stack
        alignItems="center"
        direction="row"
        flexGrow={1}
        justifyContent={loading || Icon
          ? iconPosition === 'right'
            ? 'space-between'
            : 'flex-start'
          : 'center'}
        minWidth={0}
      >
        {iconPosition === 'left' && Icon ? <Icon iconColor={iconColor} sx={{ color: iconColor }} /> : null}
        {
          typeof children === 'string' || title
            ? (
              <Typography
                color={iconColor}
                noWrap={noWrap}
                sx={{
                  marginLeft: iconPosition === 'left' && Icon ? 1 : 0,
                  marginRight: iconPosition === 'right' && Icon ? 1 : 0,
                  ...childrenContainerSx,
                }}
                variant="body1"
              >
                {title || children}
              </Typography>
            )
            : children
        }
        {iconPosition === 'right' && Icon ? <Icon iconColor={iconColor} sx={{ color: iconColor }} /> : null}
      </Stack>
    </ButtonColor>
  );
});

HIDButton.displayName = 'HIDButton';

export default HIDButton;
