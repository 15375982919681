import { DeductionChatContext } from '@house-id/houseid-types/dist/deductions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Article } from '@house-id/houseid-types/dist/article';
import {
  Box,
  Card,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';

import { FCC } from '../../../../../../../../../types/common';
import DeductionArticle from './DeductionArticle';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import HIDLink from '../../../../../../../../../components/HIDLink';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetDeductionArticlesQuery } from '../api/api.deductions';
import { DEDUCTION_ARTICLES_ALL_URL } from '../constants.declarationHelp';

const SliderContainer = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  gap: '16px',
  padding: '16px 0',
  '&::-webkit-scrollbar': {
    display: 'none', // Hide scrollbar for cleaner look
  },
});

const ArticleCard = styled(Card)({
  minWidth: '270px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  borderRadius: '8px',
  padding: '12px',
});

type DeductionArticlesCarouselProps = {
  context: DeductionChatContext;
};

const DeductionArticlesCarousel: FCC<DeductionArticlesCarouselProps> = ({
  context,
  sx,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['deductions']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: deductionArticles = [],
    isLoading,
    isFetching: isArticlesFetching,
  } = useGetDeductionArticlesQuery(propertyId ? { propertyId, context } : skipToken);

  const isArticlesLoading = isArticlesFetching || isLoading;

  const articles = isArticlesLoading
    ? Array.from({ length: 5 }, (_, index) => ({ id: index })) as unknown as Article[]
    : R.take(5, deductionArticles);

  return articles.length
    ? (
      <Stack sx={sx}>
        <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
          <HIDTypography variant="h6">
            {t('deductions:deductions_section_deductions_articles')}
          </HIDTypography>
          <HIDLink
            color="skattio"
            href={DEDUCTION_ARTICLES_ALL_URL}
            label={t('deductions:deductions_section_open_more_articles')}
            target="_self"
            underline="none"
          />
        </Stack>
        <SliderContainer sx={{ marginX: theme.spacing(-2.5), paddingX: theme.spacing(2.5) }}>
          {articles.map((article, index) => (
            <ArticleCard key={index}>
              <DeductionArticle
                article={article}
                isLoading={isArticlesLoading}
                sx={{ height: 280 }}
              />
            </ArticleCard>
          ))}
        </SliderContainer>
      </Stack>
    ) : null;
};

export default DeductionArticlesCarousel;
