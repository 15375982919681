import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PAGE_OUTER_SPACING } from '../../../constants/layout';

const DeclarationHelpPrivacyPolicy: FC = () => {
  const { t } = useTranslation(['auth']);

  return (
    <Stack dangerouslySetInnerHTML={{ __html: t('auth:declaration_help_privacy_policy') }} sx={{ padding: PAGE_OUTER_SPACING }} />
  );
};

export default DeclarationHelpPrivacyPolicy;
