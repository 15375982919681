import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../../../utils/date';
import { formatMoney } from '../../../../../../../../../utils/string';
import { getNumberSignPrefix } from '../../../../../../../../../utils/number';
import { capitalize } from '../../../../../../../../../utils/url';

type IndicationValuationHistoryItemProps = {
  createdAt: string;
  difference: number;
  expectation: number;
};

const IndicationValuationHistoryItem: FC<IndicationValuationHistoryItemProps> = ({
  createdAt,
  difference,
  expectation,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances']);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ py: 2.5 }}
    >
      <HIDTypography>
        {capitalize(formatDate(new Date(createdAt), DateTimeFormats.MONTH_AND_YEAR) || '')}
      </HIDTypography>
      <Stack
        alignItems="flex-end"
      >
        <HIDTypography variant="subtitle1">
          {formatMoney(expectation, 'never')}
        </HIDTypography>
        {
          Math.abs(difference) > 1
            ? (
              <HIDTypography
                sx={{ color: difference > 0 ? theme.palette.primary.main : theme.palette.error.main }}
              >
                {getNumberSignPrefix(difference)}
                {' '}
                {formatMoney(Math.abs(difference), 'never')}
              </HIDTypography>
            )
            : (
              <HIDTypography sx={{ color: theme.palette.grey[500] }}>
                {t('finances:unchanged')}
              </HIDTypography>
            )
        }
      </Stack>
    </Stack>
  );
};

export default IndicationValuationHistoryItem;
