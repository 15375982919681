import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';
import { ModificationType } from '@house-id/houseid-types/dist/content/entityContentConnection';

import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { CreateContentSaveMode } from '../components/CreateContentPageBottomToolbar';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { EntityUId } from '../types/types.content';
import { usePatchEntityConnectionsMutation } from '../api/api.content';
import { ManageContentConnectionsMode } from '../components/dialogs/manageContentConnectionsDialog/ManageContentConnectionsDialog';

type UseManageConnectionAfterCreateOrUpdate = ({
  entityType,
  connections,
  onGoBack,
}: {
  entityType: EntityType;
  connections?: EntityUId[];
  onGoBack: (entity: HIDEntityId) => void;
}) => ({
  afterUpdate: (updatedEntity: HIDEntityId, saveMode?: CreateContentSaveMode) => void;
  afterCreate: (createdEntity: HIDEntityId, saveMode?: CreateContentSaveMode) => void;
});

const useManageConnectionAfterCreateOrUpdate: UseManageConnectionAfterCreateOrUpdate = ({
  entityType,
  connections,
  onGoBack,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const [patchEntityConnections] = usePatchEntityConnectionsMutation();

  const [openManageConnectionsDialog] = useDialog(
    DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG,
  );

  const handleManageConnections = (entity?: HIDEntityId) => {
    if (entity !== undefined) {
      openManageConnectionsDialog({
        entityType,
        entity,
        mode: {
          type: ManageContentConnectionsMode.ADD,
        },
        onConnectionsAdded: () => onGoBack(entity),
      });
    }
  };

  const handleNavigateAfterChange = (entity: HIDEntityId, saveMode?: CreateContentSaveMode) => {
    if (saveMode === 'add-connections') {
      handleManageConnections(entity);
    } else {
      onGoBack(entity);
    }
  };

  return {
    afterUpdate: handleNavigateAfterChange,
    afterCreate: (createdEntity: HIDEntityId, saveMode?: CreateContentSaveMode) => {
      if (!connections || !connections.length) {
        return handleNavigateAfterChange(createdEntity, saveMode);
      }

      if (propertyId) {
        const data = {
          propertyId,
          entityId: createdEntity.id,
          entityType,
          actions: connections.map((entityUid) => ({
            type: entityUid.entityType,
            entityId: entityUid.entityId,
            action: ModificationType.CREATE,
          })),
        };

        return patchEntityConnections(data)
          .unwrap()
          .then(() => handleNavigateAfterChange(createdEntity, saveMode));
      }
    },
  };
};

export default useManageConnectionAfterCreateOrUpdate;
