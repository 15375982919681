import React, { FC } from 'react';
import { Stack } from '@mui/material';

import {
  DynamicInputCommonFieldProps,
  DynamicInputSingleValueField,
} from '../../../types/types.dynamicContent';
import HIDCounter from '../../../../../../../../../components/HIDCounter';
import DynamicContentSource from './DynamicContentSource';

type DynamicContentMultiSelectProps = DynamicInputSingleValueField<number> & DynamicInputCommonFieldProps<number>;

const DynamicContentCounter: FC<DynamicContentMultiSelectProps> = ({
  title,
  description,
  value,
  source,
  formSettings,
  onValueChange,
}) => {
  // const info = getDynamicContentComponentInfo(title, description);

  const buttonColor = formSettings?.secondaryButtonColor || 'secondary';

  const handleChange = (count: number) => {
    if (onValueChange) {
      onValueChange(count);
    }
  };

  return (
    <Stack>
      <HIDCounter
        buttonColor={buttonColor}
        description={description}
        label={title}
        value={value}
        onChange={handleChange}
      />
      <DynamicContentSource source={source} />
    </Stack>
  );
};
export default DynamicContentCounter;
