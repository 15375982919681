import React, {
  FC,
} from 'react';
import {
  Grid,
  Stack,
  ToggleButton,
  Typography,
} from '@mui/material';

import {
  DynamicInputCommonFieldProps,
  DynamicSliderField,
} from '../../../types/types.dynamicContent';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import DynamicContentSource from './DynamicContentSource';

type DynamicContentSliderProps = DynamicInputCommonFieldProps<string> & DynamicSliderField;

const DynamicContentSlider: FC<DynamicContentSliderProps> = ({
  title,
  description,
  options,
  value,
  source,
  onValueChange,
}) => {
  const handleSelect = (value: string) => {
    if (onValueChange) {
      onValueChange(value);
    }
  };

  return (
    <Stack spacing={1} width="fit-content">
      <HIDInfo
        isMarkdown
        description={description}
        label={title}
        labelVariant="subtitle1"
      />
      <Grid container spacing={1}>
        {options.map((option) => (
          <Grid item key={option.value}>
            <ToggleButton
              selected={value === option.value}
              style={{
                width: 100,
                height: 100,
                borderRadius: '5px',
                margin: 0,
              }}
              value={option}
              onChange={() => handleSelect(option.value)}
            >
              <Stack alignItems="center">
                <Typography variant="body2">{option.label}</Typography>
                <img height={48} src={option.iconUrl} width={48} />
              </Stack>
            </ToggleButton>
          </Grid>
        ))}
      </Grid>
      <DynamicContentSource source={source} />
    </Stack>
  );
};

export default DynamicContentSlider;
