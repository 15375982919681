import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { match } from 'ts-pattern';

import useGetIsUserSfSellerFromOnboarding from '../../../hooks/useGetIsUserSfSellerFromOnboarding';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { getPropertyProgressPercent } from '../../../modules/PropertyProgress/utils/utils.propertyProgress';
import { useGetPropertyProgressQuery } from '../../../modules/PropertyProgress/api/api.propertyProgress';
import { HomeIcon } from '../../../../../components/icons/Icons';
import HIDClickable from '../../../../../components/HIDClickable';
import HomeWithProgress from '../../../../../components/icons/HomeWithProgress';
import HIDTypography from '../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../constants/borderRadius';
import { FCC } from '../../../../../types/common';
import HIDTooltip from '../../../../../components/buttons/HIDTooltip';

type PropertyProgressMenuItemProps = {
  open: boolean;
  onClick?: (open: boolean) => void;
};

const PropertyProgressMenuItem: FCC<PropertyProgressMenuItemProps> = ({
  open,
  sx,
  style,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'menu', 'property_progress']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: propertyProgress } = useGetPropertyProgressQuery(propertyId ? { propertyId } : skipToken);
  const propertyProgressPercent = getPropertyProgressPercent(propertyProgress?.progress);
  const { data: isUserSfSellerFromOnboarding } = useGetIsUserSfSellerFromOnboarding();

  const progress = isUserSfSellerFromOnboarding ? undefined : propertyProgressPercent;

  const description = useMemo(
    () => isUserSfSellerFromOnboarding
      ? t('property_progress:property_progress_property_progress_box_seller_description')
      : match(progress || 0)
        .when(
          (progress) => progress <= 40,
          () => t('property_progress:property_progress_property_progress_box_lower_40_description'),
        )
        .when(
          (progress) => progress > 40 && progress <= 80,
          () => t('property_progress:property_progress_property_progress_box_between_41_and_80_description'),
        )
        .when(
          (progress) => progress > 80 && progress <= 90,
          () => t('property_progress:property_progress_property_progress_box_between_81_and_90_description'),
        )
        .otherwise(
          () => t('property_progress:property_progress_property_progress_box_higher_90_description'),
        ),
    [progress, isUserSfSellerFromOnboarding],
  );

  return (
    <HIDClickable
      style={style}
      sx={{
        borderRadius: BorderRadius.sm,
        padding: 1,
        backgroundColor: theme.palette.primary.lighter,
        '&:hover': { backgroundColor: theme.palette.primary.lightest },
        '&:active': { backgroundColor: theme.palette.primary.lighter },
        ...sx,
      }}
      onClick={onClick ? () => onClick(!open) : undefined}
    >
      <Stack direction="row" flex={1} gap={1.5}>
        <HIDTooltip
          title={open ? undefined : t('menu:property_overview')}
          titlePlacement="right"
        >
          <Stack>
            {
              progress
                ? <HomeWithProgress progress={progress} />
                : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: 40, height: 40 }}
                  >
                    <HomeIcon />
                  </Stack>
                )
            }
          </Stack>
        </HIDTooltip>
        {open && (
          <Stack direction="column" flex={1}>
            <Stack direction="row" justifyContent="space-between">
              <HIDTypography sx={{ lineHeight: '20px' }} variant="subtitle2">
                {t('menu:property_overview')}
              </HIDTypography>
              {progress !== undefined && (
                <HIDTypography
                  sx={{
                    alignSelf: 'center',
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '2px',
                    color: theme.palette.common.white,
                    padding: theme.spacing(0, 0.5),
                    whiteSpace: 'nowrap',
                    lineHeight: '20px',
                  }}
                  variant="body2"
                >
                  {`${progress}% ${t('common:done')}`}
                </HIDTypography>
              )}
            </Stack>
            <HIDTypography variant="body2">
              {description}
            </HIDTypography>
          </Stack>
        )}
      </Stack>
    </HIDClickable>
  );
};

export default PropertyProgressMenuItem;
