import React, { FC } from 'react';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
} from 'recharts';
import * as R from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import { EconomyAmountHistoryItem } from '@house-id/houseid-types/dist/finances/finances';
import { useNavigate } from 'react-router';
import { useAuthState } from 'react-firebase-hooks/auth';
import { EntityType } from '@house-id/houseid-types/dist/entityType';

import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import {
  formatChartNumbers,
  formatMoney,
} from '../../../../../utils/string';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../utils/date';
import {
  fillInGaps,
  getBoundaries,
  useGetFormatMonthDateLabel,
} from '../utils/formatDates';
import {
  Discount,
  SmartphoneValue,
} from '../../../../../components/icons/Icons';
import { hidSpacing } from '../../../../../utils/number';
import HIDButton from '../../../../../components/buttons/HIDButton';
import {
  HomeWidgetType,
  useGetWidgetHeight,
} from '../utils/useGetWidgetHeight';
import { getNoWrapStyle } from '../../../../../utils/style';
import useGetChartLabelFontSize from '../utils/useGetChartLabelFontSize';
import ChartLegend from '../../../../../components/charts/ChartLegend';
import { getValuationOverviewPath } from '../../../modules/Finances/modules/Valuation/navigation.valuation';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import { getActiveAuth } from '../../../../../external-services/firebase';
import { useGetTokenDataQuery } from '../../../../Auth/api/api.user';
import useDialog from '../../../../../hooks/useDialog';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { isTestEnv } from '../../../../../utils/env';
import { useGetLatestIndicationValuationQuery } from '../../../modules/Finances/modules/Valuation/api/api.valuation';

const MONTH_COUNT = 6;

const divideForLastMonth = (history: Array<EconomyAmountHistoryItem>) => history
  .map((item, index) => index === history.length - 1 ? { ...item, amount: 0, lastMonthAmount: item.amount } : item);

const mapValuationToHistoryItem = ({ month, valuation }: { month: string, valuation: number }) => ({
  month: new Date(month).getMonth() + 1,
  year: new Date(month).getFullYear(),
  amount: valuation,
});

const ValuationEmptyState: FC<{ showActions?: boolean }> = ({ showActions }) => {
  const { t } = useTranslation(['home']);
  const navigate = useNavigate();
  const theme = useTheme();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

  const [currentUser] = useAuthState(getActiveAuth());
  const { data: tokenData } = useGetTokenDataQuery({}, { skip: !currentUser });

  const showValuation = isTestEnv || tokenData?.isAdmin;

  const handleNavigateToIndicationValuation = () => showValuation
    ? navigate(getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId, showIndicationValuationDialog: true }))
    : openMobileAppDialog({ unavailableFeature: EntityType.VALUATION });

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        height: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[300],
        borderRadius: '5px',
        padding: theme.spacing(3, 2, 2, 2),
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: theme.spacing(8),
          height: theme.spacing(8),
          borderRadius: theme.spacing(4),
          backgroundColor: theme.palette.primary.lighter,
          position: 'relative',
        }}
      >
        <SmartphoneValue
          iconColor={theme.palette.primary.main}
          size={hidSpacing(4)}
        />
        <div
          style={{
            position: 'absolute',
            top: -hidSpacing(0.25),
            right: -hidSpacing(0.25),
          }}
        >
          <Discount iconColor={theme.palette.primary.light} />
        </div>
      </Stack>
      <Typography
        sx={{
          textAlign: 'center',
          ...getNoWrapStyle(2),
        }}
        variant="h6"
      >
        {t('home:home_subscribe_to_digital_value_indication')}
      </Typography>
      <Typography
        style={{ marginTop: theme.spacing(1) }}
        sx={{
          textAlign: 'center',
          maxWidth: 300,
        }}
        variant="body1"
      >
        {t('home:home_value_indication_based_on_sales_in_your_area')}
      </Typography>
      {showActions && (
        <HIDButton onClick={handleNavigateToIndicationValuation}>
          {t('home:home_start_digital_value_indication')}
        </HIDButton>
      )}
    </Stack>
  );
};

type ValuationWidgetChartItem = {
  amount: number;
  lastMonthAmount?: number;
  month: number;
  year: number;
};

const ValuationWidgetChart: FC<{ data: Array<ValuationWidgetChartItem> }> = ({
  data,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['home']);

  const chartLabelFontSize = useGetChartLabelFontSize();
  const formatDateLabel = useGetFormatMonthDateLabel();

  const renderLegend = ({ payload }: { payload: Array<{ id: string, value: string, color: string }> }) => (
    <Stack
      alignItems="center"
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      spacing={2}
      sx={{ marginTop: 1.25 }}
    >
      <ChartLegend payload={payload} />
      <Typography
        style={{
          marginLeft: 'auto',
        }}
        sx={{
          color: theme.palette.grey[500],
          maxWidth: 300,
          textAlign: 'center',
          lineHeight: '16px',
        }}
        variant="body2"
      >
        {t('home:home_value_indication_based_on_sales_in_your_area')}
      </Typography>
    </Stack>
  );

  return (
    <ResponsiveContainer
      height="100%"
      minHeight={170}
      width="100%"
    >
      <BarChart
        data={data}
        margin={{
          top: 16,
        }}
      >
        <XAxis
          dataKey="month"
          fontSize={chartLabelFontSize}
          stroke={theme.palette.common.transparent}
          tick={{ fill: theme.palette.grey[500] }}
          tickFormatter={formatDateLabel}
          tickLine={{ stroke: theme.palette.common.transparent }}
        />
        <Legend
          align="left"
          content={renderLegend as unknown as undefined}
        />
        <Bar
          dataKey="amount"
          fill={theme.palette.primary.main}
          isAnimationActive={false}
          name={t('home:home_earlier')}
          stackId="stack"
        >
          <LabelList
            dataKey="amount"
            fontSize={chartLabelFontSize}
            formatter={formatChartNumbers}
            position="top"
          />
        </Bar>
        <Bar
          dataKey="lastMonthAmount"
          fill={theme.palette.warning.main}
          isAnimationActive={false}
          name={t('home:home_last_month')}
          stackId="stack"
        >
          <LabelList
            dataKey="lastMonthAmount"
            fontSize={chartLabelFontSize}
            formatter={formatChartNumbers}
            position="top"
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const ValuationWidget: FC<{ showActions?: boolean }> = ({ showActions }) => {
  const { t } = useTranslation(['home']);
  const theme = useTheme();

  const { data: propertyId } = useGetCurrentPropertyId();
  const { data: valuationSummary, isLoading } = useGetLatestIndicationValuationQuery(propertyId ? { propertyId } : skipToken);

  const history = valuationSummary?.history || [];
  const expectation = valuationSummary?.valuation?.expectation || 0;
  const difference = valuationSummary?.valuationChange;
  const differenceValuationDate = valuationSummary?.comparisonValuation?.createdAt;

  const { high = 0, low = 0 } = valuationSummary?.valuation?.range || {};

  const boundaries = getBoundaries(MONTH_COUNT, true);

  const prepareData = R.compose(divideForLastMonth, fillInGaps(boundaries, MONTH_COUNT), R.reverse<EconomyAmountHistoryItem>);
  const chartData = prepareData(history.map(mapValuationToHistoryItem));

  const widgetHeight = useGetWidgetHeight(HomeWidgetType.VALUATION);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2.5),
        height: widgetHeight || 'unset',
      }}
    >
      <Stack spacing={1} sx={{ height: '100%' }}>
        <Typography variant="h5">
          {t('home:home_digital_value_indication')}
        </Typography>
        {
          !valuationSummary && !isLoading
            ? <ValuationEmptyState showActions={showActions} />
            : (
              <>
                <Stack spacing={0.5}>
                  <Typography variant="h3">
                    {`≈ ${formatMoney(expectation, 'never')}`}
                  </Typography>
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    <Typography variant="body2">
                      {t('home:home_highest')}
                    </Typography>
                    <Typography variant="subtitle2">
                      {formatMoney(high, 'never')}
                    </Typography>
                    <Typography variant="body2">
                      {t('home:home_lowest')}
                    </Typography>
                    <Typography variant="subtitle2">
                      {formatMoney(low, 'never')}
                    </Typography>
                  </Stack>
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    {
                      difference
                        ? (
                          <Typography
                            sx={{
                              color: difference < 0 ? theme.palette.error.main : theme.palette.success.main,
                            }}
                            variant="subtitle1"
                          >
                            {difference < 0 ? '' : '+'} {formatMoney(difference)}
                          </Typography>
                        )
                        : (
                          <Typography>&nbsp;</Typography>
                        )
                    }
                    {differenceValuationDate !== undefined && (
                      <Typography variant="body2">
                        {`(${formatDate(new Date(differenceValuationDate), DateTimeFormats.MONTH_AND_YEAR)})`}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <ValuationWidgetChart data={chartData} />
              </>
            )
        }
      </Stack>
    </Card>
  );
};

export default ValuationWidget;
