import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import HIDTypography from '../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../utils/string';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import FinanceIcon from '../../../components/FinanceIcon';
import DocMoney from '../../../../../../../components/icons/DocMoney';
import { useGetPropertyFinanceSaleSummaryQuery } from '../../../api/api.propertyFinance';

type LatestValuationInfoProps = {
  showIcon?: boolean;
};

const DeclarationInfo: FC<LatestValuationInfoProps> = ({
  showIcon = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['finances', 'declarations']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: saleSummary,
    isLoading,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);
  const taxReductionDueToDeductions = saleSummary?.taxCalculation.taxReductionDueToDeductions || 0;
  const balance = saleSummary?.resultSummary?.balance || 0;

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      gap={2}
      justifyContent="flex-start"
    >
      {showIcon && (
        <FinanceIcon
          Icon={DocMoney}
          iconBackgroundColor={theme.palette.warning.lighter}
          iconColor={theme.palette.warning.dark}
        />
      )}
      <Stack alignItems="flex-start" gap={0.5}>
        <HIDTypography variant="h5">
          {t('declarations:declarations_declaration_title')}
        </HIDTypography>
        <HIDTypography
          isLoading={isLoading}
          skeletonWidth={140}
          variant="h4"
        >
          {formatMoney(balance || 0, 'never')}
        </HIDTypography>
        <HIDTypography variant="body2">
          <ReactMarkdown components={{ p: 'span' }}>
            {t(
              'declarations:declarations_declaration_summary_deduction_reduction',
              {
                amount: formatMoney(taxReductionDueToDeductions || 0, 'never'),
              },
            )}
          </ReactMarkdown>
        </HIDTypography>
      </Stack>
    </Stack>
  );
};

export default DeclarationInfo;
