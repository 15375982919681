import {
  Stack,
  SxProps,
  Theme,
} from '@mui/system';
import React from 'react';

import { FCC } from '../../../../../../../../../types/common';
import HIDInfo from '../../../../../../../../../components/HIDInfo';

type DeclarationCalculationsSectionProps = {
  title?: string;
  description?: string;
  SubtitleComponent?: React.ReactNode;
  loading?: boolean;
  sx?: SxProps<Theme>;
};

const DeclarationCalculationsSection: FCC<DeclarationCalculationsSectionProps> = ({
  title,
  SubtitleComponent,
  loading,
  children,
  sx,
}) => (
  <Stack
    gap={2}
    sx={sx}
  >
    {(title || SubtitleComponent) && (
      <Stack>
        {title && (
          <HIDInfo
            isMarkdown
            isLoading={loading}
            label={title}
            labelVariant="h5"
          >
            {title}
          </HIDInfo>
        )}
        {SubtitleComponent}
      </Stack>
    )}
    <Stack gap={2}>
      {children}
    </Stack>
  </Stack>
);

export default DeclarationCalculationsSection;
