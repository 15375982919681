import React, { ReactNode } from 'react';
import {
  Stack,
  StackProps,
  SvgIconProps,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Info } from '@mui/icons-material';

import {
  FCC,
} from '../types/common';
import useBreakPointsSizes from '../hooks/useBreakpointsSizes';

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.info.lightest,
  padding: theme.spacing(2.5),
  borderRadius: '5px',
  border: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[300],
}));

type InfoBoxProps = {
  message: string;
  iconColor?: SvgIconProps['color'];
  description?: string;
  RightComponent?: ReactNode;
  onClick?: StackProps['onClick'];
};

const InfoBox: FCC<InfoBoxProps> = ({
  message,
  description,
  iconColor: iconColorProps,
  sx,
  style,
  RightComponent,
  onClick,
}) => {
  const theme = useTheme();

  const iconColor = iconColorProps || 'info';

  const { isDownSm } = useBreakPointsSizes();

  return (
    <StyledStack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      style={style}
      sx={sx}
      onClick={onClick}
    >
      <Stack
        alignItems="center"
        flex={1}
        flexDirection="row"
      >
        <Info color={iconColor} style={{ marginRight: theme.spacing(1) }} />
        <Stack>
          <ReactMarkdown
            components={{
              p: (props) => (
                <Typography
                  style={{ margin: 0, marginRight: theme.spacing(1) }}
                  variant={isDownSm ? 'subtitle2' : 'subtitle1'}
                >
                  {props.children}
                </Typography>),
            }}
          >
            {message}
          </ReactMarkdown>
          {Boolean(description) && (
            <Typography
              style={{ margin: 0, marginRight: theme.spacing(1) }}
              variant={isDownSm ? 'body2' : 'body1'}
            >
              {description}
            </Typography>
          )}
        </Stack>
      </Stack>
      {RightComponent}
    </StyledStack>
  );
};
export default InfoBox;
