import React, {
  FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  Deduction,
  AmountSpecificationType,
  DeductionTypeReceipt,
} from '@house-id/houseid-types/dist/deductions';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { FormikProps } from 'formik';
import ReactMarkdown from 'react-markdown';

import HIDTypography from '../../../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../../../constants/borderRadius';
import useGetCurrentPropertyId from '../../../../../../../../../hooks/useGetCurrentPropertyId';
import { useGetDeductionsTypesQuery } from '../../../api/api.deductions';
import { CreateEditDeductionValues } from '../types';
import HIDInfo from '../../../../../../../../../../../components/HIDInfo';
import HIDCurrencyField from '../../../../../../../../../../../components/HIDCurrencyField';
import DeclarationTableRow from '../../../../../components/DeclarationTableRow';
import ReceiptsMissedAmount from './ReceiptsMissedAmount';
import DeductionCardContainer from './DeductionCardContainer';
import DeductableReceiptsPicker from './DeductableReceiptsPicker';
import HIDSection from '../../../../../../../../../../../components/HIDSection';

type RenovationDeductionReceiptsAndAmountsProps = {
  type: string | undefined;
  formik: FormikProps<CreateEditDeductionValues>;
  deduction: Deduction | undefined;
  deductionTypeReceipts: Array<DeductionTypeReceipt>;
  onValueChanged: (field: string, value: any) => void;
  onConnectedReceiptsChange: (receipts: string[]) => void;
  onBeforeAdd?: () => void;
};

const RenovationDeductionReceiptsAndAmounts: FC<RenovationDeductionReceiptsAndAmountsProps> = ({
  type,
  formik,
  deduction,
  deductionTypeReceipts,
  onValueChanged,
  onConnectedReceiptsChange,
  onBeforeAdd,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['deductions', 'common']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: deductionTypes,
  } = useGetDeductionsTypesQuery(propertyId ? { propertyId } : skipToken);

  const currentType = deductionTypes?.find((deductionType) => deductionType.id === type || deductionType.id === formik.values.type);
  const hasType = Boolean(formik.values.type);
  const needsReceipts = Boolean(currentType?.needReceipts);

  const showReceiptsSummary = hasType && deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR
    ? needsReceipts
    && Object.values(deduction.yearSummary).some((yearSummary) => yearSummary.totalAmount || yearSummary.totalReceiptAmount)
    : needsReceipts
    && Boolean(deduction && (deduction.summary.totalAmount || deduction.summary.totalReceiptAmount));

  const typeName = currentType?.name?.toLowerCase();
  const costPerDeductionTypeName = t('deductions:create_edit_deductions_cost_per_type', { typeName });

  const handleReceiptsChange = (receipt: Record<string, string[]>) => {
    const receiptIds = receipt[EntityType.RECEIPT] || [];
    onConnectedReceiptsChange(receiptIds);
  };

  return (
    <DeductionCardContainer
      sx={
        !showReceiptsSummary ? { paddingY: theme.spacing(2.5) } : undefined
      }
    >
      <Stack
        gap={theme.spacing(2)}
        sx={[{ paddingX: theme.spacing(2.5) }, showReceiptsSummary && { paddingTop: theme.spacing(2.5) }]}
      >
        <HIDInfo
          isMarkdown
          label={t('deductions:deduction_how_much_it_cost')}
          labelVariant="subtitle1"
        />
        <Stack>
          <HIDTypography>
            {currentType?.amountDescription || t('deductions:total_cost_of_action_description')}
          </HIDTypography>
          {Boolean(deductionTypeReceipts.length) && (
            <HIDTypography>
              {t('deductions:deduction_sales_expenses_retrieve_receipts', { typeName })}
            </HIDTypography>
          )}
        </Stack>
        <Stack gap={1}>
          <HIDTypography variant="subtitle1">
            {t('deductions:total_cost_of_action')}
          </HIDTypography>
          <Stack>
            <Stack gap={theme.spacing(1.5)}>
              {formik.values.amounts?.length
                ? formik.values.amounts
                  .filter(({ year }) => year)
                  .map(({ year, number }, index) => (
                    <HIDCurrencyField
                      required
                      backgroundColor={theme.palette.skattio.lightest}
                      color="skattio"
                      inputProps={{ min: 0 }}
                      key={year}
                      label={t('deductions:deduction_cost_for_year', { year })}
                      value={number}
                      onChange={({ target: { value } }) => onValueChanged(`amounts[${index}]`, { ...formik.values.amounts[index], number: value })}
                    />
                  ))
                : null}
            </Stack>
            {currentType?.amountHelpText
              ? (
                <HIDSection sx={{ backgroundColor: theme.palette.grey[200], padding: theme.spacing(1) }}>
                  <HIDTypography variant="body1">
                    <ReactMarkdown components={{ p: 'div' }}>
                      {currentType.amountHelpText}
                    </ReactMarkdown>
                  </HIDTypography>
                </HIDSection>
              ) : undefined}
          </Stack>
        </Stack>
        {currentType?.needReceipts && (
          <Stack gap={theme.spacing(2)}>
            <Stack gap={theme.spacing(0.5)}>
              <HIDTypography variant="subtitle1">
                {t('deductions:deduction_receipts_associated_with_action')}
              </HIDTypography>
              <HIDInfo
                isMarkdown
                description={t('deductions:deduction_sales_expenses_receipts_associated_with_action_description_modal')}
                label={t('deductions:create_edit_deductions_receipts_connection_section_description')}
                labelVariant="body1"
              />
            </Stack>
            <DeductableReceiptsPicker
              deductionCategoryId={deduction?.categoryId}
              deductionId={deduction?.id}
              deductionTypeId={deduction?.type}
              receiptConnections={deductionTypeReceipts}
              receiptInitialValues={{
                name: formik.values.name,
              }}
              selectedItems={formik.values.receiptConnections[EntityType.RECEIPT] || []}
              onBeforeAddNew={onBeforeAdd}
              onSelect={(items) => handleReceiptsChange({ [EntityType.RECEIPT]: items })}
            />
          </Stack>
        )}
      </Stack>
      {showReceiptsSummary && (
        <Stack
          gap={1.5}
          padding={2.5}
          style={{
            borderBottomLeftRadius: BorderRadius.md,
            borderBottomRightRadius: BorderRadius.md,
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Stack gap={1}>
            <HIDTypography variant="subtitle1">
              {costPerDeductionTypeName}
            </HIDTypography>
            {deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR
              && (
                Object.entries(deduction?.yearSummary).map(([year, summary]) => (
                  <DeclarationTableRow
                    item={{
                      id: year,
                      label: `${t('common:cost')} ${year}`,
                      value: Number(summary.totalAmount),
                      withDecimals: false,
                    }}
                    key={year}
                  />
                ))
              )}
          </Stack>
          <Stack gap={1}>
            <HIDTypography variant="subtitle1">
              {t('deductions:create_edit_deductions_connected_receipts_amount')}
            </HIDTypography>
            {deduction?.amountSpecificationType === AmountSpecificationType.PER_YEAR
              && (
                Object.entries(deduction?.yearSummary).map(([year, summary]) => (
                  <DeclarationTableRow
                    item={{
                      id: year,
                      label: `${t('common:cost')} ${year}`,
                      value: Number(summary.totalReceiptAmount),
                      withDecimals: false,
                    }}
                    key={year}
                  />
                ))
              )}
          </Stack>
          <ReceiptsMissedAmount deduction={deduction} />
        </Stack>
      )}
    </DeductionCardContainer>
  );
};

export default RenovationDeductionReceiptsAndAmounts;
