import React, { FC } from 'react';
import {
  Stack,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router';

import HIDSection from '../../../../../../../components/HIDSection';
import HIDTypography from '../../../../../../../components/HIDTypography';
import DocMoney from '../../../../../../../components/icons/DocMoney';
import HIDButton from '../../../../../../../components/buttons/HIDButton';
import { useGetPropertyFinanceSaleSummaryQuery } from '../../../api/api.propertyFinance';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { formatMoney } from '../../../../../../../utils/string';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import { getDeclarationCalculationPath } from '../../Declaration/navigation.declaration';

const ProfitOnSaleCard: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    data: financeSaleSummary,
  } = useGetPropertyFinanceSaleSummaryQuery(propertyId ? { propertyId } : skipToken);

  const declaration = financeSaleSummary?.resultSummary?.balance || 0;

  const handleAdd = () => {
    if (propertyId) {
      navigate(getDeclarationCalculationPath({ propertyId }));
    }
  };

  const handleManage = () => {
    if (propertyId) {
      navigate(getDeclarationCalculationPath({ propertyId }));
    }
  };

  const { isDownXl, isDownLg, isDownSm } = useBreakPointsSizes();

  const SectionIcon = (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        p: 2,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.lighter,
      }}
    >
      <DocMoney iconColor={theme.palette.primary.main} size={32} />
    </Stack>
  );

  const Price = (
    <Stack direction="column" gap={0.5}>
      <HIDTypography>
        {t('finances:profit_on_sale')}
      </HIDTypography>
      <HIDTypography variant="h5">
        {formatMoney(declaration, 'never')}
      </HIDTypography>
    </Stack>
  );

  const ManageButton = isDownXl && !isDownLg
    ? (
      <HIDIconButton
        Icon={Edit}
        color="white"
        title={t('finances:sales_calculation')}
        onClick={handleManage}
      />
    )
    : (
      <HIDButton
        color="secondary"
        sx={{
          alignSelf: isDownSm ? undefined : 'center',
          width: isDownSm ? 180 : undefined,
        }}
        onClick={handleManage}
      >
        {t('finances:sales_calculation')}
      </HIDButton>
    );

  const AddButton = (
    <HIDButton color="primary" sx={{ minWidth: isDownSm ? 200 : undefined }} onClick={handleAdd}>
      {t('finances:get_started')}
    </HIDButton>
  );

  const EmptyStateText = (
    <HIDTypography style={{ whiteSpace: 'pre-wrap' }} variant="h6">
      {t('finances:find_out_your_profit_on_a_sale')}
    </HIDTypography>
  );

  const EmptyState = isDownLg
    ? (
      <Stack
        alignItems="center"
        direction="column"
        sx={{ gap: 2.5 }}
      >
        {SectionIcon}
        {EmptyStateText}
        {AddButton}
      </Stack>
    )
    : (
      <Stack alignItems="center" direction="row" sx={{ gap: 2.5 }}>
        {SectionIcon}
        {EmptyStateText}
        <Stack sx={{ flexShrink: 0, marginLeft: 'auto' }}>
          {AddButton}
        </Stack>
      </Stack>
    );

  const FilledState = isDownLg
    ? (
      <Stack
        direction={isDownSm ? 'column' : 'row'}
        justifyContent="space-between"
        sx={{ gap: 2.5 }}
      >
        <Stack alignItems="center" direction="row" sx={{ gap: 2.5 }}>
          {SectionIcon}
          <Stack direction="column">
            {Price}
          </Stack>
        </Stack>
        <Stack sx={{ gap: 2, alignSelf: 'center', minWidth: 0 }}>
          {ManageButton}
        </Stack>
      </Stack>
    )
    : (
      <Stack alignItems="center" direction="row" sx={{ gap: 2.5 }}>
        {SectionIcon}
        <Stack direction="column">
          {Price}
        </Stack>
        <Stack sx={{ marginLeft: 'auto' }}>
          {ManageButton}
        </Stack>
      </Stack>
    );

  return (
    <HIDSection>
      {
        declaration
          ? FilledState
          : EmptyState
      }
    </HIDSection>
  );
};

export default ProfitOnSaleCard;
