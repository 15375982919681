import queryString from 'query-string';
import { SubscriptionPlanId } from '@house-id/houseid-types/dist/subscriptionPlans';
import { UtmParameters } from '@house-id/houseid-types/dist/auth';

import {
  HouseIdUserToken,
  UserTokenData,
} from '../types/types.auth';
import {
  getAdminData,
  getBankIdClaim,
  getMemberships,
  getTokenProperties,
  getUserId,
} from './token';

const ADMIN = 'is-admin';
const USER_PROPERTIES = 'user-properties';
const BANK_ID_CLAIM = 'bank-id-claim';
const SIGN_IN_PROVIDER = 'sign-in-provider';
const PROJECT = 'project';
const SUBSCRIPTION_PLAN = 'subscription-plan';
const UTM_PARAMS = 'utm-params';
const FIREBASE_UID = 'firebase-uid';

const isMembershipValid = (start: Date, end: Date) => {
  const currentDate = new Date();

  return start <= currentDate && end >= currentDate;
};

const getCurrentSubscriptionPlan = (decodedToken: HouseIdUserToken) => {
  const memberships = getMemberships(decodedToken).map((m) => ({
    subscriptionPlan: m.type,
    startTime: new Date(m.startTime * 1000),
    expirationTime: new Date(m.expirationTime * 1000),
  }));

  const activeMemberships = memberships.filter((m) => isMembershipValid(m.startTime, m.expirationTime));
  if (!activeMemberships.length) {
    return SubscriptionPlanId.FREE;
  }
  const plusMembership = activeMemberships.find((m) => m.subscriptionPlan === SubscriptionPlanId.PLUS);
  if (plusMembership) {
    return SubscriptionPlanId.PLUS;
  }
  const baseMembership = activeMemberships.find((m) => m.subscriptionPlan === SubscriptionPlanId.BASE);
  return baseMembership ? SubscriptionPlanId.BASE : SubscriptionPlanId.FREE;
};

export const getTokenData = (decodedToken: HouseIdUserToken) => {
  const adminData = getAdminData(decodedToken);
  const bankIdClaim = getBankIdClaim(decodedToken);

  return {
    isAdmin: Boolean(adminData?.personalNumber),
    admin: adminData,
    hasBankIdClaim: Boolean(bankIdClaim?.personalNumber),
    bankIdClaim,
    properties: Array(...getTokenProperties(decodedToken)),
    signInProvider: decodedToken?.firebase?.sign_in_provider,
    project: decodedToken?.aud,
    userId: getUserId(decodedToken),
    subscriptionPlanId: getCurrentSubscriptionPlan(decodedToken),
    email: decodedToken.email,
    name: decodedToken.name,
    partnerId: decodedToken.partnerId,
    firebaseUID: decodedToken.user_id,
    iat: decodedToken.iat,
    firebase: decodedToken.firebase,
    auth_time: decodedToken.auth_time,
    memberships: getMemberships(decodedToken),
  } as UserTokenData;
};

export const getTokenAttributes = (tokenData: UserTokenData, utm?: UtmParameters) => {
  const attributes: Record<string, string> = {};
  attributes[ADMIN] = tokenData.isAdmin?.toString();
  attributes[BANK_ID_CLAIM] = tokenData.hasBankIdClaim.toString();
  attributes[USER_PROPERTIES] = tokenData.properties.join(', ');
  attributes[SIGN_IN_PROVIDER] = tokenData.signInProvider;
  attributes[PROJECT] = tokenData.project;
  attributes[SUBSCRIPTION_PLAN] = tokenData.subscriptionPlanId.toString();
  attributes[UTM_PARAMS] = utm ? queryString.stringify(utm) : '';
  attributes[FIREBASE_UID] = tokenData.firebaseUID;
  return attributes;
};

export const getEmptyTokenAttributes = () => {
  const attributes: Record<string, string | undefined> = {};
  attributes[ADMIN] = undefined;
  attributes[BANK_ID_CLAIM] = undefined;
  attributes[USER_PROPERTIES] = undefined;
  attributes[SIGN_IN_PROVIDER] = undefined;
  attributes[PROJECT] = undefined;
  attributes[SUBSCRIPTION_PLAN] = undefined;
  attributes[UTM_PARAMS] = undefined;
  attributes[FIREBASE_UID] = undefined;

  return attributes;
};
