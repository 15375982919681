import React, {
  FC,
} from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioProps,
  Stack,
  useTheme,
} from '@mui/material';

import {
  DynamicInputCommonFieldProps,
  DynamicRadioButtonListField,
} from '../../../types/types.dynamicContent';
import DynamicContentSource from './DynamicContentSource';
import HIDInfo from '../../../../../../../../../components/HIDInfo';

type DynamicContentRadioButtonListProps = DynamicRadioButtonListField & DynamicInputCommonFieldProps<string>;

const DynamicContentRadioButtonList: FC<DynamicContentRadioButtonListProps> = ({
  title,
  description,
  options,
  value,
  source,
  formSettings,
  onValueChange,
}) => {
  const theme = useTheme();
  const handleSelect = (value: string) => {
    if (onValueChange) {
      onValueChange(value);
    }
  };

  const color = formSettings?.color;

  return (
    <Stack>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          <HIDInfo
            isMarkdown
            description={description}
            label={title}
            labelSx={{ color: theme.palette.common.black }}
          />
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
        >
          {options.map((option) => (
            <FormControlLabel
              control={
                <Radio
                  checked={option.value === value}
                  color={color as RadioProps['color']}
                  onChange={() => handleSelect(option.value)}
                />
              }
              key={option.value}
              label={
                option.iconUrl
                  ? <img src={option.iconUrl} />
                  : option.description
                    ? (
                      <HIDInfo
                        isMarkdown
                        description={option.description}
                        label={option.label}
                        labelSx={{ color: theme.palette.common.black }}
                      />
                    ) : option.label
              }
              value={option.value}
            />
          ))}

        </RadioGroup>
      </FormControl>
      <DynamicContentSource source={source} />
    </Stack>
  );
};

export default DynamicContentRadioButtonList;
