import * as R from 'ramda';

import { getCurrentLocale } from './locale';
import { formatNumber } from './number';

// TODO: use proper currency
export const Currency = {
  code: 'SEK',
  label: 'kr',
};

export const hasDecimal = (num: number) => num !== Math.trunc(num);

export function formatMoney(number: number, decimals?: 'auto' | 'always' | 'never'): string;
export function formatMoney(number: number | undefined | null, decimals?: 'auto' | 'always' | 'never'): string | undefined;

export function formatMoney(number?: number | null, decimals = 'always') {
  const hasDecimalPart = number !== null && number !== undefined && hasDecimal(number);
  const widthDecimalPart = decimals === 'always' || (decimals === 'auto' && hasDecimalPart);

  const formatter = new Intl.NumberFormat(
    getCurrentLocale().code,
    {
      style: 'currency',
      currency: Currency.code,
      minimumFractionDigits: widthDecimalPart ? 2 : 0,
      maximumFractionDigits: widthDecimalPart ? 2 : 0,
    },
  );
  return R.isNil(number) ? number : formatter.format(number);
}

export const EMPTY_VALUE = '–';

export const htmlDecode = (str: string) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.documentElement.textContent || '';
};

export const getBadgeCount = (count: number, max = 99) => count > max ? `${max}+` : count.toString();

export function parseIntNumber(number: string | number | undefined, defaultValue: number): number;
export function parseIntNumber(number: string | number | undefined, defaultValue?: number): number | undefined;
export function parseIntNumber(number: string | number | undefined, defaultValue?: number) {
  if (number === undefined) {
    return defaultValue;
  }

  const parsedValue = Number.parseInt(number?.toString(), 10);
  return Number.isNaN(parsedValue) ? defaultValue : parsedValue;
}

export function parseFloatNumber(number: string | number | undefined, defaultValue: number): number;
export function parseFloatNumber(number: string | number | undefined, defaultValue?: number): number | undefined;
export function parseFloatNumber(number: string | number | undefined, defaultValue?: number) {
  if (number === undefined) {
    return defaultValue;
  }

  const parsedValue = Number.parseFloat(number?.toString().replace(',', '.'));
  return Number.isNaN(parsedValue) ? defaultValue : parsedValue;
}

const getNumberSuffix = (number: number) => R.cond([
  [R.gt(1_000), R.always('')],
  [R.gt(1_000_000), R.always('K')],
  [R.gt(1_000_000_000), R.always('M')],
  [R.T, R.always('B')],
])(number);

const formatDividedNumber = (number: number) => {
  if (number < 1_000) {
    return { rounded: number, suffix: '' };
  }

  const round = (number: number, base: number) => Math.round(number / (base / 10)) / 10;

  let base = 1;
  let nextRounded = round(number, base);
  let rounded = number;

  while (nextRounded >= 1) {
    base *= 1_000;
    rounded = nextRounded;
    nextRounded = round(number, base);
  }

  return {
    rounded,
    suffix: getNumberSuffix(base / 1000),
  };
};

export const formatChartNumbers = (value: number) => {
  const { rounded, suffix } = formatDividedNumber(value);

  const result = rounded < 10
    ? rounded
    : Math.floor(rounded);

  return `${result}${suffix}`;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Number.parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const convertToMb = (bytes: number) => {
  if (bytes === 0) {
    return 0;
  }
  const k = 1024;
  return Number.parseFloat(formatNumber(bytes / (k ** 2), 3));
};

export const getUserName = (user?: { givenName?: string, surname?: string }) =>
  user ? `${user.givenName || ''} ${user.surname || ''}`.trim() : '';

export const getUserNameInitials = (user?: { givenName?: string, surname?: string }) =>
  user && user.givenName?.length ? `${R.head(user.givenName)}${R.head(user.surname || '')}`.toUpperCase().trim() : '';
