import React from 'react';
import {
  Stack,
  Skeleton,
  useTheme,
} from '@mui/material';
import { Article } from '@house-id/houseid-types/dist/article';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { useTranslation } from 'react-i18next';
import { AnalyticsEvent } from '@house-id/houseid-types/dist/analytics';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { FCC } from '../../../../../../../../../types/common';
import HIDImage from '../../../../../../../../../components/image/HIDImage';
import { getNoWrapStyle } from '../../../../../../../../../utils/style';
import useGetOpenUrlWithAnalytics from '../../../../../../../../Analytics/hooks/useGetOpenUrlWithAnalytics';
import HIDLink from '../../../../../../../../../components/HIDLink';

type PostCardProps = {
  article: Article;
  isLoading?: boolean;
};

const DeductionArticle: FCC<PostCardProps> = ({
  article,
  isLoading,
  sx,
}) => {
  const { t } = useTranslation(['common', 'deductions']);
  const theme = useTheme();

  const {
    title,
    ingress: description,
    imageUrl,
  } = article;

  const imageStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    height: 136,
    width: '100%',
  };

  const openUrl = useGetOpenUrlWithAnalytics();

  const handleOpenArticle = (article: Article) => {
    if (article.data.link) {
      openUrl({
        url: article.data.link,
        source: article.source.name,
        hidCategory: EntityType.DEDUCTION,
        event: AnalyticsEvent.CLICK_ARTICLE,
        openInNewTab: false,
      });
    }
  };

  if (isLoading) {
    return (
      <Stack sx={{ gap: theme.spacing(1.5), ...sx }}>
        <Skeleton sx={imageStyle} />
        <Stack style={{ gap: theme.spacing(1) }}>
          <Skeleton style={{
            height: 20, borderRadius: 4, width: '80%',
          }}
          />
          <Skeleton style={{
            height: 16, borderRadius: 4, width: '100%',
          }}
          />
          <Skeleton style={{
            height: 16, borderRadius: 4, width: '60%',
          }}
          />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      sx={{
        padding: 0,
        backgroundColor: theme.palette.common.white,
        borderRadius: 0,
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <Stack
        gap={theme.spacing(1)}
        sx={{
          flex: 1,
          cursor: 'pointer',
        }}
        onClick={() => handleOpenArticle(article)}

      >
        {imageUrl && (
          <HIDImage
            externalUrl={imageUrl}
            style={imageStyle}
          />
        )}
        <Stack style={{ gap: theme.spacing(0.75) }}>
          <HIDTypography sx={getNoWrapStyle(4)}>
            <HIDTypography variant="subtitle1">
              {title}
            </HIDTypography>
            {' \n'}
            <HIDTypography variant="body2">
              {description}
            </HIDTypography>
          </HIDTypography>
        </Stack>
      </Stack>
      <HIDLink
        color="skattio"
        href={article.data.link}
        label={t('common:read_more')}
        sx={{ padding: 0 }}
        target="_self"
        underline="none"
      />
    </Stack>
  );
};

export default DeductionArticle;
