import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { Remove } from '@mui/icons-material';

import DynamicContentSource from './DynamicContentSource';
import HIDInfo from '../../../../../../../../../components/HIDInfo';
import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import {
  DynamicFactsContainer,
  DynamicContainerFieldProps,
  DynamicInputField,
} from '../../../types/types.dynamicContent';
import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import useNavigateFromLink from '../../../../../../../hooks/useNavigateFromLink';

type DynamicContentFactsContainerProps = DynamicFactsContainer & DynamicContainerFieldProps;

const DynamicContentFactsContainer: FC<DynamicContentFactsContainerProps> = ({
  title,
  description,
  components,
  source,
  link,
  addButton,
  renderItem,
}) => {
  const theme = useTheme();

  const handleLinkPress = useNavigateFromLink(link);

  const handleAddLinkPress = useNavigateFromLink(addButton?.link);

  return (
    <Stack>
      <Card
        sx={{
          padding: 2,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: '10px',
          borderColor: theme.palette.grey[300],
          backgroundColor: theme.palette.common.white,
          width: '100%',
        }}
      >
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <HIDInfo
              isMarkdown
              description={description}
              label={title}
              labelVariant="subtitle1"
            />
            {link && (
              <HIDIconButton
                Icon={Remove}
                onClick={handleLinkPress}
              />
            )}
          </Stack>
          {components?.map((inputField: DynamicInputField, index: number) => renderItem(inputField, index))}
          {Boolean(addButton?.title) && (
            <HIDButton
              onClick={handleAddLinkPress}
            >
              {addButton?.title}
            </HIDButton>
          )}
          <DynamicContentSource source={source} />
        </Stack>
      </Card>
    </Stack>
  );
};
export default DynamicContentFactsContainer;
