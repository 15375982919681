import React from 'react';

import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import { FCC } from '../../../../../../../types/common';
import { usePatchPropertyProgressSpecificationMutation } from '../../../api/api.propertyProgress';
import PropertyProgressDynamicContentSection from '../../../components/PropertyProgressDynamicContentSection';
import {
  PropertyProgressCategorySectionProps,
  PropertySpecificationData,
} from '../../../types.propertyProgress';
import useGetIsUserSfSellerFromOnboarding from '../../../../../hooks/useGetIsUserSfSellerFromOnboarding';

type PropertySpecificationSectionProps = PropertyProgressCategorySectionProps<PropertySpecificationData>;

const PropertySpecificationSection: FCC<PropertySpecificationSectionProps> = ({
  section,
}) => {
  const { data: propertyId } = useGetCurrentPropertyId();

  const { data: isUserSfSellerFromOnboarding } = useGetIsUserSfSellerFromOnboarding();

  const [patchPropertySpecification, { isLoading: isPropertySpecificationPatching }] = usePatchPropertyProgressSpecificationMutation();

  const handleUpdate = (difference: Record<string, unknown>) =>
    propertyId ? patchPropertySpecification({ propertyId, data: difference }) : undefined;

  return (
    <PropertyProgressDynamicContentSection
      key={section.id}
      loading={isPropertySpecificationPatching}
      section={isUserSfSellerFromOnboarding ? { ...section, progress: undefined } : section}
      onUpdate={handleUpdate}
    />
  );
};

export default PropertySpecificationSection;
