import React, { FC } from 'react';
import {
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import {
  DynamicDropDownField,
  DynamicInputCommonFieldProps,
} from '../../../types/types.dynamicContent';
import HIDFormSelect, { HIDSelectItem } from '../../../../../../../../../components/HIDFormSelect';
import DynamicContentSource from './DynamicContentSource';
import { HIDFormDatePickerProps } from '../../../../../../../../../components/datePicker/HIDFormDatePicker';

type DynamicContentDropDownProps = DynamicInputCommonFieldProps<string> & DynamicDropDownField;

const DynamicContentDropDown: FC<DynamicContentDropDownProps> = ({
  title,
  mandatory,
  // description,
  options,
  placeholder,
  value,
  readonly,
  source,
  errorMessage,
  formSettings,
  onValueChange,
  onBlur,
}) => {
  // const info = getDynamicContentComponentInfo(title, description);

  const items = options.map((option) => ({
    id: option.value,
    name: option.label,
  } as HIDSelectItem));

  const handleValueChange = (event: SelectChangeEvent) => {
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };

  const helperText = (
    <Stack>
      {errorMessage && (
        <Typography variant="inherit">
          {errorMessage}
        </Typography>
      )}
      <DynamicContentSource source={source} />
    </Stack>
  );

  const { backgroundColor, color } = formSettings || {};

  return (
    <HIDFormSelect
      backgroundColor={backgroundColor}
      color={color as HIDFormDatePickerProps['color']}
      disabled={readonly}
      error={Boolean(errorMessage)}
      helperText={helperText}
      items={items}
      label={title}
      placeholder={placeholder}
      required={mandatory}
      value={value}
      onBlur={onBlur}
      onChange={handleValueChange}
    />
  );
};
export default DynamicContentDropDown;
