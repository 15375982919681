import React, {
  FC,
  useMemo,
} from 'react';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeductionCategory } from '@house-id/houseid-types/dist/deductions';
import {
  FormikProps,
} from 'formik';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import ReactMarkdown from 'react-markdown';

import HIDButton from '../../../../../../../../../components/buttons/HIDButton';
import HIDLink from '../../../../../../../../../components/HIDLink';
import HIDSection from '../../../../../../../../../components/HIDSection';
import HIDCurrencyField from '../../../../../../../../../components/HIDCurrencyField';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import useGetEntityInfo from '../../../../../../Content/hooks/useGetEntityInfo';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import {
  ManagePropertyEstimatedDeductionsValues,
  DeductionCategoryValueType,
} from '../types';
import FinanceSaleSummaryDeductionReceiptCard from './FinanceSaleSummaryDeductionReceiptCard';

type DeclarationCalculationsDeductionCategoryCardProps = {
  deductionCategoryId: DeductionCategory;
  label: string;
  formik: FormikProps<ManagePropertyEstimatedDeductionsValues>
};

const DeclarationCalculationsDeductionCategoryCard: FC<DeclarationCalculationsDeductionCategoryCardProps> = ({
  formik,
  deductionCategoryId,
  label,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'finances', 'declarations', 'deductions']);

  const getEntityInfo = useGetEntityInfo();
  const receiptInfo = getEntityInfo(EntityType.RECEIPT);

  const [openManageDeductionReceiptsDialog] = useDialog(DialogNames.MANAGE_DEDUCTION_RECEIPTS_DIALOG);
  const handleOpenSelectReceipts = (categoryId: DeductionCategory) => {
    openManageDeductionReceiptsDialog({
      deductionCategoryId: categoryId,
      deductionReceipts: formik.values[categoryId].categoryReceipts,
      selectedItems: formik.values[categoryId].receiptIds,
      onSelect: (items: Array<string>) => {
        formik.setFieldValue(categoryId, { ...formik.values[categoryId], receiptIds: items });
      },
    });
  };

  const handleValueType = (categoryId: DeductionCategory, type: DeductionCategoryValueType) => {
    formik.setFieldValue(categoryId, { ...formik.values[categoryId], type });
  };

  const handleAmountChange = (categoryId: DeductionCategory, amount: string) => {
    formik.setFieldValue(categoryId, { ...formik.values[categoryId], amount });
  };

  const getReceiptCountLabel = (categoryId: DeductionCategory) => {
    const count = formik.values[categoryId].categoryReceipts.length;
    return `${count} ${count > 1 ? receiptInfo.namePlural.toLowerCase() : receiptInfo.name.toLowerCase()}`;
  };

  const categoryValues = formik.values[deductionCategoryId];
  const categoryTouched = formik.touched[deductionCategoryId];
  const categoryErrors = formik.errors[deductionCategoryId];

  const selectedDeductionReceipts = useMemo(
    () => categoryValues.categoryReceipts.filter((connection) => categoryValues.receiptIds.includes(connection.receipt.id)),
    [formik.values[deductionCategoryId].receiptIds],
  );

  const handleRemoveReceipt = (receiptId: string) => {
    const updatedReceiptIds = categoryValues.receiptIds.filter((id) => id !== receiptId);
    formik.setFieldValue(deductionCategoryId, { ...categoryValues, receiptIds: updatedReceiptIds });
  };

  const [openInfoDialog] = useDialog(DialogNames.INFO_DIALOG);
  const handleShowReadMore = () => openInfoDialog({
    title: label,
    isMarkdown: true,
    description: categoryValues.description.full,
  });

  return (
    <HIDSection sx={{ gap: 2, padding: 2 }}>
      <HIDTypography variant="h6">
        {label}
      </HIDTypography>
      <Stack direction="row">
        <HIDTypography>
          {formik.values[deductionCategoryId].description.preamble}
          {' '}
          <HIDLink
            label={t('common:read_more')}
            onClick={handleShowReadMore}
          />
          .
        </HIDTypography>
      </Stack>
      {categoryValues.hasReceipts
        ? (
          <Stack>
            <HIDTypography variant="subtitle1">
              {t('deductions:preliminary_deductions_enter_amount')}
            </HIDTypography>
            <RadioGroup
              sx={{ alignItems: 'flex-start' }}
              value={categoryValues.type}
              onChange={(_event, value) => handleValueType(deductionCategoryId, value as DeductionCategoryValueType)}
            >
              <FormControlLabel
                control={<Radio />}
                label={t('deductions:preliminary_deductions_want_to_enter_total_manually')}
                value={DeductionCategoryValueType.SINGLE_VALUE}
              />
              {categoryValues.type === DeductionCategoryValueType.SINGLE_VALUE
                ? (
                  <Stack
                    gap={2}
                    paddingLeft={3.5}
                    width="100%"
                  >
                    <HIDCurrencyField
                      required
                      error={Boolean(categoryTouched?.amount && categoryErrors?.amount)}
                      helperText={(categoryTouched?.amount)
                        ? categoryErrors?.amount
                        : undefined}
                      inputProps={{ min: 0 }}
                      key={deductionCategoryId}
                      label={label}
                      valid={Boolean(formik.touched?.[deductionCategoryId] && !categoryErrors)}
                      value={formik.values[deductionCategoryId].amount}
                      onBlur={formik.handleBlur(deductionCategoryId)}
                      onChange={({ target: { value } }) => handleAmountChange(deductionCategoryId, value)}
                    />
                  </Stack>
                ) : undefined}
              <FormControlLabel
                control={<Radio />}
                label={t('deductions:preliminary_deductions_amount_from_receipts')}
                value={DeductionCategoryValueType.VALUE_WITH_RECEIPTS}
              />
              {categoryValues.type === DeductionCategoryValueType.VALUE_WITH_RECEIPTS
                ? (
                  <Stack
                    gap={2}
                    paddingLeft={3.5}
                    width="100%"
                  >
                    {formik.values[deductionCategoryId].receiptIds.length > 0
                      ? (
                        <HIDSection>
                          <Stack
                            alignItems="center"
                            flexDirection="row"
                            gap={1.5}
                            justifyContent="space-between"
                          >
                            <HIDTypography variant="subtitle1">
                              {t('deductions:preliminary_deductions_connected_to_category', { categoryLabel: label })}
                            </HIDTypography>
                            <HIDLink
                              label={t('deductions:preliminary_deductions_manage_receipts')}
                              onClick={() => handleOpenSelectReceipts(deductionCategoryId)}
                            />
                          </Stack>
                          <Grid
                            container
                            spacing={1.5}
                            sx={{ marginTop: 1.5 }}
                          >
                            {selectedDeductionReceipts.map((connection) => (
                              <Grid
                                item
                                key={connection.receipt.id}
                                xxs={12}
                              >
                                <FinanceSaleSummaryDeductionReceiptCard
                                  deductionReceipt={connection}
                                  onRemove={() => handleRemoveReceipt(connection.receipt.id)}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </HIDSection>
                      )
                      : (
                        <Stack>
                          <Stack
                            gap={1.5}
                            padding={2}
                            sx={{ backgroundColor: theme.palette.grey[200], borderRadius: BorderRadius.md }}
                          >
                            <Stack gap={1}>
                              <HIDTypography>
                                <ReactMarkdown components={{ p: 'div' }}>
                                  {t(
                                    'deductions:preliminary_deductions_connected_receipts',
                                    {
                                      deductionCategory: label.toLowerCase(),
                                      amountWithName: getReceiptCountLabel(deductionCategoryId),
                                    },
                                  )}
                                </ReactMarkdown>
                              </HIDTypography>
                            </Stack>
                            <HIDButton
                              sx={{ alignSelf: 'flex-start' }}
                              title={t('deductions:preliminary_deductions_manage_receipts')}
                              onClick={() => handleOpenSelectReceipts(deductionCategoryId)}
                            />
                          </Stack>
                        </Stack>
                      )}
                    <HIDTypography variant="subtitle2">
                      {t('deductions:preliminary_deductions_additional_amount_from_receipts_enter_amount')}
                    </HIDTypography>
                    <HIDCurrencyField
                      required
                      error={Boolean(formik.touched?.[deductionCategoryId] && categoryErrors)}
                      helperText={(formik.touched?.[deductionCategoryId]?.amount)
                        ? categoryErrors?.amount
                        : undefined}
                      inputProps={{ min: 0 }}
                      key={deductionCategoryId}
                      label={label}
                      valid={Boolean(formik.touched?.[deductionCategoryId] && !categoryErrors)}
                      value={formik.values[deductionCategoryId].amount}
                      onBlur={formik.handleBlur(deductionCategoryId)}
                      onChange={({ target: { value } }) => handleAmountChange(deductionCategoryId, value)}
                    />
                  </Stack>
                )
                : undefined}
            </RadioGroup>
          </Stack>
        ) : (
          <Stack gap={1}>
            <HIDTypography variant="subtitle1">
              {t('deductions:preliminary_deductions_enter_total_amount')}
            </HIDTypography>
            <HIDCurrencyField
              required
              error={Boolean(formik.touched?.[deductionCategoryId] && categoryErrors)}
              helperText={(formik.touched?.[deductionCategoryId]?.amount)
                ? categoryErrors?.amount
                : undefined}
              inputProps={{ min: 0 }}
              key={deductionCategoryId}
              label={label}
              valid={Boolean(formik.touched?.[deductionCategoryId] && !categoryErrors)}
              value={formik.values[deductionCategoryId].amount}
              onBlur={formik.handleBlur(deductionCategoryId)}
              onChange={({ target: { value } }) => handleAmountChange(deductionCategoryId, value)}
            />
          </Stack>
        )}
    </HIDSection>

  );
};

export default DeclarationCalculationsDeductionCategoryCard;
