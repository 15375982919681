import React from 'react';
import { DeductionChatContext } from '@house-id/houseid-types/dist/deductions';
import { AnalyticsEvent } from '@house-id/houseid-types/dist/analytics';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';

import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import useGetLogAnalyticsEvent from '../../../../../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { FCC } from '../../../../../../../../../types/common';
import HIDTypography from '../../../../../../../../../components/HIDTypography';
import HIDLink from '../../../../../../../../../components/HIDLink';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import Robot from '../../../../../../../../../components/icons/Robot';
import CircleIcon from '../../../../../../../../../components/CircleIcon';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';

type DeductionsSupportBoxProps = {
  context: DeductionChatContext;
  title?: string;
  linkText: string;
};

const DeductionsSupportBox: FCC<DeductionsSupportBoxProps> = ({
  context,
  linkText,
  title,
}) => {
  const theme = useTheme();
  const { data: propertyId } = useGetCurrentPropertyId();
  const logEvent = useGetLogAnalyticsEvent();
  const [openSelectDeductionSupportDialog] = useDialog(DialogNames.SELECT_DEDUCTION_SUPPORT_DIALOG);

  const handleOpenSupportWebView = () => {
    if (propertyId) {
      openSelectDeductionSupportDialog({ context });
      logEvent({
        event: AnalyticsEvent.DECLARATION_HELP_OPEN_SUPPORT_CHAT,
        hidCategory: EntityType.DECLARATION,
      });
    }
  };

  return (
    <Card
      style={{
        backgroundColor: theme.palette.common.premiumLight,
        borderRadius: BorderRadius.md,
        padding: theme.spacing(2),
      }}
    >
      <Stack
        flex={1}
        flexDirection="row"
        gap={0.5}
        justifyContent="space-between"
      >
        <Stack>
          {title && (
            <HIDTypography flex={1} variant="subtitle1">
              {title}
            </HIDTypography>
          )}
          <Stack>
            <HIDLink
              color="grey"
              label={linkText}
              linkColor={theme.palette.common.black}
              noWrap={false}
              style={{
                textAlign: 'left',
                width: '100%',
              }}
              onClick={handleOpenSupportWebView}
            />
          </Stack>
        </Stack>
        <HIDIconButton
          Icon={() => (
            <CircleIcon
              Icon={Robot}
              backgroundColor={theme.palette.common.premium}
              iconColor={theme.palette.common.white}
            />
          )}
          onClick={handleOpenSupportWebView}
        />
      </Stack>
    </Card>
  );
};

export default DeductionsSupportBox;
