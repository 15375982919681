import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useField,
  useFormikContext,
} from 'formik';
import { debounce } from 'throttle-debounce';
import { InputBaseComponentProps } from '@mui/material';
import { CreateUpdateBudgetValues } from '@house-id/houseid-types/dist/finances/budgets';

import HIDTextField from '../../../../../../../../../components/HIDTextField';
import { INPUT_FIELDS_DEBOUNCE_TIME } from '../../../../../../../../../constants/layout';
import {
  FCC,
} from '../../../../../../../../../types/common';
import scrollToElementAndFocus from '../utils/scrollToElementAndFocus';
import { ErrorFieldInfo } from '../hooks/useGetFormikErrorFieldPath';
import HIDCurrencyField from '../../../../../../../../../components/HIDCurrencyField';

type DebouncedTextFieldProps = {
  fieldPath: string;
  inputProps?: InputBaseComponentProps;
  type?: 'number' | 'text' | 'currency';
  label: string;
  isRequired?: boolean;
  endAdornment?: React.JSX.Element;
};

const DebouncedTextField: FCC<DebouncedTextFieldProps> = ({
  fieldPath,
  inputProps,
  label,
  isRequired = false,
  type = 'text',
  endAdornment,
}) => {
  const inputFieldRef = useRef<HTMLInputElement | null>(null);

  const formikContext = useFormikContext<CreateUpdateBudgetValues>();
  const errorFieldInfo = formikContext.status as ErrorFieldInfo;

  const fieldType = type === 'currency' ? 'number' : type;

  useEffect(() => {
    if (errorFieldInfo && errorFieldInfo.errorFieldPath === fieldPath) {
      scrollToElementAndFocus(inputFieldRef.current);
    }
  }, [errorFieldInfo, fieldPath]);

  const [field, meta, helpers] = useField<string | number | undefined>(fieldPath);

  const [currentValue, setCurrenValue] = useState<string | number | undefined>(field.value);

  useEffect(() => {
    if (currentValue !== field.value) {
      setCurrenValue(field.value);
    }
  }, [field.value, type]);

  const setDebouncedValue = useMemo(() => debounce(
    INPUT_FIELDS_DEBOUNCE_TIME,
    (value: string | number | undefined) => helpers.setValue(value),
  ), [helpers.setValue, type]);

  const handleChangeNote = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const currentValue = fieldType === 'number' ? value ? Number(value) : undefined : value;
    setCurrenValue(currentValue);
    setDebouncedValue(currentValue);
  };

  const Field = type === 'currency' ? HIDCurrencyField : HIDTextField;

  return (
    <Field
      endAdornment={endAdornment}
      error={Boolean(meta.touched && meta.error)}
      helperText={(meta.touched) ? meta.error : undefined}
      inputProps={inputProps}
      label={label}
      ref={inputFieldRef}
      required={isRequired}
      type={fieldType}
      value={currentValue !== undefined ? String(currentValue) : undefined}
      onBlur={field.onBlur(fieldPath)}
      onChange={handleChangeNote}
    />
  );
};

export default DebouncedTextField;
