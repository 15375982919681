/* eslint-disable */
// @ts-nocheck
import { useEffect } from 'react';

// Track page view
declare global {
  interface Window {
    fbq: any; // or more specific type if needed
    _fbq: any;
  }
}

const useFacebookPixel = () => {
  const pixelId = import.meta.env.VITE_APP_FACEBOOK_PIXEL_CODE;

  useEffect(() => {
    if (!window.fbq) {
      (function (f: typeof window, b: Document, e: string, v: string, n: any, t: HTMLScriptElement, s: HTMLElement) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode?.insertBefore(t, s);
      }(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      ));

      window.fbq('init', pixelId);
    }

    window.fbq('track', 'PageView');
  }, [pixelId]);
};

export default useFacebookPixel;
