import React from 'react';
import { Grid } from '@mui/material';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import HIDCurrencyField from '../../../../../../../../../components/HIDCurrencyField';
import { FCC } from '../../../../../../../../../types/common';
import { ReceiptFormFields } from '../types';

type ManualAmountProps = {
  formik: FormikProps<ReceiptFormFields>;
  touched?: boolean;
  amountRef?: React.MutableRefObject<HTMLInputElement | undefined>;
};

const ManualAmount: FCC<ManualAmountProps> = ({ formik, amountRef, sx }) => {
  const { t } = useTranslation(['receipts']);

  return (
    <Grid
      container
      columnSpacing={2.5}
      sx={sx}
    >
      <Grid
        item
        sm={6}
        xs={8}
        xxs={12}
      >
        <HIDCurrencyField
          required
          error={Boolean(formik.touched.amount && formik.errors.amount)}
          helperText={(formik.touched.amount) ? formik.errors.amount : undefined}
          id="amount"
          inputProps={{ min: 0 }}
          label={t('receipts:receipts_price_label')}
          ref={amountRef}
          valid={Boolean(formik.touched.amount && !formik.errors.amount)}
          value={formik.values.amount?.toString() || ''}
          onBlur={formik.handleBlur('amount')}
          onChange={({ target: { value } }) => formik.setFieldValue('amount', value)}
        />
      </Grid>
    </Grid>
  );
};

export default ManualAmount;
