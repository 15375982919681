import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import * as R from 'ramda';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { HIDEntityId } from '@house-id/houseid-types/dist/common';

import { FCC } from '../../../../../types/common';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import { useGetEntityConnectionsQuery } from '../api/api.content';
import ConnectionsList from './ConnectionsList';
import getEntityPossiblyConnectionTypes from '../utils/entityPossiblyConnectionTypes';

type ContentConnectionsProps = {
  entity: Required<HIDEntityId>;
  entityType: EntityType,
};

const ContentConnectionsComparer = (prev: ContentConnectionsProps, next: ContentConnectionsProps) =>
  prev.entity === next.entity && prev.entityType === next.entityType;

const ContentConnections: FCC<ContentConnectionsProps> = ({
  entity,
  entityType,
}) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  const entityId = entity.id;

  const { data: propertyId } = useGetCurrentPropertyId();

  const possibleConnections = getEntityPossiblyConnectionTypes(entityType);

  const { data: connectionsMap } = useGetEntityConnectionsQuery(
    propertyId && entityId
      ? {
        propertyId,
        entityId,
        entityType,
      }
      : skipToken,
  );

  const connections = !R.isNil(connectionsMap) && !R.isEmpty(connectionsMap)
    ? R.toPairs(connectionsMap).filter(([connectionEntityType]) => possibleConnections.includes(connectionEntityType))
    : [];

  return connections.length > 0
    ? (
      <Stack sx={{ marginTop: 4 }}>
        <Typography variant="h6">{t('common:connections')}</Typography>
        <Stack
          spacing={2.5}
          sx={{
            marginTop: 1.5,
            padding: 2.5,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: theme.palette.grey[300],
            borderRadius: '10px',
          }}
        >
          {
            connections
              .map(([connectionEntityType, connections]) => (
                <Stack key={connectionEntityType}>
                  <ConnectionsList
                    connectionEntityType={connectionEntityType as EntityType}
                    connections={connections}
                    sourceEntity={entity}
                    sourceEntityType={entityType}
                  />
                </Stack>
              ))
          }
        </Stack>
      </Stack>
    )
    : null;
};

export default memo(ContentConnections, ContentConnectionsComparer);
