import React, { FC } from 'react';
import {
  Card,
  Stack,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropertyValuation } from '@house-id/houseid-types/dist/finances/valuation';
import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router';
import {
  AttachFile,
  DeleteOutline,
  EditOutlined,
} from '@mui/icons-material';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { useDispatch } from 'react-redux';

import HomeLayout from '../../../../../pages/Home/components/HomeLayout';
import useGetCurrentPropertyId from '../../../../../hooks/useGetCurrentPropertyId';
import {
  propertyValuationApi,
  useDeletePropertyValuationMutation,
  useGetPropertyValuationsQuery,
} from '../api/api.valuation';
import HIDTypography from '../../../../../../../components/HIDTypography';
import { formatMoney } from '../../../../../../../utils/string';
import {
  DateTimeFormats,
  formatDate,
} from '../../../../../../../utils/date';
import { FCC } from '../../../../../../../types/common';
import { BorderRadius } from '../../../../../../../constants/borderRadius';
import {
  getCreateBrokerValuationPath,
  getUpdateBrokerValuationPath,
  getValuationOverviewPath,
} from '../navigation.valuation';
import { getPathWithPropertyIdOrInit } from '../../../../../../Auth/navigation/navigation.auth';
import { useGetPropertyRealtorsQuery } from '../../../../../api/api.onboarding';
import { OTHER_VALUATION_REALTOR_CODE } from '../constants.valuation';
import ViewEntityActions from '../../../../Content/components/actions/ViewEntityActions';
import { getFinancesPath } from '../../../navigation.finances';
import HIDIconButton from '../../../../../../../components/buttons/HIDIconButton';
import DialogNames from '../../../../../../../hooks/useDialog/DialogNames';
import useDialog from '../../../../../../../hooks/useDialog';
import ManageConnections from '../../../../../../../components/icons/ManageConnections';
import { HIDApiTags } from '../../../../../../../api/HIDApiTags';
import useBreakPointsSizes from '../../../../../../../hooks/useBreakpointsSizes';
import { ManageContentConnectionsMode } from '../../../../Content/components/dialogs/manageContentConnectionsDialog/ManageContentConnectionsDialog';

type BrokerValuationCardProps = {
  valuation: PropertyValuation;
  hideActions?: boolean;
};

export const BrokerValuationCard: FCC<BrokerValuationCardProps> = ({
  valuation,
  hideActions = false,
  sx,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation(['common', 'forms_common', 'finances']);
  const theme = useTheme();

  const { isDownSm } = useBreakPointsSizes();

  const { data: propertyId } = useGetCurrentPropertyId();

  const [deletePropertyValuation, { isLoading: isDeleting }] = useDeletePropertyValuationMutation();

  const handleUpdate = () => navigate(getPathWithPropertyIdOrInit(getUpdateBrokerValuationPath, { id: valuation.id, propertyId }));

  const { data: realtors } = useGetPropertyRealtorsQuery();

  const brokerCompanyName = valuation.realtorId === OTHER_VALUATION_REALTOR_CODE
    ? valuation.brokerCompany
    : realtors?.find(({ id }) => id === valuation.realtorId)?.name;

  const numberOfAttachments = valuation?.connections?.length || 0;

  const [openConfirmDelete, closeConfirmDeleteDialog] = useDialog(DialogNames.YES_NO_CANCEL_DIALOG);

  const handleDelete = (id: string) => {
    if (propertyId) {
      deletePropertyValuation({
        propertyId,
        id,
      })
        .unwrap()
        .then(() => closeConfirmDeleteDialog());
    }
  };

  const handleConfirmDelete = () => {
    openConfirmDelete({
      closeOnAction: false,
      fullScreen: false,
      loading: isDeleting,
      title: t('common:are_you_sure_you_want_to_delete'),
      description: t('finances:are_you_sure_you_want_to_remove_broker_rating'),
      onYes: () => handleDelete(valuation.id),
      onNo: closeConfirmDeleteDialog,
    });
  };

  const [openManageConnectionsDialog] = useDialog(DialogNames.MANAGE_CONTENT_CONNECTIONS_DIALOG);

  const handleManageConnections = () => {
    openManageConnectionsDialog({
      entityType: EntityType.VALUATION,
      entity: valuation,
      mode: {
        type: ManageContentConnectionsMode.ADD,
      },
      onConnectionsAdded: () => dispatch(propertyValuationApi.util.invalidateTags([HIDApiTags.PROPERTY_VALUATION])),
    });
  };

  return (
    <Stack
      sx={{
        padding: 2.5,
        borderRadius: BorderRadius.sm,
        backgroundColor: theme.palette.grey[100],
        ...sx,
      }}
    >
      <Stack gap={0.5} key={valuation.id}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <HIDTypography variant="h5">
            {formatMoney(valuation.amount, 'never')}
          </HIDTypography>
          {!hideActions && (
            <Stack direction="row" gap={isDownSm ? 1.5 : 2}>
              <HIDIconButton
                Icon={DeleteOutline}
                onClick={handleConfirmDelete}
              />
              <HIDIconButton
                Icon={ManageConnections}
                onClick={handleManageConnections}
              />
              <HIDIconButton
                Icon={EditOutlined}
                onClick={handleUpdate}
              />
            </Stack>
          )}
        </Stack>
        {Boolean(valuation.brokerName) && (
          <Stack alignItems="center" direction="row" gap={1.5}>
            <HIDTypography variant="subtitle1">
              {t('finances:broker')}
            </HIDTypography>
            <HIDTypography variant="body1">
              {valuation.brokerName}
            </HIDTypography>
          </Stack>
        )}
        {Boolean(brokerCompanyName) && (
          <Stack alignItems="center" direction="row" gap={1.5}>
            <HIDTypography variant="subtitle1">
              {t('finances:brokerage_company')}
            </HIDTypography>
            <HIDTypography variant="body1">
              {brokerCompanyName}
            </HIDTypography>
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between">
          <HIDTypography variant="subtitle1">
            {formatDate(new Date(valuation.date), DateTimeFormats.DATE_ONLY)}
          </HIDTypography>
          {numberOfAttachments > 0 && (
            <Stack direction="row" gap={0.5}>
              <AttachFile sx={{ color: theme.palette.grey[500] }} />
              <HIDTypography sx={{ color: theme.palette.grey[500] }}>
                {`${t('common:connections')} (${numberOfAttachments})`}
              </HIDTypography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const BrokerValuations: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['finances']);

  const { data: propertyId } = useGetCurrentPropertyId();
  const {
    data: valuations,
    isLoading,
  } = useGetPropertyValuationsQuery(propertyId ? { propertyId } : skipToken);

  const handleAdd = () => navigate(getPathWithPropertyIdOrInit(getCreateBrokerValuationPath, { propertyId }));

  return (
    <HomeLayout
      SideColumn={
        <Card sx={{ padding: 2 }}>
          <ViewEntityActions
            onAdd={handleAdd}
          />
        </Card>
      }
      breadcrumbsLinks={[
        {
          link: getPathWithPropertyIdOrInit(getFinancesPath, { propertyId }),
          name: t('finances:finances'),
        },
        {
          link: getPathWithPropertyIdOrInit(getValuationOverviewPath, { propertyId }),
          name: t('finances:valuation'),
        },
      ]}
      isLoading={isLoading}
      sideDrawerElements={
        [
          <ViewEntityActions key={ViewEntityActions.name} onAdd={handleAdd} />,
        ]
      }
      title={t('finances:broker_valuation')}
    >
      <Stack sx={{ gap: 4 }}>
        <HIDTypography>
          {t('finances:broker_valuation_description')}
        </HIDTypography>
        <Stack sx={{ gap: 2 }}>
          {
            valuations
              ?.map((valuation) => (
                <BrokerValuationCard
                  key={valuation.id}
                  valuation={valuation}
                />
              ))
          }
        </Stack>
      </Stack>
    </HomeLayout>
  );
};

export default BrokerValuations;
