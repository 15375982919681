import React from 'react';
import {
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  FormikProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import { DeleteOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ReceiptLine } from '@house-id/houseid-types/dist/content/receipt';

import { FCC } from '../../../../../../../../../types/common';
import { getHandleSetField } from '../../../../../../../../../utils/form';
import HIDTextField from '../../../../../../../../../components/HIDTextField';
import HIDCurrencyField from '../../../../../../../../../components/HIDCurrencyField';
import { ReceiptFormFields } from '../types';
import HIDFormFreeSelect from '../../../../../../../../../components/HIDFormFreeSelect';

type ReceiptLineFormProps = {
  index: number;
  formik: FormikProps<ReceiptFormFields>,
  touched?: boolean;
  canDelete?: boolean;
  onDelete: () => void;
};

const ReceiptLineForm: FCC<ReceiptLineFormProps> = ({
  index,
  formik,
  touched = false,
  canDelete = true,
  sx,
  onDelete,
}) => {
  const { t } = useTranslation(['common', 'forms_common', 'receipts']);
  const theme = useTheme();

  const units = [
    { id: 'count', name: t('common:pieces_abr') },
    { id: 'pack', name: t('receipts:receipts_pack') },
    { id: 'h', name: 'h' },
    { id: 'roll', name: t('receipts:receipts_roll') },
    { id: 'm', name: 'm' },
    { id: 'm²', name: 'm²' },
    { id: 'm³', name: 'm³' },
    { id: 'L', name: 'L' },
    { id: 'kg', name: 'kg' },
  ];

  const handleSetField = getHandleSetField<ReceiptFormFields>(formik);

  const receiptLine = formik.values.lines?.[index];
  const formikErrors = formik.errors.lines as unknown as FormikErrors<Array<ReceiptLine>>;
  const formikError = formikErrors?.[index];
  const formikTouchedItems = formik.touched.lines as unknown as FormikTouched<Array<ReceiptLine>>;
  const formikTouched = formikTouchedItems?.[index];

  return (
    <Grid
      container
      columnSpacing={2.5}
      sx={{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderRadius: '10px',
        padding: theme.spacing(2.5, 2.5, 2.5, 0),
        margin: 0,
        position: 'relative',
        ...sx,
      }}
    >
      <Grid item sx={{ marginBottom: 2 }} xxs={12}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {`${t('receipts:receipts_receipt_line')} ${index + 1}`}
          </Typography>
          {canDelete && (
            <IconButton
              sx={{
                position: 'absolute',
                top: theme.spacing(1.5),
                right: theme.spacing(1.5),
              }}
              onClick={onDelete}
            >
              <DeleteOutline />
            </IconButton>
          )}
        </Stack>
      </Grid>
      <Grid item sm={4} xxs={12}>
        <HIDTextField
          id="articleNumber"
          label={t('receipts:receipts_receipt_article_number')}
          minRows={1}
          value={receiptLine?.articleNumber?.toString() || ''}
          onChange={handleSetField(`lines.${index}.articleNumber`)}
        />
      </Grid>
      <Grid item sm={8} xxs={12}>
        <HIDTextField
          multiline
          required
          error={Boolean((formikTouched?.name || touched) && formikError?.name)}
          helperText={(formikTouched?.name || touched) ? formikError?.name : undefined}
          id="name"
          label={t('forms_common:enter_name')}
          minRows={1}
          value={receiptLine?.name}
          onBlur={formik.handleBlur(`lines.${index}.name`)}
          onChange={handleSetField(`lines.${index}.name`)}
        />
      </Grid>
      <Grid
        item
        xxs={6}
      >
        <Stack direction="row" spacing={2}>
          <HIDTextField
            required
            error={Boolean((formikTouched?.quantity || touched) && formikError?.quantity)}
            helperText={(formikTouched?.quantity || touched) ? formikError?.quantity : undefined}
            id="quantity"
            inputProps={{ min: 0 }}
            label={t('receipts:receipts_quantity')}
            type="number"
            value={receiptLine?.quantity?.toString() || ''}
            onBlur={formik.handleBlur(`lines.${index}.quantity`)}
            onChange={handleSetField(`lines.${index}.quantity`)}
          />
          <HIDFormFreeSelect
            fullWidth={false}
            items={units.map(({ name }) => name)}
            label={' '}
            value={receiptLine?.unit || t('common:pieces_abr')}
            onChange={(_event, value) => formik.setFieldValue(`lines.${index}.unit`, value)}
          />
        </Stack>
      </Grid>
      <Grid
        item
        xxs={6}
      >
        <HIDCurrencyField
          required
          error={Boolean((formikTouched?.pricePerUnit || touched) && formikError?.pricePerUnit)}
          helperText={(formikTouched?.pricePerUnit || touched) ? formikError?.pricePerUnit : undefined}
          id="pricePerUnit"
          inputProps={{ min: 0 }}
          label={t('receipts:receipts_price_per_unit_label')}
          value={receiptLine?.pricePerUnit?.toString() || ''}
          onBlur={formik.handleBlur(`lines.${index}.pricePerUnit`)}
          onChange={handleSetField(`lines.${index}.pricePerUnit`)}
        />
      </Grid>
    </Grid>
  );
};

export default ReceiptLineForm;
