import React, { FC } from 'react';

import {
  DynamicAddressTextInput,
  DynamicInputCommonFieldProps,
} from '../../../types/types.dynamicContent';
import DynamicContentTextInput from './DynamicContentTextInput';
import { Address } from '../../../../../../../../../types/common';

type DynamicContentAddressTextInputProps = DynamicAddressTextInput & DynamicInputCommonFieldProps<Address>;

const DynamicContentAddressTextInput: FC<DynamicContentAddressTextInputProps> = ({
  id,
  title,
  description,
  mandatory,
  placeholder,
  errorMessage,
  value,
  source,
  formSettings,
  onBlur,
}) => {
  const formattedValue = value
    ? [
      value.streetAddress ? `${value.streetAddress},` : '',
      value.postalCode,
      value.addressLocality,
    ].filter(Boolean).join(' ').trim()
    : '';

  return (
    <DynamicContentTextInput
      readonly
      description={description}
      errorMessage={errorMessage}
      formSettings={formSettings}
      id={id}
      mandatory={mandatory}
      placeholder={placeholder}
      source={source}
      title={title}
      value={formattedValue}
      onBlur={onBlur}
    />
  );
};

export default DynamicContentAddressTextInput;
