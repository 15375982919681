import React, {
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router';
import { EntityType } from '@house-id/houseid-types/dist/entityType';
import { AnalyticsEvent } from '@house-id/houseid-types/dist/analytics';
import { SubscriptionFeature } from '@house-id/houseid-types/dist/subscriptionPlans';

import {
  ExternalService,
  ExternalServiceStage,
  FCC,
  FCCProps,
} from '../../../../../types/common';
import {
  formatDate,
  DateTimeFormats,
} from '../../../../../utils/date';
import HIDLink from '../../../../../components/HIDLink';
import HIDButton from '../../../../../components/buttons/HIDButton';
import HIDInfo from '../../../../../components/HIDInfo';
import {
  useSyncPropertyExternalServicesMutation,
} from '../../../api/api.property';
import useGetCurrentPropertyId from '../../../hooks/useGetCurrentPropertyId';
import HIDTag from '../../../../../components/HIDTag';
import { getMessagesPath } from '../../../modules/Content/modules/Message/navigation.message';
import useGetLogAnalyticsEvent from '../../../../Analytics/hooks/useGetLogAnalyticsEvent';
import { getPathWithPropertyIdOrInit } from '../../../../Auth/navigation/navigation.auth';
import useTryUseFeatureDialog from '../../../../SubscriptionPlans/hooks/useTryUseFeatureDialog';
import useDialog from '../../../../../hooks/useDialog';
import PropertyExternalService from '../../../constants/constants.externalServices';
import DialogNames from '../../../../../hooks/useDialog/DialogNames';
import { UnavailableFeature } from '../../../components/dialogs/OpenMobileAppDialog';

export enum ExternalServices {
  ENERGY_DECLARATIONS = 'energy_declarations',
  PROPERTY_INFORMATION_DATA = 'uc_property_information_data',
  TAXATION_DATA = 'uc_taxation_data',
}

const messagesRelatedServices = [
  ExternalServices.ENERGY_DECLARATIONS,
  ExternalServices.PROPERTY_INFORMATION_DATA,
  ExternalServices.TAXATION_DATA,
];

export type ExternalServiceCardRef = {
  activate: () => void;
};

type ExternalServiceCardProps = {
  externalService: ExternalService;
  onExternalServiceSynced?: () => void;
};

type ExternalServiceCardWithRef = FCCProps<ExternalServiceCardProps, ExternalServiceCardRef>;

const ExternalServiceCard: FCC<ExternalServiceCardProps> = forwardRef<ExternalServiceCardRef, ExternalServiceCardWithRef>((
  {
    externalService,
    sx,
    onExternalServiceSynced,
  },
  ref,
) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['property', 'common']);

  const statusColors: Record<ExternalServiceStage, { borderColor: string, backgroundColor: string }> = {
    [ExternalServiceStage.NOT_STARTED]: {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.info.lightest,
    },
    [ExternalServiceStage.PENDING]: {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.info.lightest,
    },
    [ExternalServiceStage.COMPLETED]: {
      backgroundColor: theme.palette.primary.lightest,
      borderColor: theme.palette.primary.main,
    },
    [ExternalServiceStage.FAILED]: {
      backgroundColor: theme.palette.error.lightest,
      borderColor: theme.palette.error.main,
    },
  };

  type UseGetCustomExternalServiceHandling = (params: { externalServiceId: PropertyExternalService }) => (() => void) | undefined;

  const useGetCustomExternalServiceHandling: UseGetCustomExternalServiceHandling = ({ externalServiceId }) => {
    const [openMobileAppDialog] = useDialog(DialogNames.OPEN_MOBILE_APP_DIALOG);

    const customExternalServiceHandling: Record<string, () => void> = {
      [PropertyExternalService.SVENSKFAST_BUYER]: () => openMobileAppDialog({ unavailableFeature: UnavailableFeature.SYNC_PROPERTY }),
      [PropertyExternalService.SVENSKFAST_CURRENTLY_SELLING]:
        () => openMobileAppDialog({ unavailableFeature: UnavailableFeature.SYNC_PROPERTY }),
      [PropertyExternalService.SVENSKFAST_SELLER]: () => openMobileAppDialog({ unavailableFeature: UnavailableFeature.SYNC_PROPERTY }),
    };

    return customExternalServiceHandling[externalServiceId];
  };

  const { data: propertyId } = useGetCurrentPropertyId();

  const {
    name,
    buttonText,
    serviceId,
    lastUpdated,
  } = externalService;

  const { text, stage } = externalService.status;
  const serviceStage = stage || ExternalServiceStage.FAILED;

  const points = externalService.bonus?.points;

  const formattedLastUpdated = lastUpdated
    ? formatDate(new Date(lastUpdated), DateTimeFormats.DATE_ONLY)
    : null;

  const { canSync } = externalService.actions;
  const canActivate = canSync && serviceStage === ExternalServiceStage.NOT_STARTED;
  const canRetry = canSync && (serviceStage === ExternalServiceStage.FAILED || serviceStage === ExternalServiceStage.COMPLETED);
  const canSyncService = canActivate || canRetry;
  const shouldNavigateToMessages = serviceStage === ExternalServiceStage.COMPLETED && messagesRelatedServices.includes(serviceId);

  const currentStatusColors = statusColors[serviceStage];

  const activateButtonText = canRetry
    ? t('property:external_service_retry')
    : buttonText || t('external_service_download_data');

  const handleGoToInbox = () => navigate(getPathWithPropertyIdOrInit(getMessagesPath, { propertyId }));

  const [syncExternalServices, { isLoading: isExternalServicesLoading }] = useSyncPropertyExternalServicesMutation();

  const [
    proceedToFeatureOrOpenSubscriptionDialog,
    subscriptionFeaturesIsLoading,
  ] = useTryUseFeatureDialog({ subscriptionFeature: SubscriptionFeature.EXTERNAL_SERVICES_SYNC_INFO });

  const logAnalyticsEvent = useGetLogAnalyticsEvent();

  const [openExternalServiceIntroModal] = useDialog(DialogNames.EXTERNAL_SERVICE_INTRO_DIALOG);

  const customSyncExternalService = useGetCustomExternalServiceHandling({ externalServiceId: externalService.serviceId });

  const handleSyncExternalService = (propertyId: string, externalServiceId: string) => {
    openExternalServiceIntroModal({
      onStart: () => {
        if (customSyncExternalService) {
          customSyncExternalService();
          onExternalServiceSynced?.();
        } else {
          syncExternalServices({ propertyId, serviceIds: [externalServiceId] })
            .then(() => onExternalServiceSynced?.());
        }
        logAnalyticsEvent({ event: AnalyticsEvent.SYNC_EXTERNAL_SERVICES, hidCategory: EntityType.INSURANCE });
      },
      externalServiceId: externalService.serviceId,
    });
  };

  const handleTrySyncExternalService = () => {
    if (propertyId) {
      if (externalService.premiumRequired) {
        proceedToFeatureOrOpenSubscriptionDialog({
          onAction: () => handleSyncExternalService(propertyId, externalService.id),
        });
      } else {
        handleSyncExternalService(propertyId, externalService.id);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    activate: () => handleTrySyncExternalService(),
  }));

  return (
    <Card
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '10px',
        borderColor: currentStatusColors.borderColor,
        ...sx,
      }}
    >
      <Stack
        padding={2}
        spacing={1}
        sx={{
          backgroundColor: currentStatusColors.backgroundColor,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          flexWrap="wrap-reverse"
          justifyContent="space-between"
        >
          <HIDInfo
            description={externalService.additionalInformation}
            label={name}
            labelVariant="subtitle1"
          />
          {points && (
            <HIDTag
              color={serviceStage === ExternalServiceStage.COMPLETED
                ? theme.palette.primary.light
                : theme.palette.grey[400]}
              label={t('common:points', { points })}
            />
          )}
        </Stack>
        <Typography>
          <ReactMarkdown components={{ p: 'span' }}>
            {text}
          </ReactMarkdown>
        </Typography>
        {lastUpdated && (
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <Typography>
              {t('property:external_service_external_service_last_updated', { lastUpdated: formattedLastUpdated })}
            </Typography>

          </Stack>
        )}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {shouldNavigateToMessages && (
            <HIDLink
              label={t('external_service_view_inbox')}
              underline="none"
              onClick={handleGoToInbox}
            />
          )}
          {canSyncService && (
            <HIDButton
              loading={subscriptionFeaturesIsLoading || isExternalServicesLoading}
              onClick={handleTrySyncExternalService}
            >
              {canRetry ? t('property:external_service_retry') : activateButtonText}
            </HIDButton>
          )}
        </Stack>
      </Stack>
    </Card>
  );
});

ExternalServiceCard.displayName = 'ExternalServiceCard';

export default ExternalServiceCard;
