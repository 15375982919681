/* eslint-disable max-len */
import qs from 'query-string';
import * as R from 'ramda';
import { UtmParameters } from '@house-id/houseid-types/dist/auth';

import { isLocal } from './env';
import { getInviteCodePath } from '../modules/Auth/navigation/navigation.auth';
import { ENROLL_SECOND_FACTOR_URL_PARAM } from '../modules/Auth/constants.auth';
import { getUrlSearchParams } from './url';

export const getAppOnboardingLinkWithCustomToken = (customToken: string) => `https://houseid.page.link/?link=https://houseid.se/mobile?action%3Dhouseid://app/landing?utm_campaign%253Dstartpage%2526utm_source%253Dhouseid.se%2526token%253D${customToken}&apn=se.houseid.app&isi=1546758051&ibi=se.houseid.app&utm_campaign=startpage&utm_source=houseid.se&efr=1`;

export const getAppStoreAppOnboardingLinkWithCustomToken = (customToken: string) => `https://houseid.page.link/?link=https://houseid.se/mobile?action%3Dhouseid://app/landing?utm_campaign%253Dstartpage%2526utm_source%253Dhouseid.se%2526token%253D${customToken}&apn=se.houseid.app&isi=1546758051&ibi=se.houseid.app&utm_campaign=startpage&utm_source=houseid.se&efr=1&ofl=https://apps.apple.com/se/app/house-id-acessa-ditt-hus-id/id1546758051&utm_source=houseid.se&utm_medium=web_no_utm`;

export const getGooglePlayAppOnboardingLinkWithCustomToken = (customToken: string) => `https://houseid.page.link/?link=https://houseid.se/mobile?action%3Dhouseid://app/landing?utm_campaign%253Dstartpage%2526utm_source%253Dhouseid.se%2526token%253D${customToken}&apn=se.houseid.app&isi=1546758051&ibi=se.houseid.app&utm_campaign=startpage&utm_source=houseid.se&efr=1&ofl=${encodeURI('https://play.google.com/store/apps/details?id=se.houseid.app')}&utm_source=houseid.se&utm_medium=web_no_utm`;

export const getMobileStoreUtmParams = () => ({
  apn: 'se.houseid.app',
  isi: '1546758051',
  ibi: 'se.houseid.app',
  efr: '1',
  defaultUtmParams: {
    utm_campaign: 'startpage',
    utm_source: 'houseid.se',
    utm_medium: 'web_no_utm',
  },
});

export const getAppStoreUtmParams = () => ({
  ...getMobileStoreUtmParams(),
  ofl: encodeURI('https://apps.apple.com/se/app/house-id-acessa-ditt-hus-id/id1546758051'),
});

export const getGooglePlayUtmParams = () => ({
  ...getMobileStoreUtmParams(),
  ofl: encodeURI('https://play.google.com/store/apps/details?id=se.houseid.app'),
});

export const getStoreLinkWithUtm = (params: {
  apn: string;
  efr: string;
  ibi: string;
  isi: string;
  ofl: string;
  defaultUtmParams: UtmParameters;
  token?: string;
  queryUtmParams?: UtmParameters;
}) => {
  const utmParams = params.queryUtmParams?.utm_source ? params.queryUtmParams : params.defaultUtmParams;

  const actionQueryParams: Record<string, any> = { ...utmParams };
  if (params.token) {
    actionQueryParams.token = params.token;
  }
  const mainQueryParams = {
    ...R.omit(['defaultUtmParams', 'queryUtmParams'], params),
    ...utmParams,
  };

  const action = encodeURI(`houseid://app/landing?${getUrlSearchParams(actionQueryParams)}`);
  const link = `https://houseid.se/mobile?action=${action}`;

  const allParams = getUrlSearchParams({
    link,
    ...mainQueryParams,
  });

  return `https://houseid.page.link/?${allParams}`;
};

export const MobileAppPrefixUrl = 'houseid://app';

export const getVerifyEmailReturnBackUrl = (propertyId: string) =>
  `${document.location.origin}/properties/${propertyId}/user-details?${ENROLL_SECOND_FACTOR_URL_PARAM}=true&action=${MobileAppPrefixUrl}/editAccount/true`;

export const generateDynamicLink = (location: string, appDeepLink: string) => {
  const locationResolved = isLocal
    ? location
      .replace('http://localhost:5173', 'https://test.houseid.services')
      .replace('http://127.0.0.1:5173', 'https://test.houseid.services')
    : location;

  const parsedUrl = qs.parseUrl(locationResolved);
  return encodeURI(qs.stringifyUrl({
    url: parsedUrl.url,
    query: {
      ...parsedUrl.query,
      action: encodeURI(appDeepLink),
    },
  }));
};

export const getPropertyInviteDeepLink = (code: string) => generateDynamicLink(
  `${window.location.origin}/${getInviteCodePath(code)}`,
  `${MobileAppPrefixUrl}/inviteCode/${code}`,
);

export const getPropertyHomeDeepLink = (customToken: string, propertyId: string) =>
  generateDynamicLink(
    window.location.href,
    qs.stringifyUrl({
      url: `${MobileAppPrefixUrl}/home`,
      query: {
        token: customToken,
        propertyId,
      },
    }),
  );

export const getPropertyDeclarationDeepLink = (customToken: string, propertyId: string) =>
  generateDynamicLink(
    window.location.href,
    qs.stringifyUrl({
      url: `${MobileAppPrefixUrl}/declaration`,
      query: {
        token: customToken,
        propertyId,
      },
    }),
  );

export const getPropertySpecificationDeepLink = (customToken: string, propertyId: string) =>
  generateDynamicLink(
    window.location.href,
    qs.stringifyUrl({
      url: `${MobileAppPrefixUrl}/viewPropertySpecifications`,
      query: {
        token: customToken,
        propertyId,
      },
    }),
  );
