import React, { FC } from 'react';
import { Stack } from '@mui/material';

import { DynamicContentArrowButtonProps } from '../../../types/types.dynamicContent';
import useNavigateFromLink from '../../../../../../../hooks/useNavigateFromLink';
import HIDLink from '../../../../../../../../../components/HIDLink';

const DynamicContentArrowButton: FC<DynamicContentArrowButtonProps> = ({
  title,
  link,
  formSettings,
}) => {
  const handleAddLinkPress = useNavigateFromLink(link);

  return (
    <Stack direction="row" justifyContent="flex-end">
      <HIDLink
        label={title}
        sx={{ color: formSettings?.color }}
        onClick={handleAddLinkPress}
      />
    </Stack>
  );
};
export default DynamicContentArrowButton;
