import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeductionListItemWithWarnings,
  DeductionWarningType,
} from '@house-id/houseid-types/dist/deductions';
import {
  AttachFile,
  Edit,
  Warning,
} from '@mui/icons-material';
import {
  Card,
  useTheme,
} from '@mui/material';
import {
  Stack,
} from '@mui/system';
import { useNavigate } from 'react-router';

import HIDTypography from '../../../../../../../../../components/HIDTypography';
import { BorderRadius } from '../../../../../../../../../constants/borderRadius';
import useDialog from '../../../../../../../../../hooks/useDialog';
import DialogNames from '../../../../../../../../../hooks/useDialog/DialogNames';
import { FCC } from '../../../../../../../../../types/common';
import useGetDeductionAmountTypeLabel from '../hooks/useGetDeductionAmountTypeLabel';
import { formatMoney } from '../../../../../../../../../utils/string';
import HIDLink from '../../../../../../../../../components/HIDLink';
import useGetEntityConnectionsDescription from '../../../../../../Content/hooks/useGetEntityConnectionsDescription';
import HIDIconButton from '../../../../../../../../../components/buttons/HIDIconButton';
import { getDeductionUpdatePath } from '../navigation/navigation.declarationHelp';
import useGetCurrentPropertyId from '../../../../../../../hooks/useGetCurrentPropertyId';
import HIDInfo from '../../../../../../../../../components/HIDInfo';

type DeductionItemCardProps = {
  deduction: DeductionListItemWithWarnings;
};

const DeductionItemCard: FCC<DeductionItemCardProps> = ({
  deduction,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['declarations', 'deductions']);
  const { data: propertyId } = useGetCurrentPropertyId();

  const getConnectionsDescription = useGetEntityConnectionsDescription();
  const connectionsDescription = deduction.connectionCounts ? getConnectionsDescription(deduction.connectionCounts) : '';

  const [openShowDeductionCalculationResultDialog] = useDialog(DialogNames.SHOW_DEDUCTION_CALCULATION_RESULT_DIALOG);
  const handleShowResult = () => {
    openShowDeductionCalculationResultDialog({
      deductionTypeId: deduction.type,
      totalAmount: deduction.totalAmount || 0,
      result: deduction.result,
    });
  };

  const hasResult = deduction.result !== undefined;
  const showResultDetails = hasResult && deduction.showResult;

  const handleEditDeduction = () => {
    if (propertyId) {
      navigate(getDeductionUpdatePath({ propertyId, id: deduction.id }));
    }
  };

  const getDeductionResultTypeLabel = useGetDeductionAmountTypeLabel();

  const warningsLabels = {
    [DeductionWarningType.MISSING_AMOUNT]: t('deductions:deduction_total_amount_missing_warning_description'),
    [DeductionWarningType.MISSING_DOCUMENTATION]: t('deductions:connections_are_missing_description'),
    [DeductionWarningType.UNANSWERED_QUESTIONS]: t('deductions:unsanswered_questions_warning_description'),
  };

  const warnings = deduction.warnings.map((warning) => warningsLabels[warning.type]);

  const description = warnings
    .map((row) => `- ${row}`)
    .join('\n\n');

  const hasWarning = !hasResult || connectionsDescription === '';

  return (
    <Card>
      <Stack
        gap={0.75}
        padding={2.5}
        sx={{
          backgroundColor: theme.palette.grey[200],
          borderRadius: BorderRadius.sm,
        }}
      >
        <Stack
          alignItems="center"
          flexDirection="row"
          gap={4}
          justifyContent="space-between"
        >
          <Stack
            alignItems="center"
            flex={1}
            flexDirection="row"
            gap={1}
          >
            {hasWarning && (

              <HIDInfo
                isMarkdown
                IconComponent={({ onClick }) => (
                  <HIDIconButton
                    Icon={Warning}
                    color="yellow"
                    size="small"
                    onClick={onClick}
                  />
                )}
                description={description}
              />
            )}
            <HIDTypography variant="subtitle1">
              {deduction.name}
            </HIDTypography>
          </Stack>
          <HIDIconButton
            Icon={Edit}
            size="small"
            onClick={handleEditDeduction}
          />
        </Stack>
        {deduction.result
          ? deduction.result.deductionAmounts.map((result) => (
            <Stack
              alignItems="center"
              flexDirection="row"
              gap={1}
              justifyContent="space-between"
              key={result.type}
            >
              <HIDTypography variant="body2">
                {getDeductionResultTypeLabel(result.type)}
              </HIDTypography>
              <HIDTypography variant="subtitle2">
                {formatMoney(result.amount.amount || 0, 'never')}
              </HIDTypography>
            </Stack>))
          : null}
        {(connectionsDescription || hasResult) && (
          <Stack
            alignItems={connectionsDescription ? 'flex-start' : 'flex-end'}
            flexDirection="row"
            gap={1}
            justifyContent={connectionsDescription ? 'space-between' : 'flex-end'}
          >
            {connectionsDescription
              ? (
                <Stack alignItems="center" flexDirection="row" gap={0.5}>
                  <AttachFile
                    sx={{
                      color: theme.palette.grey[500],
                      width: theme.spacing(2),
                      height: theme.spacing(2),
                      transform: 'rotate(45deg)',
                    }}
                  />
                  <HIDTypography
                    color={theme.palette.common.black}
                    flex={1}
                    variant="subtitle2"
                  >
                    {connectionsDescription}
                  </HIDTypography>
                </Stack>
              )
              : null}
            {showResultDetails && (
              <HIDLink
                color="grey"
                label={t('declarations:declaration_show_calculation')}
                linkColor={theme.palette.common.black}
                variant="body2"
                onClick={handleShowResult}
              />
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default DeductionItemCard;
